@charset "UTF-8";

.skip_nav {
    position: absolute;
    z-index: 9999;

    a {
        display: block;
        overflow: hidden;
        height: 0;
        margin: 0 auto;
        height: 1px;
        margin-bottom: -1px;
        white-space: nowrap;
        text-align: center;
        text-indent: -9999px;
    }
}

.c-browser {
    position: relative;
    left: 0;
    top: 0;
    z-index: 300;
    width: 100%;
    min-width: 900px;
    height: 60px;
    padding: 0 40px;
    border-bottom: 1px solid $color-light__5;
    background-color: #e5f2fb;
    text-align: center;
    color: $color-dark__3;
    font-weight: bold;
    font-size: 16px;
    line-height: 60px;
    letter-spacing: -0.3px;

    &:before {
        display: inline-block;
        @include sprite-retina($sp_icon_2x_info_browser);
        vertical-align: middle;
        margin: -2px 5px 0 0;
        content: '';
    }

    em {
        color: $color-secondary;
    }

    .btn-close {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 20px;
        width: 40px;
        height: 40px;
        overflow: hidden;
        line-height: 200px;

        &:before,
        &:after {
            position: absolute;
            width: 18px;
            height: 1px;
            top: 50%;
            left: 11px;
            margin-top: -1px;
            background: $color-dark__3;
            content: '';
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}

.st-wrap {
    overflow: hidden;
    min-width: 1400px;
    min-height: 100vh;
    background-color: $color-light__3;
}

// header
.st-header {
    background-color: $color-light;
    box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15);
}

.st-header__seller {
    .seller_in {
        position: relative;
        display: flex;
        align-items: center;
        width: 1200px;
        margin: 0 auto;
        padding: 30px 0;

        .seller_title {
            margin-right: 16px;

            &:after {
                display: inline-block;
                width: 1px;
                height: 20px;
                margin: 0 0 -2px 16px;
                background-color: $color-light__4;
                vertical-align: middle;
                content: '';
            }

            .logo_11st {
                overflow: hidden;
                display: inline-block;
                margin-right: 4px;
                vertical-align: middle;
                line-height: 200px;
                @include sprite-retina($sp_icon_2x_logo_11st);
            }

            .logo_store {
                overflow: hidden;
                vertical-align: middle;
                line-height: 200px;
                @include sprite-retina($sp_icon_2x_logo_store);
            }
        }

        .seller_info {
            flex: 1;

            .info_img {
                position: relative;
                display: inline-block;
                margin-right: 4px;
                padding-right: 6px;
                vertical-align: middle;

                .img {
                    overflow: hidden;
                    position: relative;
                    display: block;
                    width: 40px;
                    height: 40px;
                    border: solid 1px rgba(0, 0, 0, 0.04);
                    border-radius: 50%;
                    @include sprite-retina($sp_icon_2x_profile_default);

                    .img_in {
                        overflow: hidden;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        border-radius: 50%;
                        color: transparent;
                        font-size: 0;
                        line-height: 200px;
                    }

                    /* MPQC-17908 배포 후 삭제 */
                    >img {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        max-width: 100%;
                        max-height: 100%;
                        margin: auto;
                    }
                    /* // MPQC-17908 배포 후 삭제 */
                }

                .btn-icon__edit {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    &:before {
                        @include sprite-retina($sp_icon_2x_profile_edit);
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        content: '';
                    }
                }
            }

            >span {
                vertical-align: middle;
            }

            .info_title {
                padding-right: 4px;
                vertical-align: middle;
                color: $color-dark__2;
                font-size: 18px;

                &:after {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    margin: 0 0 -2px -1px;
                    border: 1px solid $color-dark__2;
                    border-width: 1px 1px 0 0;
                    vertical-align: middle;
                    transform: rotate(45deg) translateY(-50%);
                    content: '';
                }
            }

            .info_visit {
                margin-left: 14px;
                padding: 0 5px;
                color: $color-info;
                font-size: 15px;

                &:after {
                    display: inline-block;
                    width: 0;
                    height: 0;
                    margin-left: 8px;
                    border-right: 4px solid transparent;
                    border-top: 5px solid $color-default;
                    border-left: 4px solid transparent;
                    transform: translateY(-50%);
                    opacity: 0.5;
                    content: '';
                }

                em {
                    margin-left: 4px;
                    color: $color-secondary;
                }
            }
        }

        .seller_visit {
            display: none;
            position: absolute;
            z-index: 99;
            top: 74px;
            left: 50%;
            width: 738px;
            margin-left: -369px;
            padding: 30px;
            background-color: $color-light;
            box-shadow: 0 1px 10px 0 rgba(29, 29, 29, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.15);

            &.active {
                display: block;
            }

            .visit_title {
                margin-bottom: 10px;
                font-size: 13px;

                .title {
                    margin-right: 4px;
                    vertical-align: -1px;
                    color: $color-dark__2;
                    font-size: 18px;
                    letter-spacing: 0;
                }
            }

            .visit_graph {
                .graph_date {
                    margin-bottom: 10px;

                    span {
                        font-size: 13px;

                        &:before {
                            display: inline-block;
                            width: 9px;
                            height: 4px;
                            margin-right: 4px;
                            vertical-align: middle;
                            background-color: $color-light__5;
                            border: solid 1px rgba(0, 0, 0, 0.03);
                            border-radius: 2px;
                            line-height: 0;
                            content: '';
                        }

                        &:nth-of-type(2):before {
                            background-color: $color-secondary;
                        }

                        &+span {
                            margin-left: 16px;
                        }
                    }
                }
            }

            .visit_period {
                display: flex;
                align-items: center;
                margin-top: 25px;

                span {
                    color: $color-dark__2;
                    font-weight: bold;
                    font-family: $font-family-number;
                    letter-spacing: 0;
                }

                .btn {
                    flex: 1;
                    text-align: right;

                    .text_sub {
                        display: inline-block;
                        margin-right: 8px;
                        vertical-align: middle;
                        color: $color-default;
                        font-size: 13px;
                    }
                }
            }

            .visit_btn {
                position: absolute;
                top: 14px;
                right: 14px;
            }
        }

        .seller_menu {
            padding-right: 3px;
            text-align: right;
            font-size: 0px;

            li {
                display: inline-block;

                &+li:before {
                    display: inline-block;
                    width: 1px;
                    height: 10px;
                    background-color: $color-light__4;
                    vertical-align: -1px;
                    content: '';
                }

                a,
                button {
                    display: inline-block;
                    padding: 5px 6px;
                    vertical-align: middle;
                    font-size: 15px;
                    letter-spacing: 0;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.st-nav__gnb {
    .gnb_in {
        width: 1200px;
        margin: 0 auto;

        ul {
            display: flex;

            li {
                flex: 1;
                text-align: center;

                button {
                    width: 100%;

                    span {
                        display: inline-block;
                        padding: 18px 0;
                        color: $color-dark__3;
                        font-size: 16px;
                        letter-spacing: 0;
                    }
                }

                &.active button>span {
                    padding-bottom: 16px;
                    border-bottom: 2px solid $color-brand;
                    color: $color-brand;
                    font-weight: bold;
                }
            }
        }
    }

    border-top: 1px solid $color-light__4;
}

// CONTENT
.st-content {
    width: 1200px;
    margin: 40px auto 0;
    background-color: $color-light__3;

    .content-in {
        min-height: calc(100vh - 625px);
        background-color: $color-light;
        box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15);

        .view-cont {
            padding: 30px 24px 40px;
            background-color: $color-light;

            &.cont-type__full {
                padding: 0;
            }
        }

        &+.content-in {
            margin-top: 40px;
        }
    }
}

.add_area {
    .content-in {
        min-height: calc(100vh - 701px);
    }

    .st-tab__cont.cont-type__2 {
        min-height: calc(100vh - 781px);
    }

    .footer-box {
        padding-bottom: 110px;
    }
}

// 전시관리
.st-tab__menu {
    background-color: $color-light;
    box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15);

    ul {
        display: flex;

        li {
            flex: 1;
            background-color: $color-light;

            button {
                position: relative;
                width: 100%;
                padding: 17px 10px;
                text-align: center;
                color: $color-dark;

                &:before {
                    display: inline-block;
                    margin: -2px 4px 0 0;
                    vertical-align: middle;
                    content: '';
                }
            }

            &:nth-of-type(1) button:before {
                @include sprite-retina($sp_icon_2x_tab_temp);
            }

            &:nth-of-type(2) button:before {
                @include sprite-retina($sp_icon_2x_tab_color);
            }

            &:nth-of-type(3) button:before {
                @include sprite-retina($sp_icon_2x_tab_home);
            }

            &.active {
                border-bottom-color: $color-light;

                button {
                    color: $color-brand;
                    font-weight: bold;
                }

                &:nth-of-type(1) button:before {
                    @include sprite-retina($sp_icon_2x_tab_temp_on);
                }

                &:nth-of-type(2) button:before {
                    @include sprite-retina($sp_icon_2x_tab_color_on);
                }

                &:nth-of-type(3) button:before {
                    @include sprite-retina($sp_icon_2x_tab_home_on);
                }
            }

            +li {
                border-left: 1px solid $color-light__5;
            }
        }
    }
}

.st-tab__cont {
    margin-top: 20px;
    padding-bottom: 28px;
    background-color: $color-light;
    border-top: none;
    box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15);

    &.cont-type__2 {
        display: flex;
        min-height: calc(100vh - 705px);
        margin-bottom: 0;
        padding-bottom: 0;

        .cont-view {
            flex: 1;
            padding: 0 24px 28px;

            .view-btn {
                padding-bottom: 0;
            }
        }
    }
}

.st-tab__cont.cont-type__2 {
    position: relative;

    .cont-menu {
        width: 290px;
        border-right: 1px solid $color-light__5;
    }

    &.fixed,
    &.fixed_end {
        .cont-menu {
            position: fixed;
            top: 0;
            left: calc(100vw - 1200px)/2;
            z-index: 99;
            width: 290px;
            height: 100vh;
            background-color: $color-light;
            border-right: 1px solid #ddd;

            .cont-menu__item {
                min-height: 0;
            }

            &+.cont-view {
                padding-left: 314px;
            }
        }
    }

    &.fixed {

        .cont-view {
            .view-title.title-style__3 {
                position: fixed;
                z-index: 5;
                top: 0;
                width: 910px;
                margin: 0 -24px;
                padding: 24px;
                background-color: #fff;
            }
        }
    }

    &.fixed_end {
        .cont-menu {
            position: absolute;
            top: auto;
            bottom: 0;
        }
    }

    .cont-menu__edit {
        padding: 20px 16px;
    }

    .cont-menu__guide {
        margin: 0 16px;
        padding: 15px 0;
        border-top: 1px solid $color-light__4;
        text-align: center;
        color: $color-dark__2;
        font-size: 13px;

        &:before {
            display: inline-block;
            vertical-align: middle;
            @include sprite-retina($sp_icon_2x_drag);
            content: '';
        }
    }

    .cont-menu__item {
        position: relative;
        min-height: 703px;
        height: calc(100% - 88px);
        padding: 0 16px;

        .menu-item__scroll {
            position: absolute;
            top: 0;
            right: 8px;
            bottom: 0;
            left: 16px;

            >div>div {
                padding-top: 1px;
            }
        }

        .item {
            background-color: $color-light;
            border-top: 1px solid $color-light__4;

            &:last-of-type {
                border-bottom: 1px solid $color-light__4;
            }

            &.active {
                position: relative;
                overflow: hidden;
                margin: -1px 0 16px;
                border-radius: 2px;
                border: solid 1px $color-info;
                box-shadow: 1px 2px 4px 0 rgba(29, 29, 29, 0.2);

                &.item-type__link button {
                    margin-left: 1px;
                    width: calc(100% - 2px);
                }

                .item_cont {
                    overflow: hidden;
                    display: block;
                }
            }
        }

        .item_title {
            position: relative;
            //display: flex;
            background-color: $color-light;

            button {
                //flex: 1;
                margin: 0 0 1px 2px;
                padding: 20px 10px 19px;
                text-align: left;
                color: $color-dark;
                font-size: 15px;
                letter-spacing: -0.5px;
                width: calc(100% - 3px);

                i {
                    color: $color-info;
                    font-size: 14px;

                    &:before {
                        display: inline-block;
                        width: 1px;
                        height: 12px;
                        margin: -5px 7px 0 3px;
                        background-color: $color-light__4;
                        vertical-align: middle;
                        content: '';
                    }
                }

                // MPQA-46122 span.drag_place -> button.drag 로 변경 요청
                &.drop {
                    &:before,
                    &:after {
                        display: none;
                        position: absolute;
                        z-index: 10;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: #f00;
                        content: '';
                    }

                    &.before:before {
                        display: block;
                    }

                    &.after:after {
                        display: block;
                    }

                    &:before {
                        top: -2px;
                    }

                    &:after {
                        bottom: -2px;
                    }
                }
            }

            .check-style__2 {
                padding: 10px 0;
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .item_cont {
            display: none;
            border-top: 1px solid $color-light__4;

            img {
                display: block;
                width: 100%;
            }
        }

        &.type_move {
            position: relative;
            height: auto;

            .menu-item__scroll {
                position: static;
            }

            .item {
                position: relative;
                margin-top: -1px;
                border-top: none;

                &+.item {
                    margin-top: 16px;
                }

                &:after {
                    position: absolute;
                    top: 50%;
                    right: 6px;
                    margin-top: -8px;
                    @include sprite-retina($sp_icon_2x_move);
                    content: '';
                }

                .item_title {
                    box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.4);
                    cursor: move;
                    cursor: grab;
                }

                button {
                    padding-left: 16px;
                    cursor: move;
                    cursor: grab;
                }

                [class^="check-style"] {
                    display: none;
                }

                &.drag_off {
                    .item_title {
                        background-color: $color-light__2;
                        box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.2);
                        opacity: 0.5;
                    }

                    .item_title,
                    button {
                        background-color: transparent;
                        color: $color-info;
                        cursor: none;
                    }
                }

                &.drag_on {
                    .item_title {
                        z-index: 2;
                        width: 100%;
                        height: 63px;
                        border: solid 1px $color-default;
                        background-color: rgba(255, 255, 255, 0.9);
                        box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.15);
                        cursor: move;
                    }

                    button,
                    [class^="check-style"] label {
                        cursor: move;
                    }
                }
            }

            .drag_place {
                display: block;
                height: 4px;
                margin: 10px 0;
                background-color: $color-brand;
            }
        }
    }
}

// 카테고리관리
.view-cont .cont-type__2 {
    display: flex;

    .cont-view {
        flex: 1;
    }
}

// title
.view-title {
    .view-cont &:first-child {
        margin-top: 0;
    }

    &.title-style__2 {
        padding: 24px;
        background-color: $color-light;
        border-bottom: 1px solid $color-light__3;

        .title {
            color: $color-dark__2;
            font-weight: normal;
            font-size: 18px;
        }
    }

    &.title-style__3 {
        margin-bottom: 24px;
        padding-right: 0;
        padding-left: 0;
        @extend .view-title.title-style__2;
    }

    &.title-style__4 {
        margin: 30px 0 20px;

        .write-box+& {
            margin-top: 40px;
        }

        .title {
            color: $color-dark;
            letter-spacing: -1px;

            i {
                color: $color-dark__2;
                font-weight: normal;
            }
        }
    }
}

// 하단 버튼
.cont-btn {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 98;
    padding: 10px 24px;
    text-align: center;
    background-color: $color-light__2;
    border-top: 1px solid $color-light__6;
}

.view-btn__area {
    padding: 16px 24px;
    border-top: 1px solid $color-light__4;
    text-align: center;

    /* MPQC-18126 삭제 */
    &.area-type__2 {
        position: relative;
        z-index: 2;
        margin-top: -1px;
        padding-bottom: 0;
        border-top: 1px solid $color-light__5;
    }
    /* // MPQC-18126 삭제 */
}

.view-btn {
    @extend .view-btn__area;
    position: relative;
    margin-top: 40px;
    padding: 20px 24px;
}

.st-bottom {
    @extend .view-btn__area;
    position: relative;
    margin: 20px 0 -40px;
    background-color: $color-light__2;
    border-top: 1px solid $color-light__6;
}
