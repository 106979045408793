@charset "UTF-8";

html.dialog-open {
    overflow: hidden;

    body {
        overflow: hidden;
    }

    .dialog-bg {
        display: block;
        overflow: hidden;
        overflow-x: auto;
    }
}

html.alert-open {
    overflow: hidden;

    .alert-bg {
        display: block;
    }
}

.dialog-body.scroll {
    max-height: calc(100vh - 150px);
    overflow: hidden;
    overflow-y: auto;
}

.dialog-bg,
.alert-bg,
.renewal-pop__bg,
.mov-pop__bg,
.cloud-pop__bg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    overflow: auto;
}

//dialog
.dialog-bg {
    .dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 762px;
        height: auto;
        padding: 0;
        background-color: $color-light;
        border: 1px solid $color-light__5;
        transform: translate(-50%, -50%);

        .dialog-header {
            position: relative;
            padding: 0 49px 0 40px;
            border: 1px solid transparent;
            border-bottom: 1px solid $color-light__5;
            text-align: center;

            .dialog-header__title {
                font-size: 18px;
                padding: 17px 0 16px;
                color: $color-dark__3;
                font-weight: bold;
            }
        }

        .dialog-body {
            overflow: hidden;
            overflow-y: auto;
            max-height: calc(100vh - 180px);
            padding: 30px 40px 0;

            &:after {
                display: block;
                height: 40px;
                content: '';
            }

            &.body-style__scroll {
                height: 750px;
            }

            .area-cont__btn {
                margin-top: 20px;
                text-align: center;
            }
        }

        .dialog-bottom {
            position: static;
            height: auto;
            border-top: 1px solid $color-light__5;
            padding: 16px 40px 15px;
            text-align: center;

            button {
                margin: 0 5px;
            }

            .dialog-bottom__left {
                float: left;
                text-align: left;
            }

            .dialog-bottom__right {
                float: right;
                text-align: right;
            }

            @include clearfix();
        }

        .dialog-close {
            position: absolute;
            top: 10px;
            right: 10px;
            @include ico-cancel(25px, 1px, #999, 9px);
        }
    }
}

.dialog-body__title {
    position: relative;
    padding-right: 150px;
    margin-bottom: 20px;

    h4,
    .title {
        padding: 5px 0 6px;
        color: $color-dark__3;
        font-weight: normal;
        letter-spacing: -1px;
    }

    p {
        padding: 5px 0 6px;
        color: $color-dark__3;
    }

    .side {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -16px;
    }

    &.title-type__em {
        .title {
            padding: 0;
            color: $color-dark;
            font-weight: bold;

            i {
                font-weight: normal;
            }
        }

        &:not(:first-child) {
            margin-top: 40px;
        }
    }

    &.title-type__guide {
        margin-bottom: 30px;
        padding-right: 0;
        text-align: center;

        .title {
            color: $color-brand;
            font-size: 20px;
            letter-spacing: 0;
        }
    }

    &.title-type__info {
        margin: 25px 0 6px;

        &:first-child {
            margin-top: 0;
        }

        .title {
            color: $color-dark__2;
            font-weight: normal;
            font-size: 14px;
            letter-spacing: 0;
        }

        .title_sub {
            padding: 6px 0;
            font-weight: normal;
            font-size: 14px;
            letter-spacing: 0;
        }
    }
}

//alert
.alert {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 460px;
    background: $color-light;
    border: 1px solid $color-light__4;
    transform: translate(-50%, -50%);

    .alert-header {
        height: 0;
        overflow: hidden;
    }

    .alert-body {
        padding: 40px 20px;
        font-size: 15px;
        line-height: 1.8;
    }

    .alert-bottom {
        border-top: 1px solid $color-light__4;
        min-height: 56px;
        padding: 16px 40px 15px;
        text-align: center;

        .alert-bottom__left {
            float: left;
            text-align: left;

            .check-style__2 {
                margin: 8px 0 0 8px;
            }

            .check-style__2 .lab {
                color: #666;
            }
        }

        .alert-bottom__right {
            float: right;
            text-align: right;
        }
    }

    .alert-close {
        position: absolute;
        top: 14px;
        right: 14px;
        @include ico-cancel(12px, 1px, #999);
    }

    .alert-text {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.54px;
        text-align: center;
    }
}

.c-alert {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: $color-dark__2;

    .sub_text {
        display: block;
        margin-top: 6px;
        font-size: 15px;
        line-height: 1.5;
        color: $color-default;
        font-weight: normal;
    }
}
