@charset "UTF-8";

@import "mixins/sprite";

@mixin clearfix {
    zoom: 1;
    &:after {
        display: block;
        content: "";
        clear: both;
    }
}
@mixin skip {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    width: 1px;
    height: 1px;
}

@mixin text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// x 모양
@mixin ico-cancel($size: 40px, $thick: 2px, $color: $color-info, $padding: 0) {
    $sqrt_2: 1.41421356237;
    width: $size + $padding*2;
    height: $size + $padding*2;
    overflow: hidden;
    line-height: 200px;

    &:before, &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: round($size*$sqrt_2 - $thick);
        height: $thick;
        background-color: $color;
        content: '';
    }
    &:before {
        -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(45deg);
        transform: translateZ(0) translate(-50%, -50%) rotate(45deg);
    }
    &:after {
        -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(135deg);
        transform: translateZ(0) translate(-50%, -50%) rotate(135deg);
    }
}
