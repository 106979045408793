@charset "UTF-8";

.st-layer {
    position: absolute;
    z-index: 4;
    margin-top: -1px;
    border: 1px solid $color-light__5;
    box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04);
}

.st-calendar {
    width: 304px;
    padding: 12px;
    background-color: $color-light;
    text-align: center;

    .st-calendar__header {
        position: relative;
        margin: 0 -13px 7px;
        padding: 0 40px;
        text-align: center;

        .btn-style__icon {
            position: absolute;
            top: 0;
            left: 0;

            &.btn-icon__next2 {
                right: 0;
                left: auto;
            }
        }
    }

    .st-calendar__body {
        .calendar-table {
            width: 100%;

            thead th {
                height: 40px;
                color: $color-dark__3;
                font-size: 13px;

                &:first-of-type {
                    color: $color-brand;
                }
            }

            tbody td {
                height: 40px;

                &:first-of-type .td_day {
                    color: $color-brand;
                }
            }

            .td_day {
                display: block;
                height: 100%;
                width: 100%;
                padding: 5px;
                color: $color-dark__2;
                font-family: $font-family-number;
                font-size: 13px;
            }

            .today {
                background-color: $color-light__3;
            }

            .disabled {
                color: $color-light__5;
            }

            .active {
                color: $color-light !important;
                background-color: $color-brand;
            }
        }
    }
}
