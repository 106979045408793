@charset "UTF-8";

@import "../store/mixins"; //믹스인모음
@import "../store/helpers/reset"; //스타일 초기화
@import "../store/helpers/variables"; //변수모음

@import "../store/sprites/sp_icon.scss";
@import "../store/sprites/sp_icon_2x.scss";
@import "../store/sprites/_sp_display_skin_01.scss";
@import "../store/sprites/_sp_display_skin_02.scss";
@import "../store/sprites/_sp_display_skin_03.scss";
@import "../store/sprites/_sp_display_skin_04.scss";
@import "../store/sprites/_sp_display_skin_05.scss";
@import "../store/sprites/_sp_display_skin_06.scss";
@import "../store/sprites/_sp_display_skin_07.scss";
@import "../store/sprites/_sp_display_skin_08.scss";
@import "../store/sprites/_sp_display_skin_09.scss";
@import "../store/sprites/_sp_display_skin_10.scss";
@import "../store/sprites/_sp_display_skin_11.scss";
@import "../store/sprites/_sp_display_skin_12.scss";
@import "../store/sprites/_sp_display_skin_13.scss";

@import "../store/partial/common.scss";
@import "../store/partial/layout.scss";
@import "../store/partial/footer.scss";
@import "../store/partial/form.scss";
@import "../store/partial/button.scss";
@import "../store/partial/table.scss";
@import "../store/partial/modal.scss";
@import "../store/partial/icon.scss";
@import "../store/partial/box.scss";
@import "../store/partial/calendar.scss";

// CONTENT
// 공통
.text_guide {
    margin: 40px 0 20px;

    &.guide-type__2 {
        position: relative;
        margin: 0;
        padding-left: 6px;
        color: $color-dark__2;

        &:before {
            position: absolute;
            top: 10px;
            left: 0;
            width: 2px;
            height: 2px;
            margin-right: 4px;
            background-color: $color-dark__2;
            vertical-align: 3px;
            border-radius: 50%;
            content: '';
        }
    }
}

.text_sub {
    color: $color-info;
    font-size: 13px;
    letter-spacing: 0;

    &.sub-type__2 {
        color: $color-dark__2;
        font-size: 15px;
    }
}

.text_info {
    margin-top: 10px;
    font-size: 13px;
    letter-spacing: 0;

    &.info-type__2 {
        margin: 25px 0 15px;
    }

    &.info-type__3 {
        color: $color-dark__3;
    }

    &.info-type__4 {
        margin: 0 0 20px;
    }

    &.info-type__5 {
        margin-top: 20px;
    }

    >.text_em {
        margin: 0;
    }

    >button {
        margin-left: 5px;
    }
}

.text_add {
    margin-left: 8px;
    color: $color-dark__2;
    font-size: 13px;
    letter-spacing: 0;
}

.text_confirm {
    margin-bottom: 12px;
    color: $color-info;

    &:before {
        display: inline-block;
        width: 7px;
        height: 12px;
        margin: 0 8px 0 2px;
        vertical-align: 2px;
        border: 1px solid $color-info;
        border-width: 0 1.5px 1.5px 0;
        transform: rotate(45deg);
        content: '';
    }

    &.type__em {
        color: $color-brand;

        &:before {
            border-color: $color-brand;
        }
    }
}

.text_em {
    margin-top: 20px;
    color: $color-brand;
}

.text_alert {
    margin-top: 10px;
    font-size: 12px;
}

.st-msg {
    position: fixed;
    bottom: 100px;
    left: 50%;
    z-index: 99;
    min-width: 260px;
    margin-left: -130px;
    padding: 11px 20px;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 22px;
    text-align: center;
    color: $color-light;
    font-size: 15px;
    letter-spacing: 0;

    &.confirm:before {
        display: inline-block;
        width: 6px;
        height: 11px;
        margin-right: 10px;
        vertical-align: 2px;
        border: 1px solid $color-light;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
        content: '';
    }
}

.st-msg__guide {
    position: absolute;
    left: -29px;
    height: 28px;
    margin-top: 9px;
    padding: 5px 15px;
    background-color: $color-dark;
    border-radius: 14px;
    color: $color-light;
    font-size: 12px;
    white-space: nowrap;

    &:after {
        position: absolute;
        top: -6px;
        left: 47px;
        width: 0;
        height: 0;
        border-right: 4px solid transparent;
        border-bottom: 6px solid $color-dark;
        border-left: 4px solid transparent;
        content: '';
    }

    >p {
        display: inline-block;
        color: $color-light;
        font-size: 12px;
    }

    &.bottom {
        &:after {
            top: auto;
            bottom: -6px;
            left: 50%;
            border-top: 6px solid $color-dark;
            border-bottom: none;
        }
    }

    &.guide-type__2 {
        top: -27px;
        left: 50%;
        margin: 0 0 0 -123px;
    }
}

.text_list {
    li {
        position: relative;
        padding-left: 6px;
        font-size: 13px;
        letter-spacing: 0;

        &+li {
            margin-top: 5px;
            word-break: keep-all;
        }

        &:before {
            position: absolute;
            top: 9px;
            left: 0;
            width: 2px;
            height: 2px;
            margin-right: 4px;
            background-color: $color-light__6;
            vertical-align: 3px;
            content: '';
        }
    }

    &.list-style__lg {
        li {
            padding-left: 8px;
            font-size: 14px;

            &:before {
                top: 9px;
            }
        }
    }
}

// 전시관리> 모바일템플릿설정
.view-guide {
    padding-top: 50px;
    margin-bottom: 30px;
    text-align: center;
    color: $color-dark__2;
    font-size: 18px;
}

.view-item {
    position: relative;
    display: flex;
    padding: 10px 0 12px;

    .item {
        flex: 1;
        text-align: center;

        [class^="radio-style"] {
            margin-right: 0;
        }

        .item-img {
            position: relative;
            width: 292px;
            margin: 20px auto 0;
            padding: 30px 14px;
            border: 1px solid $color-light__5;
            border-radius: 32px;
            background-color: $color-light;

            .item-img__in {
                position: relative;
                z-index: 2;
                height: 690px;
                background-color: $color-light__4;
                border: 1px solid $color-light__5;
                border-radius: 2px;
            }

            span {
                overflow: hidden;
                display: block;
                margin-top: 5px;
                line-height: 9999px;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

            .template-gnb,
            .template-visual,
            .template-store {
                margin-top: 0;
            }

            &.type_visual {
                .img-in {
                    .in_size {
                        transform: scale(0.72) translate(-70px, -1055px);
                        margin-bottom: -1520px;
                    }
                }
            }
        }

        &.active .item-img {
            border-color: transparent;
            box-shadow: 0 2px 6px 0 rgba(29, 29, 29, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.6);
        }

        .bar-wrap {
            width: 2px;
            height: 100%;
            position: absolute;
            top: 0;
            right: -26px;
            overflow: hidden;

            >span {
                visibility: hidden;
                display: block;
                width: 2px;
                background-color: #111111;
            }
        }

        .type_visual .bar-wrap {
            left: -26px;
        }
    }

    .compare {
        position: absolute;
        top: 10px;
        left: 50%;
        display: flex;
        width: 196px;
        height: 750px;
        margin-top: 41px; // 휴대폰 상단 간격
        margin-left: -98px;
        padding: 32px 0; // 휴대폰 베젤
        justify-content: center;

        .btn {
            align-self: center;

            button {
                white-space: nowrap;
            }
        }
    }

    .info {
        position: relative;
        opacity: 0;
        transition: 1s opacity;

        .info-area,
        .info-area__2 {
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 50px;
            background-color: $color-dark;
        }

        .info-area__2 {
            right: 0;
            left: auto;
        }

        .info-name {
            position: relative;
            z-index: 2;
            display: inline-block;
            padding: 8px 0 7px;
            background-color: $color-dark;
            color: $color-light;
            white-space: nowrap;

            &:before {
                position: absolute;
                top: 0;
                bottom: 0;
                left: -20px;
                width: 20px;
                background: url('/img/store/bg/bg_template.png') left top no-repeat;
                content: '';
            }

            &:after {
                position: absolute;
                top: 0;
                bottom: 0;
                right: -20px;
                width: 20px;
                background: url('/img/store/bg/bg_template.png') right top no-repeat;
                content: '';
            }
        }
    }

    // 개발화면동기화
    .scroll-wrap {
        height: 100%;
        transition: 1s opacity;
    }
}

// 전시관리> 스토어대표컬러
.view-skin {
    padding-bottom: 12px;
    text-align: center;

    .view-skin__img {
        width: 392px;
        margin: 30px auto 0;
        padding: 40px 15px;
        border-radius: 32px;
        border: solid 1px $color-light__5;
        background-color: $color-light;

        .img_in {
            overflow-x: hidden;
            overflow-y: auto;
            position: relative;
            z-index: 2;
            height: 715px;
            background-color: $color-light__4;
            border: solid 1px $color-light__5;
            border-radius: 2px;

            span {
                overflow: hidden;
                display: block;
                line-height: 9999px;
                margin-top: 8px;
            }

            .skin_gnb,
            .skin_visual,
            .skin_store {
                margin-top: 0;
            }
        }

        .skin_visual {
            @include sprite-retina($sp_display_skin_01_skin_02visual);
        }

        .skin_banner {
            @include sprite-retina($sp_display_skin_01_skin_11banner);
        }

        &.skin_1 {
            .skin_gnb {
                @include sprite-retina($sp_display_skin_01_skin_01gnb);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_01_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_01_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_01_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_01_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_01_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_01_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_01_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_01_skin_10movie);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_01_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_01_skin_14product);
            }
        }

        &.skin_2 {
            .skin_gnb {
                @include sprite-retina($sp_display_skin_02_skin_01gnb);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_02_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_02_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_02_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_02_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_02_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_02_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_02_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_02_skin_10movie);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_02_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_02_skin_14product);
            }
        }

        &.skin_3 {
            .skin_gnb {
                @include sprite-retina($sp_display_skin_03_skin_01gnb);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_03_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_03_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_03_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_03_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_03_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_03_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_03_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_03_skin_10movie);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_03_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_03_skin_14product);
            }
        }

        &.skin_4 {
            .skin_gnb {
                @include sprite-retina($sp_display_skin_04_skin_01gnb);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_04_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_04_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_04_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_04_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_04_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_04_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_04_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_04_skin_10movie);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_04_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_04_skin_14product);
            }
        }

        &.skin_5 {
            .skin_gnb {
                @include sprite-retina($sp_display_skin_05_skin_01gnb);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_05_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_05_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_05_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_05_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_05_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_05_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_05_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_05_skin_10movie);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_05_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_05_skin_14product);
            }
        }

        &.skin_6 {
            .skin_gnb {
                @include sprite-retina($sp_display_skin_06_skin_01gnb);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_06_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_06_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_06_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_06_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_06_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_06_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_06_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_06_skin_10movie);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_06_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_06_skin_14product);
            }
        }

        &.skin_7 {
            .skin_gnb {
                @include sprite-retina($sp_display_skin_07_skin_01gnb);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_07_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_07_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_07_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_07_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_07_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_07_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_07_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_07_skin_10movie);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_07_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_07_skin_14product);
            }
        }

        &.skin_8 {
            .skin_gnb {
                @include sprite-retina($sp_display_skin_08_skin_01gnb);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_08_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_08_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_08_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_08_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_08_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_08_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_08_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_08_skin_10movie);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_08_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_08_skin_14product);
            }
        }

        &.skin_9 {
            .skin_gnb {
                @include sprite-retina($sp_display_skin_09_skin_01gnb);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_09_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_09_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_09_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_09_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_09_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_09_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_09_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_09_skin_10movie);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_09_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_09_skin_14product);
            }
        }

        &.skin_10 {
            .skin_gnb {
                @include sprite-retina($sp_display_skin_10_skin_01gnb);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_10_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_10_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_10_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_10_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_10_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_10_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_10_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_10_skin_10movie);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_10_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_10_skin_14product);
            }
        }

        &.skin_11 {
            .skin_gnb {
                @include sprite-retina($sp_display_skin_11_skin_01gnb);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_11_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_11_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_11_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_11_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_11_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_11_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_11_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_11_skin_10movie);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_11_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_11_skin_14product);
            }
        }

        &.skin_12 {
            .skin_gnb {
                @include sprite-retina($sp_display_skin_12_skin_01gnb);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_12_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_12_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_12_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_12_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_12_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_12_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_12_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_12_skin_10movie);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_12_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_12_skin_14product);
            }
        }

        &.skin_13 {
            .skin_gnb {
                @include sprite-retina($sp_display_skin_13_skin_01gnb);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_13_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_13_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_13_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_13_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_13_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_13_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_13_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_13_skin_10movie);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_13_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_13_skin_14product);
            }
        }
    }
}

// 전시관리> 스토어홈/전시
.view-display {
    position: relative;
    padding-bottom: 12px;
    text-align: center;

    .view-display__img {
        width: 392px;
        height: calc(100vh - 438px);
        min-height: 700px;
        margin: 30px auto 0;
        padding: 30px 15px;
        border-radius: 32px;
        border: solid 1px $color-light__5;
        background-color: $color-light;

        .img-in {
            overflow-x: hidden;
            overflow-y: auto;
            position: relative;
            z-index: 2;
            height: 100%;
            background-color: $color-light__4;
            border: solid 1px $color-light__5;
            border-radius: 2px;

            span {
                overflow: hidden;
                display: block;
                line-height: 9999px;
                margin-top: 8px;
            }

            .skin_gnb,
            .skin_visual,
            .skin_store {
                margin-top: 0;
            }

            .skin_gnb {
                @include sprite-retina($sp_display_skin_01_skin_01gnb);
            }

            .skin_visual {
                @include sprite-retina($sp_display_skin_01_skin_02visual);
            }

            .skin_store {
                @include sprite-retina($sp_display_skin_01_skin_03store);
            }

            .skin_info {
                @include sprite-retina($sp_display_skin_01_skin_04info);
            }

            .skin_search {
                @include sprite-retina($sp_display_skin_01_skin_05search);
            }

            .skin_recommend {
                @include sprite-retina($sp_display_skin_01_skin_06recommend);
            }

            .skin_shortcut {
                @include sprite-retina($sp_display_skin_01_skin_07shortcut);
            }

            .skin_promotion {
                @include sprite-retina($sp_display_skin_01_skin_08promotion);
            }

            .skin_cardview {
                @include sprite-retina($sp_display_skin_01_skin_09cardview);
            }

            .skin_movie {
                @include sprite-retina($sp_display_skin_01_skin_10movie);
            }

            .skin_banner {
                @include sprite-retina($sp_display_skin_01_skin_11banner);
            }

            .skin_review {
                @include sprite-retina($sp_display_skin_01_skin_13review);
            }

            .skin_product {
                @include sprite-retina($sp_display_skin_01_skin_14product);
            }
        }
    }

    .view-display__area {
        overflow: hidden;
        position: absolute;
        top: 396px;
        left: 50%;
        width: 2px;
        height: 50px;
        margin-left: -208px;
        background-color: $color-brand;
        text-indent: -9999px;
    }
}

// 전시관리> 스토어메인관리> 인스타그램관리
.view-guide__instagram {
    padding: 16px 30px 30px;
    text-align: center;
    color: $color-dark__2;
    font-weight: bold;
    line-height: 1.7;

    &:before {
        display: block;
        margin: 0 auto 16px;
        content: '';
        @include sprite-retina($sp_icon_2x_sns_instagram);
    }
}

// 프로모션
.cont-filter {
    display: flex;
    margin-bottom: 20px;

    .filter-side__2 {
        flex: 1;
        text-align: right;
    }
}

// 기본정보관리
.c-radio__tab {
    .tab_cont {
        margin-top: 15px;
        line-height: 1.6;

        .cont_add {
            display: table;
            width: 100%;
            margin-top: 20px;

            .title {
                display: table-cell;
                width: 93px;
                vertical-align: middle;
                color: $color-dark__2;
                font-weight: normal;
                white-space: nowrap;
            }

            &.type__2 {
                width: auto;

                .title {
                    vertical-align: top;
                }
            }

            .location {
                display: table-cell;
                padding-right: 30px;
                vertical-align: middle;

                &+.text_list {
                    display: table-cell;
                    vertical-align: middle;
                }
            }
        }

        &.disabled .text_url {

            .cont,
            .cont>i {
                color: $color-default;
            }
        }
    }

    .text_url {
        margin-top: 16px;

        .title {
            display: inline-block;
            width: 44px;
            font-size: 13px;
            letter-spacing: 0;
        }

        .cont {
            color: $color-info;
            font-size: 13px;
            letter-spacing: 0;

            >i {
                color: $color-dark__2;
            }
        }
    }
}

.list-item {
    display: table;
    width: 100%;
    white-space: nowrap;

    dt {
        display: table-cell;
        padding-right: 8px;
        vertical-align: middle;
        color: $color-dark__2;
    }

    dd {
        display: table-cell;
        padding-right: 40px;
        vertical-align: middle;

        &:last-of-type {
            padding-right: 0;
        }

        &.dd-size__1 {
            width: 175px;
        }
    }
}

.cont_profile {
    display: table-cell;
    padding-right: 30px;
    vertical-align: middle;

    +.text_list {
        display: table-cell;
        vertical-align: middle;
    }
}

// 카테고리관리
.st-filter__cate {
    padding-top: 10px;
}

.st-cont__cate {
    display: flex;
}

.cate-menu {
    .menu-depth {
        display: flex;

        [class^="menu-depth__"] {
            overflow-x: hidden;
            overflow-y: auto;
            position: relative;
            width: 230px;
            border: 1px solid $color-light__5;
            border-width: 1px 0;
            align-items: stretch;
            // scrollbar-width: none;   // MPQA-46122 스크롤바 다시 보이게 수정

            // &::-webkit-scrollbar {
            //     display: none;
            // }

            &:before {
                // .item.edit_dim 좌우 라인 바깥으로 유지
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 1px solid $color-light__5;
                border-width: 0 1px;
                content: '';
            }

            &+[class^="menu-depth__"] {
                width: 229px;

                &:before,
                .depth_list:before {
                    border-left: none;
                }
            }

            .cate_default & {
                height: 587px;
            }

            .cate_step1 & {
                height: 587px;
            }

            .cate_step2 & {
                height: 524px;
            }

            .cate_step3 & {
                height: 587px;
            }

            &.type_move {
                overflow: visible;
            }
        }
    }

    .depth_btn {
        display: flex;
        padding: 10px;

        button {
            flex: 1;
        }
    }

    .depth_list {
        position: relative;

        // .item.edit_dim 좌우 라인 바깥으로 유지
        &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid $color-light__5;
            border-width: 0 1px;
            content: '';
        }

        .title {
            display: block;
            margin: 0 16px 5px;
            padding: 16px 0 9px;
            border-bottom: 1px solid $color-light__4;
            color: $color-info;
            font-weight: normal;
            font-size: 12px;
        }

        .list {
            .item {
                padding: 1px;

                button {
                    position: relative;
                    width: 100%;
                    padding: 5px 24px 6px 14px;
                    background-color: $color-light;
                    text-align: left;
                    color: $color-dark__2;
                    font-size: 13px;
                    letter-spacing: 0;

                    i {
                        color: $color-info;
                    }

                    // MPQA-46122 span.drag_place -> button.drag 로 변경 요청
                    &.drop {

                        &:before,
                        &:after {
                            display: none;
                            position: absolute;
                            z-index: 10;
                            left: 0;
                            width: 100%;
                            height: 2px;
                            background-color: #f00;
                            content: '';
                        }

                        &.before:before {
                            display: block;
                        }

                        &.after:after {
                            display: block;
                        }

                        &:before {
                            top: -2px;
                        }

                        &:after {
                            bottom: -2px;
                        }
                    }
                }

                &.active button {
                    background-color: $color-light__2;
                    color: $color-brand;
                }

                &.add button:after {
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    width: 8px;
                    height: 8px;
                    margin-top: -4px;
                    border: 1px solid $color-info;
                    border-width: 1px 1px 0 0;
                    vertical-align: middle;
                    transform: rotate(45deg);
                    content: '';
                }

                &.edit_dim button {
                    width: calc(100% + 2px);
                    height: 40px;
                    margin: -1px;
                    padding-top: 8px;
                    padding-bottom: 9px;
                    background-color: $color-light__2;
                    border: 1px solid $color-info;
                    box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.1);
                    color: $color-info;
                    font-size: 14px;
                }

                &.edit_active {
                    @extend .edit_dim;

                    button {
                        background-color: $color-light;
                        color: $color-dark__2;
                    }
                }

                &.drag_off button {
                    padding-top: 9px;
                    padding-bottom: 10px;
                    background-color: $color-light__2;
                    color: $color-info;
                    font-size: 14px;
                    opacity: 0.5;
                    cursor: none;
                }

                &.drag_on button {
                    position: absolute;
                    top: 200px;
                    left: 30px;
                    z-index: 11;
                    width: calc(100% + 2px);
                    margin: 0 -1px;
                    padding-top: 8px;
                    padding-bottom: 9px;
                    background-color: $color-light;
                    box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.1);
                    border: 1px solid $color-default;
                    font-size: 14px;
                    opacity: 0.9;
                    cursor: move;
                }
            }
        }

        .text_item {
            margin: 16px;
            color: $color-dark__3;
            font-size: 13px;
            letter-spacing: 0;

            em {
                color: $color-brand;
            }
        }

        .btn {
            margin: 25px 16px 16px;

            [class^="btn-style"] {
                width: 100%;
            }
        }
    }

    .menu_btn {
        clear: both;
        display: flex;
        padding-top: 10px;
        font-size: 0;

        .side {
            flex: 1;
        }

        .side2 {
            text-align: right;
        }

        .btn-style__img {
            &:first-of-type {
                margin-right: -1px;
            }

            &+.btn-style__img {
                margin-left: 4px;
            }
        }
    }

    .text_alert {
        margin-right: -20px;
    }
}

.cate-view {
    position: relative;
    flex: 1;
    padding-left: 24px;

    &.view-type__2 {
        display: flex;
        margin-left: -1px;
        padding-left: 0;
        background-color: $color-light__2;
        border: 1px solid $color-light__5;
        justify-content: center;
        align-items: center;
    }

    &.view-type__3 {
        padding-bottom: 63px;

        .view-btn__area.area-type__2 {
            position: absolute;
            right: 0;
            bottom: 3px;
            left: 24px;
            padding-bottom: 0;
            border-top: 1px solid $color-light__5;
        }
    }
}

.view-guide__2 {
    text-align: center;

    .text {
        color: $color-dark__2;
        font-size: 18px;
    }

    .btn {
        margin-top: 16px;
    }

    .img {
        margin-top: 30px;

        img {
            display: block;
            width: 408px;
            height: 327px; // MPQA-46243
            margin: 0 auto;
        }

        .alt {
            display: block;
            margin-top: 10px;
            text-align: center;
            color: $color-info;
        }
    }
}

.view-guide__3 {
    .title {
        display: block;
        margin-bottom: 50px;
        color: $color-brand;
        font-size: 18px;

        &.confirm:before {
            display: block;
            width: 30px;
            height: 48px;
            margin: 0 0 20px 13px;
            vertical-align: 2px;
            border: 1px solid $color-brand;
            border-width: 0 6px 6px 0;
            transform: rotate(48deg);
            content: '';
        }
    }

    .text {
        margin-top: 17px;
        color: $color-dark__3;
    }

    .btn {
        margin-top: 50px;
        vertical-align: middle;
    }
}

.area-cont__cate {
    @extend .cate-menu;
    border: 1px solid $color-light__5;

    .menu-depth {
        [class^="menu-depth__"] {
            overflow-y: auto;
            flex: 1;
            width: auto;
            height: 360px;
            padding: 10px 0;
            border: none;

            &:before,
            .depth_list:before {
                content: none;
            }

            +[class^="menu-depth__"] {
                border-left: 1px solid $color-light__5;
            }

            .item button {
                padding: 8px 16px 9px;
            }
        }

        .menu-depth__2 .item button {
            position: relative;
            padding-left: 23px;

            .icon-mark {
                position: absolute;
                top: 50%;
                left: 16px;
                margin-top: -1px;
            }
        }
    }
}

.area-cont__list {
    .item_cate {
        position: relative;
        padding: 8px 8px 10px 16px;
        background-color: $color-light__2;
        border: 1px solid $color-light__4;
        color: $color-dark;

        +.item_cate {
            border-top: none;
        }

        .btn-icon__delete2 {
            position: absolute;
            top: 0;
            right: 2px;
            height: 100%;
        }
    }
}

// 모바일 MW CSS 충돌 재선언 (/html/store/common/mw_preview.html)
// 공통-전체 축소
.cont-menu__item .item {
    .l-grid {
        overflow: hidden;
        width: 360px;
        margin-top: 0;
        transform: scale(0.71) translate(-20.5%, -20.5%);
    }
}

// 공통-슬라이드 좌우버튼
.w-swiper .swiper-button-next,
.w-swiper .swiper-button-prev {
    width: 42px !important;
    height: 42px;
    margin-top: -21px;
    background-color: rgba(17, 17, 17, 0.2);

    &:before {
        width: 11px;
        height: 11px;
        margin: -7px 0 0 -4px;
        border-color: $color-light;
    }
}

// 대표이미지
[data-ui-type=Store_Contents_Home_Swiper] .c-imgcover__more span:after {
    top: 6px;
}

[data-ui-type=Store_Contents_Home_Swiper] .c-pagination__list {
    line-height: 24px;
}

.w-swiper .swiper-button-next {
    &:before {
        margin-left: -6px;
    }
}

.c-imgcover__text .c-imgcover__info {
    line-height: 21px;
}

// 셀러추천1-카드뷰
.c-card {
    &.c-card__dealcard .c-card__name {
        display: block;
        @include text-ellipsis();
    }

    &.c-card__gallery .c-card__info .c-card__name {
        display: block;
        display: -webkit-box;
    }
}

// 셀러추천2-동영상
.c-swiper__none .w-swiper [class*=swiper-button] {
    display: none;
}

// 셀러추천3-프로모션
[data-ui-type=Store_Product_ImgOverPrice] .c-card__onlyprice .c-card__thumb {
    height: 157px;
}

[data-ui-type=Store_Product_Basic] .c-card__gallery .c-card__info {
    height: 74px !important;
}

// 기획전
[data-ui-type=Store_BillBoard] {
    .w-swiper [class*=swiper-button] {
        visibility: visible;
    }

    .swiper-slide img {
        height: 178px; // MPQA-46249
    }
}

@media print,
screen and (min-width: 40em) {
    .l-grid .medium-3 {
        width: 50%;
    }
}


.event_inquiry_box {
    height:338px;

    .event_inquiry_select {
        overflow: hidden;
        margin: 7px 0 16px 0;

        li{
            float:left;
            margin-right:20px;
        }
    }

    .event_number_search {
        min-height: 221px;

        .text {
            margin-top: 13px;
            font-size: 14px;
            color: #111;
        }

        .c-input {
            margin-top: 17px;

            .inp {
                width: 238px;
            }
        }
    }
}
