@charset "UTF-8";

.table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    th {
        height: 40px;
        padding: 4px 7px;
        border: 1px solid $color-light__5;
        background-color: $color-light__3;
        color: $color-dark__2;
        text-align: center;
        font-weight: normal;
        font-size: 13px;
        line-height: 1.5;
    }

    td {
        height: 40px;
        padding: 4px 7px;
        line-height: 1.5;
        color: $color-dark__2;
        border: 1px solid $color-light__5;
        text-align: center;
        font-size: 13px;

        &.non-data {
            height: 270px !important;
            color: $color-dark__3;
            font-size: 15px;

            &:before {
                display: block;
                margin: 0 auto 18px;
                content: '';
                @include sprite-retina($sp_icon_2x_nodata);
            }
        }

        &.non-data__2 {
            height: 300px !important;

            p {
                color: $color-dark__2;
                font-size: 13px;

                &:not(:only-child) {
                    margin-bottom: 20px;
                }
            }
        }
    }

    thead {

        th,
        td {
            height: 48px;
            border: 1px solid $color-light__5;
            border-bottom: none;
            text-align: center;
            color: $color-dark__2;
        }

        &:last-child {
            border-bottom: 1px solid $color-light__5;
        }
    }

    tbody {
        background-color: $color-light;
    }

    .check-style__2 {
        margin: -6px 0;
    }

    .text-brand {
        color: $color-brand;
    }

    .td-side {
        text-align: left;
        padding-left: 12px;
        padding-right: 10px;
    }

    .td-side2 {
        text-align: right;
        padding-left: 10px;
        padding-right: 12px;
    }

    .text-num,
    .text-date {
        letter-spacing: 0;
    }

    .text-title {
        display: block;
        width: 100%;
        padding: 0 5px;
        text-align: left;
        font-size: 13px;
        line-height: 1.8;
        @include text-ellipsis();
    }

    button.text-title {
        color: $color-secondary;
        text-decoration: underline;
    }

    .non-date {
        line-height: 90px;
        font-size: 14px;
        color: $color-dark__2;
        text-align: center;
    }

    tr {
        &.tr_data {

            td,
            th {
                height: 60px;
            }
        }

        &.active {

            th,
            td {
                background-color: #eff1f7;
            }
        }
    }
}

.table.table-type__2 {

    th,
    td {
        height: 49px;
        line-height: 1.5;
    }
}

.table.table-type__3 {

    th,
    td {
        font-size: 14px;
        letter-spacing: 0;
    }

    .td-side {
        padding: 10px 18px 10px 20px;
        vertical-align: top;
    }
}

.table.table-type__4 {
    width: calc(100% - 1px);

    th,
    td {
        color: #666;
        font-size: 14px;
        letter-spacing: 0;
    }

    .td-side {
        padding-left: 20px;
        padding-right: 18px;
    }
}

.table-wrap {
    overflow-x: auto;

    /* MPQC-18126 삭제 */
    &.table-style__2 {
        min-height: 320px;
    }
    /* // MPQC-18126 삭제 */
}
