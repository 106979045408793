@charset "UTF-8";

@import "../store/helpers/variables"; //변수모음

@font-face {
    font-family: "Helvetica Neue";
    src: local("Helvetica Neue");
    unicode-range: U+0020;
}

@font-face {
    font-family: "Arial";
    src: local("Arial");
    unicode-range: U+0020;
}

/* noto-sans-kr-regular - latin */
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'),
        url('/css/fonts/noto-sans-kr-v11-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/css/fonts/noto-sans-kr-v11-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'),
        url('/css/fonts/roboto-v19-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/css/fonts/roboto-v19-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
textarea,
button {
    margin: 0;
    padding: 0;
}

.js-focus-visible {
    button:focus:not(.focus-visible) {
        outline: 0;
    }
}

html {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

body {
    width: 100%;
    height: 100%;
}

dl,
ul,
ol,
menu,
li {
    list-style: none;
}

img,
fieldset,
iframe {
    border: 0 none;
}

img {
    vertical-align: top;
}

input,
select,
textarea,
button {
    vertical-align: middle;
}

input::-ms-clear {
    display: none;
}

button {
    border: 0 none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 0;
    box-sizing: border-box;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
}

legend {
    position: absolute;
    top: -9999px;
    left: -9999px;
    font-size: 0px;
    line-height: 0;
}

caption {
    color: transparent;
    line-height: 0;
    clip: rect(1px,1px,1px,1px);
}

a {
    color: #666;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &:active {
        background-color: transparent;
    }
}

body {

    &,
    blockquote,
    button,
    dd,
    div,
    dl,
    dt,
    fieldset,
    form,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    input,
    li,
    ol,
    p,
    pre,
    select,
    td,
    textarea,
    th,
    ul {
        font-size: 14px;
        line-height: 1.5;
        font-family: $font-family-default;
        color: #666;
        letter-spacing: -0.5px;
    }
}

hr {
    display: none;
}

address,
caption,
cite,
code,
dfn,
em,
var {
    font-style: normal;
    font-weight: normal;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
main {
    display: block;
}

blockquote,
q {
    quotes: none;
}

blockquote {

    &:before,
    &:after {
        content: '';
        content: none;
    }
}

q {

    &:before,
    &:after {
        content: '';
        content: none;
    }
}

i {
    font-style: normal;
}

.hide,
.skip {
    position: absolute;
}

.js-focus-visible {
    *:focus:not(.focus-visible) {
        outline: 0;
    }
}
