@charset "UTF-8";

// 입력
.write-box {
    .write-box__row {
        & + .write-box__row {
            margin-top: 20px;
        }

        &.inline {
            display: table;
            width: 100%;

            .row_title {
                display: table-cell;
                width: 136px;
                padding: 9px 0;
                vertical-align: top;
                color: $color-dark__2;
            }

            .row_cont {
                display: table-cell;
                vertical-align: top;

                [class^="radio-style"] {
                    margin-top: 9px;
                    margin-bottom: 9px;
                }
            }
        }

        &.inline-type__2 {
            @extend .write-box__row.inline;

            .row_title {
                width: 110px;
            }
        }

        .row_title {
            position: relative;
            margin-bottom: 20px;
            padding: 10px 170px 0 0;
            color: $color-dark__3;
            font-weight: bold;

            i {
                font-weight: normal;
            }

            p {
                padding-top: 6px;
                color: $color-info;

                & + .side_btn {
                    .btn-size__6 {
                        margin-top: -4px;
                    }
                }
            }

            .side_btn {
                position: absolute;
                top: 50%;
                right: 0;

                .btn-size__6 {
                    margin-top: -13px;
                }
            }
        }
    }

    .view-item__select {
        .item {
            display: inline-block;
            vertical-align: top;

            + .item {
                margin-left: 46px;
            }

            .item_cont {
                width: 200px;
                margin-top: 10px;

                > img {
                    width: 100%;
                    height: 100%;
                    border: solid 1px rgba(0, 0, 0, 0.08);
                }
            }

            // MPQA-45216 높이 지정
            &.item-style__1 > .item_cont {
                height: 200px;
            }

            &.item-style__2 > .item_cont {
                height: 134px;
            }
        }
    }

    .view-item__editor {
        display: flex;
        height: 300px;
        background-color: $color-light;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px solid $color-light__5;
    }
}

.write-box.type_bg {
    position: relative;
    padding: 24px;
    background-color: $color-light__2;
    border: 1px solid $color-light__4;

    & + & {
        margin-top: 24px;
    }

    .write-box__row {
        &.inline {
            .row_title {
                width: 86px;
            }
        }

        &.add {
            display: table;
            width: 100%;

            .add_upload {
                display: table-cell;
                width: 160px;
                padding-right: 24px;
                vertical-align: top;

                & + .box_sub {
                    padding-top: 15px;
                }

                &.upload-type__2 + .box_sub {
                    padding-top: 0;
                }
            }
        }

        &.info {
            margin-bottom: 6px;
            color: $color-dark__3;

            p {
                color: $color-dark__3;
            }
        }

        &.divide {
            margin-top: 30px;
        }

        .row_title {
            font-weight: normal;
        }
    }

    .split {
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid $color-light__5;
    }

    .btn-icon__delete {
        position: absolute;
        right: 15px;
        top: 15px;
    }
}

.write-box__2 {
    @extend .write-box;

    &.type_bg {
        padding-right: 58px;

        .write-box__row.inline .row_title {
            width: 136px;
        }
    }
}

.write-box__3 {
    @extend .write-box;

    .write-box__row {
        padding: 24px;

        & + .write-box__row {
            margin-top: 0;
            border-top: 1px solid $color-light__4;
        }

        &.inline {
            .row_title {
                width: 176px;
                padding: 5px 0;
            }

            .row_cont {
                [class^="radio-style"] {
                    margin-top: 5px;
                    margin-bottom: 4px;
                }
            }
        }

        .area-keyword {
            + .c-check__keyword {
                margin-top: 20px;
            }

            .area-keyword__selected {
                position: relative;
                display: inline-block;
                min-width: 412px;
                min-height: 40px;
                padding: 8px 12px;
                background-color: $color-light;
                border: 1px solid $color-light__5;
                vertical-align: middle;
                line-height: 22px;
            }

            .placeholder {
                position: absolute;
                top: 8px;
                left: 12px;
                color: $color-info;
            }

            .item {
                position: relative;
                padding-right: 12px;
                background-color: $color-light;
                color: $color-dark__2;

                .btn-style__icon {
                    margin-top: -4px;
                }
            }
        }
    }
}

.write-box__4 {
    @extend .write-box;
    margin-bottom: 30px;
    border-color: $color-light__5 !important;

    &.type_bg {
        padding: 20px 24px;

        .write-box__row.inline .row_title {
            width: 116px;
            font-weight: bold;
        }
    }

    .write-box__row {
        &.inline {
            .row_title {
                padding: 0;
                vertical-align: middle;
            }

            .row_cont [class^="radio-style"] {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}

// 입력 -배너설정
.write-box__btn {
    margin-top: 24px;
    padding: 0 30px;
    text-align: center;
}

.box_sub {
    .box_sub_row + .box_sub_row {
        margin-top: 16px;
    }
}

// 검색
.search-box {
    margin-bottom: 30px;
    padding-bottom: 40px;
    border-bottom: 1px solid $color-light__4;

    .write-box__row {
        display: table;
        width: 100%;

        & + .write-box__row {
            margin-top: 20px;
        }

        .row_title {
            display: table-cell;
            width: 90px;
            padding: 9px 0;
            vertical-align: top;
            color: $color-dark__2;
            letter-spacing: -0.5px;
        }

        .row_cont {
            display: table-cell;
            vertical-align: top;

            .cont_flex {
                display: flex;

                .c-dropdown {
                    flex: 1;

                    .dropdown {
                        width: 100%;
                    }

                    .btn {
                        width: 100%;
                    }

                    & + .c-dropdown {
                        margin-left: 11px;
                    }
                }
            }
        }
    }

    .box_btn {
        padding-top: 30px;
        text-align: center;
    }

    &.box-type__bg {
        padding: 20px 24px;
        background-color: $color-light__2;
        border: 1px solid $color-light__5;
    }
}

// Pagination
%paging {
    margin-top: 20px;
    text-align: center;
    font-size: 0;

    a,
    button,
    em {
        display: inline-block;
        width: 48px;
        height: 48px;
        color: #111;
        font-size: 14px;
        line-height: 48px;
        vertical-align: middle;
        font-family: $font-family-number;
        background: transparent;
    }

    a,
    button {
        &.first,
        &.prev,
        &.next,
        &.last {
            overflow: hidden;
            position: relative;
            z-index: 1;
            background-color: $color-light;
            color: $color-light__6;
            line-height: 200px;

            &:before,
            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 8px;
                height: 8px;
                border: 1px solid $color-info;
                border-width: 1px 1px 0 0;
            }
        }

        &.first {
            &:before {
                margin: -4px 0 0 -4px;
                transform: rotate(-135deg);
            }

            &:after {
                margin: -4px 0 0 2px;
                transform: rotate(-135deg);
            }

            & + .prev {
                border-left: none;
            }
        }

        &.prev {
            &:before {
                margin: -4px 0 0 -2px;
                transform: rotate(-135deg);
            }

            &:after {
                display: none;
            }
        }

        &.next {
            &:before {
                margin: -4px 0 0 -4px;
                transform: rotate(45deg);
            }

            &:after {
                display: none;
            }

            & + .last {
                border-left: none;
            }
        }

        &.last {
            &:before {
                margin: -4px 0 0 -8px;
                transform: rotate(45deg);
            }

            &:after {
                margin: -4px 0 0 -2px;
                transform: rotate(45deg);
            }
        }
    }

    em {
        color: $color-brand;
        font-weight: bold;
    }

    a.disabled,
    button:disabled {
        &.first,
        &.prev,
        &.next,
        &.last {
            &:before,
            &:after {
                border-color: rgba(0, 0, 0, 0.12);
            }
        }
    }
}

.c-paging {
    @extend %paging;
}
