@charset "UTF-8";

.icon {
    overflow: hidden;
    display: inline-block;
    height: 0;
    vertical-align: middle;
    overflow: hidden;
    line-height: 200px;
}

.icon-must {
    margin: -2px 0 0 -2px;
    @include sprite-retina($sp_icon_2x_must);
}

.icon-play {
    margin-right: 2px;
    @include sprite-retina($sp_icon_2x_play);

    button & {
        vertical-align: -4px;
    }
}

.icon-add {
    position: relative;
    width: 8px;
    height: 8px;
    margin: -3px 3px 0 0;

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 100%;
        margin: -4px 0 0 -1px;
        background-color: $color-brand;
        content: '';
    }

    &:after {
        width: 100%;
        height: 2px;
        margin: -1px 0 0 -4px;
    }

    .btn-size__1 & {
        width: 10px;
        height: 10px;

        &:before {
            margin-top: -5px;
        }

        &:after {
            margin-left: -5px;
        }
    }

    [class^="btn-style"]:disabled & {
        &:before,
        &:after {
            background-color: $color-light__6;
        }
    }
}

.icon_excel {
    @include sprite-retina($sp_icon_2x_down_excel);
    margin-right: 1px;
}

// 전시 메뉴
.icon-order {
    position: absolute;
    top: 50%;
    left: 16px;
    margin-top: -10px;
    @include sprite-retina($sp_icon_2x_order);

    .btn-style__3 & {
        @include sprite-retina($sp_icon_2x_order_on);
    }
}

// 카테고리관리
.icon-mark {
    width: 3px;
    height: 3px;
    margin: -2px 2px 0 0;
    background-color: $color-dark__2;
    border-radius: 50%;
}

.icon-link {
    overflow: hidden;
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 1px solid $color-info;
    border-width: 1px 1px 0 0;
    vertical-align: -1px;
    transform: rotate(45deg) translateY(-50%);
    font-size: 0;
    line-height: 200px;
    white-space: nowrap;
}

// 공지관리
.icon-11st {
    @include sprite-retina($sp_icon_2x_editor_11st);
}

// flag
.flag-em {
    position: relative;
    display: inline-block;
    min-width: 30px;
    margin-right: 1px;
    padding: 0 4px;
    color: $color-brand;
    border: 1px solid $color-brand;
    border-radius: 2px;
    font-size: 11px;
    line-height: 1.6;

    &:after {
        position: absolute;
        top: -1px;
        right: -4px;
        bottom: -1px;
        width: 3px;
        background-color: $color-light;
        content: '';
    }
}
