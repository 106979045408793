// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sp_icon_search: (0px, 0px, 0px, 0px, 22px, 22px, 22px, 22px, '/img/store/sprites/sp_icon.png', 'sp_icon_search', );
$spritesheet_sprites: ($sp_icon_search, );
$spritesheet: (22px, 22px, '/img/store/sprites/sp_icon.png', $spritesheet_sprites, );


