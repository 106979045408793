@charset "UTF-8";
@font-face { font-family: "Helvetica Neue"; src: local("Helvetica Neue"); unicode-range: U+0020; }

@font-face { font-family: "Arial"; src: local("Arial"); unicode-range: U+0020; }

/* noto-sans-kr-regular - latin */
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 400; src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("/css/fonts/noto-sans-kr-v11-latin-regular.woff2") format("woff2"), url("/css/fonts/noto-sans-kr-v11-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-regular - latin */
@font-face { font-family: 'Roboto'; font-style: normal; font-weight: 400; src: local("Roboto"), local("Roboto-Regular"), url("/css/fonts/roboto-v19-latin-regular.woff2") format("woff2"), url("/css/fonts/roboto-v19-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

*, *:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, textarea, p, blockquote, th, td, input, select, textarea, button { margin: 0; padding: 0; }

.js-focus-visible button:focus:not(.focus-visible) { outline: 0; }

html { overflow-y: auto; width: 100%; height: 100%; background-color: #fff; }

body { width: 100%; height: 100%; }

dl, ul, ol, menu, li { list-style: none; }

img, fieldset, iframe { border: 0 none; }

img { vertical-align: top; }

input, select, textarea, button { vertical-align: middle; }

input::-ms-clear { display: none; }

button { border: 0 none; background-color: transparent; cursor: pointer; border-radius: 0; box-sizing: border-box; }

table { border-collapse: collapse; border-spacing: 0; empty-cells: show; }

legend { position: absolute; top: -9999px; left: -9999px; font-size: 0px; line-height: 0; }

caption { color: transparent; line-height: 0; clip: rect(1px, 1px, 1px, 1px); }

a { color: #666; text-decoration: none; }

a:hover { text-decoration: underline; }

a:active { background-color: transparent; }

body, body blockquote, body button, body dd, body div, body dl, body dt, body fieldset, body form, body h1, body h2, body h3, body h4, body h5, body h6, body input, body li, body ol, body p, body pre, body select, body td, body textarea, body th, body ul { font-size: 14px; line-height: 1.5; font-family: "Noto Sans CJK KR", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; letter-spacing: -0.5px; }

hr { display: none; }

address, caption, cite, code, dfn, em, var { font-style: normal; font-weight: normal; }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, main { display: block; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after { content: ''; content: none; }

q:before, q:after { content: ''; content: none; }

i { font-style: normal; }

.hide, .skip { position: absolute; }

.js-focus-visible *:focus:not(.focus-visible) { outline: 0; }

.skip { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.color_brand { color: #f43142 !important; }

.skip_nav { position: absolute; z-index: 9999; }

.skip_nav a { display: block; overflow: hidden; height: 0; margin: 0 auto; height: 1px; margin-bottom: -1px; white-space: nowrap; text-align: center; text-indent: -9999px; }

.c-browser { position: relative; left: 0; top: 0; z-index: 300; width: 100%; min-width: 900px; height: 60px; padding: 0 40px; border-bottom: 1px solid #ddd; background-color: #e5f2fb; text-align: center; color: #333; font-weight: bold; font-size: 16px; line-height: 60px; letter-spacing: -0.3px; }

.c-browser:before { display: inline-block; background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -132px -116px; width: 24px; height: 24px; background-size: 215px 165px; vertical-align: middle; margin: -2px 5px 0 0; content: ''; }

.c-browser em { color: #0b83e6; }

.c-browser .btn-close { position: absolute; z-index: 2; top: 10px; right: 20px; width: 40px; height: 40px; overflow: hidden; line-height: 200px; }

.c-browser .btn-close:before, .c-browser .btn-close:after { position: absolute; width: 18px; height: 1px; top: 50%; left: 11px; margin-top: -1px; background: #333; content: ''; }

.c-browser .btn-close:before { transform: rotate(45deg); }

.c-browser .btn-close:after { transform: rotate(-45deg); }

.st-wrap { overflow: hidden; min-width: 1400px; min-height: 100vh; background-color: #f3f3f3; }

.st-header { background-color: #fff; box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15); }

.st-header__seller .seller_in { position: relative; display: flex; align-items: center; width: 1200px; margin: 0 auto; padding: 30px 0; }

.st-header__seller .seller_in .seller_title { margin-right: 16px; }

.st-header__seller .seller_in .seller_title:after { display: inline-block; width: 1px; height: 20px; margin: 0 0 -2px 16px; background-color: #eee; vertical-align: middle; content: ''; }

.st-header__seller .seller_in .seller_title .logo_11st { overflow: hidden; display: inline-block; margin-right: 4px; vertical-align: middle; line-height: 200px; background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -132px 0px; width: 56px; height: 24px; background-size: 215px 165px; }

.st-header__seller .seller_in .seller_title .logo_store { overflow: hidden; vertical-align: middle; line-height: 200px; background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: 0px -35px; width: 114px; height: 23px; background-size: 215px 165px; }

.st-header__seller .seller_in .seller_info { flex: 1; }

.st-header__seller .seller_in .seller_info .info_img { position: relative; display: inline-block; margin-right: 4px; padding-right: 6px; vertical-align: middle; }

.st-header__seller .seller_in .seller_info .info_img .img { overflow: hidden; position: relative; display: block; width: 40px; height: 40px; border: solid 1px rgba(0, 0, 0, 0.04); border-radius: 50%; background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -53px -63px; width: 40px; height: 40px; background-size: 215px 165px; /* MPQC-17908 배포 후 삭제 */ /* // MPQC-17908 배포 후 삭제 */ }

.st-header__seller .seller_in .seller_info .info_img .img .img_in { overflow: hidden; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-position: center center; background-repeat: no-repeat; background-size: cover; border-radius: 50%; color: transparent; font-size: 0; line-height: 200px; }

.st-header__seller .seller_in .seller_info .info_img .img > img { position: absolute; top: 0; right: 0; bottom: 0; left: 0; max-width: 100%; max-height: 100%; margin: auto; }

.st-header__seller .seller_in .seller_info .info_img .btn-icon__edit { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.st-header__seller .seller_in .seller_info .info_img .btn-icon__edit:before { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -161px -116px; width: 24px; height: 24px; background-size: 215px 165px; position: absolute; right: 0; bottom: 0; content: ''; }

.st-header__seller .seller_in .seller_info > span { vertical-align: middle; }

.st-header__seller .seller_in .seller_info .info_title { padding-right: 4px; vertical-align: middle; color: #111; font-size: 18px; }

.st-header__seller .seller_in .seller_info .info_title:after { display: inline-block; width: 8px; height: 8px; margin: 0 0 -2px -1px; border: 1px solid #111; border-width: 1px 1px 0 0; vertical-align: middle; transform: rotate(45deg) translateY(-50%); content: ''; }

.st-header__seller .seller_in .seller_info .info_visit { margin-left: 14px; padding: 0 5px; color: #999; font-size: 15px; }

.st-header__seller .seller_in .seller_info .info_visit:after { display: inline-block; width: 0; height: 0; margin-left: 8px; border-right: 4px solid transparent; border-top: 5px solid #666; border-left: 4px solid transparent; transform: translateY(-50%); opacity: 0.5; content: ''; }

.st-header__seller .seller_in .seller_info .info_visit em { margin-left: 4px; color: #0b83e6; }

.st-header__seller .seller_in .seller_visit { display: none; position: absolute; z-index: 99; top: 74px; left: 50%; width: 738px; margin-left: -369px; padding: 30px; background-color: #fff; box-shadow: 0 1px 10px 0 rgba(29, 29, 29, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.15); }

.st-header__seller .seller_in .seller_visit.active { display: block; }

.st-header__seller .seller_in .seller_visit .visit_title { margin-bottom: 10px; font-size: 13px; }

.st-header__seller .seller_in .seller_visit .visit_title .title { margin-right: 4px; vertical-align: -1px; color: #111; font-size: 18px; letter-spacing: 0; }

.st-header__seller .seller_in .seller_visit .visit_graph .graph_date { margin-bottom: 10px; }

.st-header__seller .seller_in .seller_visit .visit_graph .graph_date span { font-size: 13px; }

.st-header__seller .seller_in .seller_visit .visit_graph .graph_date span:before { display: inline-block; width: 9px; height: 4px; margin-right: 4px; vertical-align: middle; background-color: #ddd; border: solid 1px rgba(0, 0, 0, 0.03); border-radius: 2px; line-height: 0; content: ''; }

.st-header__seller .seller_in .seller_visit .visit_graph .graph_date span:nth-of-type(2):before { background-color: #0b83e6; }

.st-header__seller .seller_in .seller_visit .visit_graph .graph_date span + span { margin-left: 16px; }

.st-header__seller .seller_in .seller_visit .visit_period { display: flex; align-items: center; margin-top: 25px; }

.st-header__seller .seller_in .seller_visit .visit_period span { color: #111; font-weight: bold; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; }

.st-header__seller .seller_in .seller_visit .visit_period .btn { flex: 1; text-align: right; }

.st-header__seller .seller_in .seller_visit .visit_period .btn .text_sub { display: inline-block; margin-right: 8px; vertical-align: middle; color: #666; font-size: 13px; }

.st-header__seller .seller_in .seller_visit .visit_btn { position: absolute; top: 14px; right: 14px; }

.st-header__seller .seller_in .seller_menu { padding-right: 3px; text-align: right; font-size: 0px; }

.st-header__seller .seller_in .seller_menu li { display: inline-block; }

.st-header__seller .seller_in .seller_menu li + li:before { display: inline-block; width: 1px; height: 10px; background-color: #eee; vertical-align: -1px; content: ''; }

.st-header__seller .seller_in .seller_menu li a, .st-header__seller .seller_in .seller_menu li button { display: inline-block; padding: 5px 6px; vertical-align: middle; font-size: 15px; letter-spacing: 0; }

.st-header__seller .seller_in .seller_menu li a:hover, .st-header__seller .seller_in .seller_menu li button:hover { text-decoration: none; }

.st-nav__gnb { border-top: 1px solid #eee; }

.st-nav__gnb .gnb_in { width: 1200px; margin: 0 auto; }

.st-nav__gnb .gnb_in ul { display: flex; }

.st-nav__gnb .gnb_in ul li { flex: 1; text-align: center; }

.st-nav__gnb .gnb_in ul li button { width: 100%; }

.st-nav__gnb .gnb_in ul li button span { display: inline-block; padding: 18px 0; color: #333; font-size: 16px; letter-spacing: 0; }

.st-nav__gnb .gnb_in ul li.active button > span { padding-bottom: 16px; border-bottom: 2px solid #f43142; color: #f43142; font-weight: bold; }

.st-content { width: 1200px; margin: 40px auto 0; background-color: #f3f3f3; }

.st-content .content-in { min-height: calc(100vh - 625px); background-color: #fff; box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15); }

.st-content .content-in .view-cont { padding: 30px 24px 40px; background-color: #fff; }

.st-content .content-in .view-cont.cont-type__full { padding: 0; }

.st-content .content-in + .content-in { margin-top: 40px; }

.add_area .content-in { min-height: calc(100vh - 701px); }

.add_area .st-tab__cont.cont-type__2 { min-height: calc(100vh - 781px); }

.add_area .footer-box { padding-bottom: 110px; }

.st-tab__menu { background-color: #fff; box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15); }

.st-tab__menu ul { display: flex; }

.st-tab__menu ul li { flex: 1; background-color: #fff; }

.st-tab__menu ul li button { position: relative; width: 100%; padding: 17px 10px; text-align: center; color: #000; }

.st-tab__menu ul li button:before { display: inline-block; margin: -2px 4px 0 0; vertical-align: middle; content: ''; }

.st-tab__menu ul li:nth-of-type(1) button:before { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -66px -116px; width: 28px; height: 28px; background-size: 215px 165px; }

.st-tab__menu ul li:nth-of-type(2) button:before { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -132px -64px; width: 28px; height: 28px; background-size: 215px 165px; }

.st-tab__menu ul li:nth-of-type(3) button:before { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: 0px -116px; width: 28px; height: 28px; background-size: 215px 165px; }

.st-tab__menu ul li.active { border-bottom-color: #fff; }

.st-tab__menu ul li.active button { color: #f43142; font-weight: bold; }

.st-tab__menu ul li.active:nth-of-type(1) button:before { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -99px -116px; width: 28px; height: 28px; background-size: 215px 165px; }

.st-tab__menu ul li.active:nth-of-type(2) button:before { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -98px -63px; width: 28px; height: 28px; background-size: 215px 165px; }

.st-tab__menu ul li.active:nth-of-type(3) button:before { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -33px -116px; width: 28px; height: 28px; background-size: 215px 165px; }

.st-tab__menu ul li + li { border-left: 1px solid #ddd; }

.st-tab__cont { margin-top: 20px; padding-bottom: 28px; background-color: #fff; border-top: none; box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15); }

.st-tab__cont.cont-type__2 { display: flex; min-height: calc(100vh - 705px); margin-bottom: 0; padding-bottom: 0; }

.st-tab__cont.cont-type__2 .cont-view { flex: 1; padding: 0 24px 28px; }

.st-tab__cont.cont-type__2 .cont-view .view-btn { padding-bottom: 0; }

.st-tab__cont.cont-type__2 { position: relative; }

.st-tab__cont.cont-type__2 .cont-menu { width: 290px; border-right: 1px solid #ddd; }

.st-tab__cont.cont-type__2.fixed .cont-menu, .st-tab__cont.cont-type__2.fixed_end .cont-menu { position: fixed; top: 0; left: calc(100vw - 1200px)/2; z-index: 99; width: 290px; height: 100vh; background-color: #fff; border-right: 1px solid #ddd; }

.st-tab__cont.cont-type__2.fixed .cont-menu .cont-menu__item, .st-tab__cont.cont-type__2.fixed_end .cont-menu .cont-menu__item { min-height: 0; }

.st-tab__cont.cont-type__2.fixed .cont-menu + .cont-view, .st-tab__cont.cont-type__2.fixed_end .cont-menu + .cont-view { padding-left: 314px; }

.st-tab__cont.cont-type__2.fixed .cont-view .view-title.title-style__3 { position: fixed; z-index: 5; top: 0; width: 910px; margin: 0 -24px; padding: 24px; background-color: #fff; }

.st-tab__cont.cont-type__2.fixed_end .cont-menu { position: absolute; top: auto; bottom: 0; }

.st-tab__cont.cont-type__2 .cont-menu__edit { padding: 20px 16px; }

.st-tab__cont.cont-type__2 .cont-menu__guide { margin: 0 16px; padding: 15px 0; border-top: 1px solid #eee; text-align: center; color: #111; font-size: 13px; }

.st-tab__cont.cont-type__2 .cont-menu__guide:before { display: inline-block; vertical-align: middle; background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -132px -29px; width: 40px; height: 30px; background-size: 215px 165px; content: ''; }

.st-tab__cont.cont-type__2 .cont-menu__item { position: relative; min-height: 703px; height: calc(100% - 88px); padding: 0 16px; }

.st-tab__cont.cont-type__2 .cont-menu__item .menu-item__scroll { position: absolute; top: 0; right: 8px; bottom: 0; left: 16px; }

.st-tab__cont.cont-type__2 .cont-menu__item .menu-item__scroll > div > div { padding-top: 1px; }

.st-tab__cont.cont-type__2 .cont-menu__item .item { background-color: #fff; border-top: 1px solid #eee; }

.st-tab__cont.cont-type__2 .cont-menu__item .item:last-of-type { border-bottom: 1px solid #eee; }

.st-tab__cont.cont-type__2 .cont-menu__item .item.active { position: relative; overflow: hidden; margin: -1px 0 16px; border-radius: 2px; border: solid 1px #999; box-shadow: 1px 2px 4px 0 rgba(29, 29, 29, 0.2); }

.st-tab__cont.cont-type__2 .cont-menu__item .item.active.item-type__link button { margin-left: 1px; width: calc(100% - 2px); }

.st-tab__cont.cont-type__2 .cont-menu__item .item.active .item_cont { overflow: hidden; display: block; }

.st-tab__cont.cont-type__2 .cont-menu__item .item_title { position: relative; background-color: #fff; }

.st-tab__cont.cont-type__2 .cont-menu__item .item_title button { margin: 0 0 1px 2px; padding: 20px 10px 19px; text-align: left; color: #000; font-size: 15px; letter-spacing: -0.5px; width: calc(100% - 3px); }

.st-tab__cont.cont-type__2 .cont-menu__item .item_title button i { color: #999; font-size: 14px; }

.st-tab__cont.cont-type__2 .cont-menu__item .item_title button i:before { display: inline-block; width: 1px; height: 12px; margin: -5px 7px 0 3px; background-color: #eee; vertical-align: middle; content: ''; }

.st-tab__cont.cont-type__2 .cont-menu__item .item_title button.drop:before, .st-tab__cont.cont-type__2 .cont-menu__item .item_title button.drop:after { display: none; position: absolute; z-index: 10; left: 0; width: 100%; height: 2px; background-color: #f00; content: ''; }

.st-tab__cont.cont-type__2 .cont-menu__item .item_title button.drop.before:before { display: block; }

.st-tab__cont.cont-type__2 .cont-menu__item .item_title button.drop.after:after { display: block; }

.st-tab__cont.cont-type__2 .cont-menu__item .item_title button.drop:before { top: -2px; }

.st-tab__cont.cont-type__2 .cont-menu__item .item_title button.drop:after { bottom: -2px; }

.st-tab__cont.cont-type__2 .cont-menu__item .item_title .check-style__2 { padding: 10px 0; position: absolute; top: 0; right: 0; }

.st-tab__cont.cont-type__2 .cont-menu__item .item_cont { display: none; border-top: 1px solid #eee; }

.st-tab__cont.cont-type__2 .cont-menu__item .item_cont img { display: block; width: 100%; }

.st-tab__cont.cont-type__2 .cont-menu__item.type_move { position: relative; height: auto; }

.st-tab__cont.cont-type__2 .cont-menu__item.type_move .menu-item__scroll { position: static; }

.st-tab__cont.cont-type__2 .cont-menu__item.type_move .item { position: relative; margin-top: -1px; border-top: none; }

.st-tab__cont.cont-type__2 .cont-menu__item.type_move .item + .item { margin-top: 16px; }

.st-tab__cont.cont-type__2 .cont-menu__item.type_move .item:after { position: absolute; top: 50%; right: 6px; margin-top: -8px; background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -119px -35px; width: 3px; height: 17px; background-size: 215px 165px; content: ''; }

.st-tab__cont.cont-type__2 .cont-menu__item.type_move .item .item_title { box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.4); cursor: move; cursor: grab; }

.st-tab__cont.cont-type__2 .cont-menu__item.type_move .item button { padding-left: 16px; cursor: move; cursor: grab; }

.st-tab__cont.cont-type__2 .cont-menu__item.type_move .item [class^="check-style"] { display: none; }

.st-tab__cont.cont-type__2 .cont-menu__item.type_move .item.drag_off .item_title { background-color: #fafafa; box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.2); opacity: 0.5; }

.st-tab__cont.cont-type__2 .cont-menu__item.type_move .item.drag_off .item_title, .st-tab__cont.cont-type__2 .cont-menu__item.type_move .item.drag_off button { background-color: transparent; color: #999; cursor: none; }

.st-tab__cont.cont-type__2 .cont-menu__item.type_move .item.drag_on .item_title { z-index: 2; width: 100%; height: 63px; border: solid 1px #666; background-color: rgba(255, 255, 255, 0.9); box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.15); cursor: move; }

.st-tab__cont.cont-type__2 .cont-menu__item.type_move .item.drag_on button, .st-tab__cont.cont-type__2 .cont-menu__item.type_move .item.drag_on [class^="check-style"] label { cursor: move; }

.st-tab__cont.cont-type__2 .cont-menu__item.type_move .drag_place { display: block; height: 4px; margin: 10px 0; background-color: #f43142; }

.view-cont .cont-type__2 { display: flex; }

.view-cont .cont-type__2 .cont-view { flex: 1; }

.view-cont .view-title:first-child { margin-top: 0; }

.view-title.title-style__2, .view-title.title-style__3 { padding: 24px; background-color: #fff; border-bottom: 1px solid #f3f3f3; }

.view-title.title-style__2 .title, .view-title.title-style__3 .title { color: #111; font-weight: normal; font-size: 18px; }

.view-title.title-style__3 { margin-bottom: 24px; padding-right: 0; padding-left: 0; }

.view-title.title-style__4 { margin: 30px 0 20px; }

.write-box + .view-title.title-style__4, .write-box__2 + .view-title.title-style__4, .write-box__3 + .view-title.title-style__4, .write-box__4 + .view-title.title-style__4 { margin-top: 40px; }

.view-title.title-style__4 .title { color: #000; letter-spacing: -1px; }

.view-title.title-style__4 .title i { color: #111; font-weight: normal; }

.cont-btn { position: fixed; right: 0; bottom: 0; left: 0; z-index: 98; padding: 10px 24px; text-align: center; background-color: #fafafa; border-top: 1px solid #ccc; }

.view-btn__area, .view-btn, .st-bottom { padding: 16px 24px; border-top: 1px solid #eee; text-align: center; /* MPQC-18126 삭제 */ /* // MPQC-18126 삭제 */ }

.view-btn__area.area-type__2, .area-type__2.view-btn, .area-type__2.st-bottom { position: relative; z-index: 2; margin-top: -1px; padding-bottom: 0; border-top: 1px solid #ddd; }

.view-btn { position: relative; margin-top: 40px; padding: 20px 24px; }

.st-bottom { position: relative; margin: 20px 0 -40px; background-color: #fafafa; border-top: 1px solid #ccc; }

.footer-box { margin-top: 40px; padding-bottom: 34px; background-color: #eee; }

.footer-box .footer-box__link { background-color: #ddd; height: 54px; font-size: 0; }

.footer-box .footer-box__link .link_in { display: flex; width: 1200px; margin: 0 auto; }

.footer-box .footer-box__link li { flex: 1 1 auto; position: relative; z-index: 1; display: inline-block; text-align: center; }

.footer-box .footer-box__link li a { font-size: 12px; color: #333; line-height: 54px; letter-spacing: -0.9px; }

.footer-box .footer-box__link li:before { position: absolute; z-index: 1; top: 50%; left: 0; margin-top: -4px; display: block; width: 1px; height: 8px; opacity: 0.65; background-color: #ccc; content: ''; }

.footer-box .footer-box__link li:first-child:before { display: none; }

.footer-box .footer-box__link li:nth-child(4) a { font-weight: bold; }

.footer-box .footer-box__info { display: table; width: 1200px; margin: 0 auto; padding: 30px 0 27px; border-bottom: 1px solid #eee; }

.footer-box .footer-box__info .logo { display: table-cell; text-align: center; width: 12%; vertical-align: middle; }

.footer-box .footer-box__info .logo .logo-11st { display: inline-block; background-image: url("/img/store/sprites/sp_footer_2x.png"); background-position: 0px 0px; width: 78px; height: 32px; background-size: 78px 32px; overflow: hidden; line-height: 200px; }

.footer-box .footer-box__info .info { display: table-cell; vertical-align: top; }

.footer-box .footer-box__info .info dt { color: #666; font-weight: bold; font-size: 12px; line-height: 19px; }

.footer-box .footer-box__info .info dd { color: #999; font-size: 12px; line-height: 19px; }

.footer-box .footer-box__info .info .tel { padding: 10px 0; font-size: 20px; color: #666; font-family: "Roboto", Tahoma, sans-serif; }

.footer-box .footer-box__info .info a { color: #999; }

.footer-box .footer-box__info .info .col { margin-left: 10px; font-size: 12px; color: #999; line-height: 19px; letter-spacing: -0.9px; vertical-align: middle; }

.footer-box .footer-box__info .info:nth-child(2) { width: 35%; }

.footer-box .footer-box__info .info:nth-child(3) { width: 27%; }

.footer-box .footer-box__info .info:nth-child(4) { width: 26%; }

.footer-box .footer-box_service { padding-top: 21px; font-size: 0; border-top: 1px solid #ddd; }

.footer-box .footer-box_service .service_in { width: 1200px; margin: 0 auto; }

.footer-box .footer-box_service .service_link { display: inline-block; min-width: 140px; height: 32px; font-size: 12px; line-height: 30px; border: 1px solid #ddd; background-color: #fff; text-align: center; color: #666; margin-left: 6px; }

.footer-box .footer-box_service .service_link:first-child { margin-left: 0; }

.footer-box .footer-box_copy { width: 1200px; margin: 0 auto; padding-top: 21px; }

.footer-box .footer-box_copy p { font-size: 11px; line-height: 22px; }

.footer-box .footer-box_copy .txt { font-weight: bold; }

.c-input { display: inline-block; vertical-align: middle; line-height: 1.3; }

.c-input .lab { line-height: 40px; font-size: 14px; margin-right: 6px; color: #111; vertical-align: top; }

.c-input .title { display: inline-block; padding-right: 10px; font-size: 14px; color: #111; line-height: 40px; vertical-align: middle; }

.c-input .input-group { display: inline-block; }

.c-input .input-text { display: inline-block; position: relative; z-index: 2; vertical-align: top; }

.c-input .input-text + button { margin-left: 7px; vertical-align: top; }

.c-input .input-text input:not(:only-child) { padding-right: 40px; }

.c-input .inp { width: 160px; height: 40px; padding: 0 12px; background: #fff; border: 1px solid #ddd; color: #111; letter-spacing: 0; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; /* firefox 19+ */ /* ie */ }

.c-input .inp::-webkit-input-placeholder { color: #999; font-weight: normal; }

.c-input .inp::-moz-placeholder { color: #999; font-weight: normal; }

.c-input .inp::-ms-input-placeholder { color: #999; font-weight: normal; }

.c-input .inp:focus { border-color: #bbb; }

.c-input .inp:required:valid { border-color: #ddd; color: #111; }

.c-input .inp:disabled, .c-input input[readonly] { background-color: #fafafa; border-color: rgba(204, 204, 204, 0.2); color: #ccc; /* firefox 19+ */ /* ie */ }

.c-input .inp:disabled::-webkit-input-placeholder, .c-input input[readonly]::-webkit-input-placeholder { color: #ccc; font-weight: normal; }

.c-input .inp:disabled::-moz-placeholder, .c-input input[readonly]::-moz-placeholder { color: #ccc; font-weight: normal; }

.c-input .inp:disabled::-ms-input-placeholder, .c-input input[readonly]::-ms-input-placeholder { color: #ccc; font-weight: normal; }

.c-input .inp:disabled + .input-etc, .c-input input[readonly] + .input-etc { background-color: #fafafa; }

.c-input .input-etc { overflow: hidden; position: absolute; top: 1px; right: 1px; height: 38px; text-align: center; }

.c-input .input-etc .btn-search { overflow: hidden; width: 44px; height: 38px; display: inline-block; line-height: 200px; }

.c-input .input-etc .btn-search:after { position: absolute; top: 0; right: 0; margin: 9px 12px; background-image: url("/img/store/sprites/sp_icon.png"); background-position: 0px 0px; width: 22px; height: 22px; content: ""; }

.c-input .input-etc .btn-clear { position: relative; display: inline-block; margin: 9px 12px; background-color: #bbb; border-radius: 9px; width: 18px; height: 18px; overflow: hidden; line-height: 200px; }

.c-input .input-etc .btn-clear:before, .c-input .input-etc .btn-clear:after { position: absolute; top: 50%; left: 50%; width: 10px; height: 1px; background-color: #fff; content: ''; }

.c-input .input-etc .btn-clear:before { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(45deg); transform: translateZ(0) translate(-50%, -50%) rotate(45deg); }

.c-input .input-etc .btn-clear:after { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(135deg); transform: translateZ(0) translate(-50%, -50%) rotate(135deg); }

.c-input .input-etc .btn-clear:not(:last-of-type) { margin-left: 0px; margin-right: 0px; }

.c-input .input-etc .btn-clear + .character-check { margin-left: 0px; }

.c-input .input-etc .btn-calender { display: inline-block; overflow: hidden; height: 38px; line-height: 200px; }

.c-input .input-etc .btn-calender:before { position: absolute; top: 0; right: 0; margin: 8px 9px; background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -165px -64px; width: 22px; height: 22px; background-size: 215px 165px; content: ""; }

.c-input .input-etc .btn-calender:after { position: absolute; top: 1px; right: 38px; display: block; width: 1px; height: 40px; background-color: #f4f4f4; content: ""; }

.c-input .input-etc .character-check { display: inline-block; height: 38px; margin: 0 9px; font-size: 13px; color: #999; line-height: 38px; text-align: right; vertical-align: middle; font-family: "Roboto", Tahoma, sans-serif; }

.c-input .input-etc .character-check em { color: #111; }

.c-input.w-350 .inp { width: 348px; }

.c-input.w-400 .inp { width: 412px; }

.c-input.size__2 .inp[type="number"] { width: 54px; height: 32px; text-align: center; font-family: "Roboto", Tahoma, sans-serif; }

.c-input.size__2 .inp[type="number"]::-webkit-inner-spin-button, .c-input.size__2 .inp[type="number"]::-webkit-outer-spin-button { -webkit-appearance: none; margin: 0; }

.c-input .unit { padding-left: 3px; line-height: 40px; color: #111; font-size: 14px; vertical-align: middle; }

.c-input .input-message { display: none; }

.c-input.waring { vertical-align: top; }

.c-input.waring .inp { border-color: #f43142; }

.c-input.waring .input-message { position: absolute; z-index: 1; left: 7px; bottom: 0; display: block; color: #f43142; width: 100%; height: 15px; font-size: 12px; line-height: 15px; white-space: nowrap; }

.c-input.waring .input-text { padding-bottom: 18px; }

.c-input.wide { display: table; width: 100%; }

.c-input.wide .lab { display: table-cell; padding-right: 10px; }

.c-input.wide .input-text { display: table-cell; width: 100%; }

.c-input.wide .input-text .inp { width: 100%; }

.c-input.wide button:first-child { margin-right: 10px; }

.c-input.wide .input-file { display: table-cell; min-width: 120px; }

.c-input.wide .unit { display: table-cell; }

.c-input.vertical { margin-top: 0 !important; }

.c-input.vertical .lab { margin-right: 5px; vertical-align: middle; }

.c-input.vertical .input-text, .c-input.vertical .unit { display: table-cell; }

.c-input.check .input-text .inp { padding-right: 80px; }

.c-input.address_add { display: block; margin: 10px 0 0 130px; }

.c-input.address_add .input-text, .c-input.address_add .inp { width: 100%; }

.c-input .input_in { display: table; width: 100%; background-color: #fff; border: 1px solid #ddd; }

.c-input .input_in .lab { display: table-cell; width: 1px; margin-right: 0; padding: 0 0 0 12px; color: #111; line-height: 38px; white-space: nowrap; }

.c-input .input_in .lab:after { display: inline-block; width: 1px; height: 12px; margin-left: 10px; background-color: #d8d8d8; vertical-align: middle; content: ""; }

.c-input .input_in .inp { display: table-cell; width: 100%; height: 38px; padding: 0 12px 0 9px !important; border: none; }

.c-date__calender { font-size: 0; display: inline-block; vertical-align: middle; }

[class^="radio-style"] + .c-date__calender, [class^="check-style"] + .c-date__calender { margin-left: -10px; }

.section-bar { display: inline-block; padding: 0 8px; line-height: 40px; text-align: center; vertical-align: middle; color: #111; font-size: 14px; }

.input-file { display: inline-block; position: relative; z-index: 1; width: 160px; height: 160px; vertical-align: middle; font-size: 0; }

.input-file:before { position: absolute; top: 0; right: 0; bottom: 0; left: 0; content: ""; }

.input-file .btn-img__file { position: relative; width: 100%; height: 100%; padding: 0; border: 1px dashed #ddd; cursor: pointer; }

.input-file .btn-img__file:before { position: absolute; top: 50%; left: 50%; width: 32px; height: 2px; margin: -1px 0 0 -16px; background-color: #999; content: ""; }

.input-file .btn-img__file:after { position: absolute; top: 50%; left: 50%; width: 2px; height: 32px; margin: -16px 0 0 -1px; background-color: #999; content: ""; }

.input-file input[type="file"] { position: absolute; z-index: -1; top: 0; left: 0; width: 100%; height: 100%; font-size: 0; opacity: 0; cursor: pointer; }

.input-file input[type="file"].focus-visible, .input-file input[type="file"].focus-visible + [class^="btn"], .input-file input[type="file"].focus-visible + .img, .input-file input[type="file"].focus-visible + .img [class^="btn"], .input-file input[type="file"].focus-visible + .img + [class^="btn"] { box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5); }

.input-file .text { position: absolute; right: 5px; bottom: 10px; left: 5px; text-align: center; color: #999; font-size: 13px; letter-spacing: 0; cursor: pointer; }

.input-file .img { position: relative; display: block; width: 100%; height: 100%; border: solid 1px rgba(0, 0, 0, 0.1); cursor: pointer; }

.input-file .img img { position: absolute; top: 0; right: 0; bottom: 0; left: 0; max-width: 100%; max-height: 100%; margin: auto; }

.input-file .img .btn { position: absolute; top: 0; right: 0; padding: 3px 5px; background-color: rgba(0, 0, 0, 0.6); border: solid 1px rgba(17, 17, 17, 0.3); color: #fff; font-size: 11px; cursor: pointer; }

.input-file .img .input-file__btn { position: absolute; bottom: 0; left: 0; right: 0; zoom: 1; }

.input-file .img .input-file__btn:after { display: block; content: ""; clear: both; }

.input-file .img .input-file__btn [class^="input-file__btn"] { position: relative; float: left; display: block; width: 50%; height: 32px; padding: 6px 5px 8px; background-color: rgba(0, 0, 0, 0.6); color: #fff; font-size: 12px; text-align: center; cursor: pointer; }

.input-file .img .input-file__btn [class^="input-file__btn"]:before { display: inline-block; content: ""; margin-right: 4px; vertical-align: bottom; }

.input-file .img .input-file__btn .input-file__btn--edit:before { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -193px -146px; width: 16px; height: 16px; background-size: 215px 165px; }

.input-file .img .input-file__btn .input-file__btn--edit:after { position: absolute; top: 10px; right: 0; width: 1px; height: 12px; border-right: 1px solid rgba(255, 255, 255, 0.6); content: ""; }

.input-file .img .input-file__btn .input-file__btn--del:before { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -193px -125px; width: 16px; height: 16px; background-size: 215px 165px; }

.input-file [class^="btn-style"], .input-file [class^="btn-style"]:before { cursor: pointer; }

.input-file.size__2 { height: 107px; }

.input-file.size__4 { width: 300px; height: 165px; }

.input-file.size__3 { width: 80px; height: auto; padding-top: 91px; /* MPQC-17908 배포 후 삭제 */ /* // MPQC-17908 배포 후 삭제 */ }

.input-file.size__3:before { position: absolute; top: 50%; left: 50%; z-index: 1; width: 28px; height: 2px; margin: -20px 0 0 -14px; background-color: #999; content: ""; }

.input-file.size__3:after { position: absolute; top: 50%; left: 50%; z-index: 1; width: 2px; height: 28px; margin: -33px 0 0 -1px; background-color: #999; content: ""; }

.input-file.size__3 input[type="file"] + [class^="btn-style"]:before { position: absolute; top: -89px; left: 0; width: 100%; height: 80px; background-color: #fff; border: 1px dashed #ddd; border-radius: 50%; content: ""; }

.input-file.size__3 .img { position: absolute; top: 0; z-index: 2; height: 80px; border: none; }

.input-file.size__3 .img:before { position: absolute; top: -3px; right: -3px; bottom: -3px; left: -3px; border: 1px solid #f43142; border-radius: 50%; content: ""; }

.input-file.size__3 .img:after { position: absolute; right: 0; bottom: 0; width: 26px; height: 26px; background-color: #f43142; border: none; border-radius: 50%; content: ""; }

.input-file.size__3 .img img { border: 1px solid rgba(0, 0, 0, 0.07); border-radius: 50%; }

.input-file.size__3 .img + [class^="btn-style"]:before { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -193px -77px; width: 20px; height: 20px; background-size: 215px 165px; position: absolute; top: -35px; right: 2px; z-index: 2; content: ""; }

.input-file.size__3 .img_wrap { position: absolute; top: 0; z-index: 2; width: 100%; height: 80px; border: 1px solid rgba(0, 0, 0, 0.07); border-radius: 50%; }

.input-file.size__3 .img_wrap:before { position: absolute; top: -3px; right: -3px; bottom: -3px; left: -3px; border: 1px solid #f43142; border-radius: 50%; content: ""; }

.input-file.size__3 .img_wrap:after { position: absolute; right: 0; bottom: 0; width: 26px; height: 26px; background-color: #f43142; border: none; border-radius: 50%; content: ""; }

.input-file.size__3 .img_wrap .img_in { overflow: hidden; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-position: center center; background-repeat: no-repeat; background-size: cover; border-radius: 50%; color: transparent; font-size: 0; line-height: 200px; }

.input-file.size__3 .img_wrap + [class^="btn-style"]:before { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -193px -77px; width: 20px; height: 20px; background-size: 215px 165px; position: absolute; top: -35px; right: 2px; z-index: 2; content: ""; }

.c-textarea .textarea { height: 78px; padding: 13px; border: 1px solid #ddd; font-size: 14px; color: #111; resize: none; /* firefox 19+ */ /* ie */ }

.c-textarea .textarea::-webkit-input-placeholder { color: #999; font-weight: normal; }

.c-textarea .textarea::-moz-placeholder { color: #999; font-weight: normal; }

.c-textarea .textarea::-ms-input-placeholder { color: #999; font-weight: normal; }

.c-textarea.wide .textarea { width: 100%; }

.c-textarea.check { position: relative; display: inline-block; }

.c-textarea.check .textarea { padding-right: 100px; }

.c-textarea.check .character-check { position: absolute; z-index: 1; right: 37px; bottom: 0; display: inline-block; font-size: 13px; color: #999; line-height: 38px; text-align: right; vertical-align: middle; letter-spacing: 0; }

.c-textarea.check .character-check em { color: #111; }

.radio-style__1, .radio-style__2 { display: inline-block; position: relative; z-index: 1; vertical-align: middle; line-height: 0; white-space: nowrap; }

.radio-style__1 .inp, .radio-style__2 .inp { width: 22px; height: 22px; opacity: 0; }

.radio-style__1 .lab, .radio-style__2 .lab { padding-left: 6px; vertical-align: -1px; color: #333; font-size: 14px; }

.radio-style__1 .lab:before, .radio-style__2 .lab:before { overflow: hidden; position: absolute; top: 0; left: 0; width: 22px; height: 22px; background-color: #fff; border: 1px solid #ccc; border-radius: 50%; content: ""; }

.radio-style__1 .lab:after, .radio-style__2 .lab:after { position: absolute; top: 6px; left: 6px; width: 10px; height: 10px; background-color: #ccc; border-radius: 50%; content: ""; }

.radio-style__1 .inp:checked + .lab:before, .radio-style__2 .inp:checked + .lab:before { border-color: #f43142; background-color: #f43142; }

.radio-style__1 .inp:checked + .lab:after, .radio-style__2 .inp:checked + .lab:after { background-color: #fff; }

.radio-style__1 .inp:disabled + .lab:before, .radio-style__2 .inp:disabled + .lab:before, .radio-style__1 .c-input input[readonly] + .lab:before, .c-input .radio-style__1 input[readonly] + .lab:before, .radio-style__2 .c-input input[readonly] + .lab:before, .c-input .radio-style__2 input[readonly] + .lab:before { border-color: #eee; }

.radio-style__1 .inp:disabled + .lab:after, .radio-style__2 .inp:disabled + .lab:after, .radio-style__1 .c-input input[readonly] + .lab:after, .c-input .radio-style__1 input[readonly] + .lab:after, .radio-style__2 .c-input input[readonly] + .lab:after, .c-input .radio-style__2 input[readonly] + .lab:after { background-color: #eee; }

.radio-style__1 .inp.focus-visible + .lab:before, .radio-style__2 .inp.focus-visible + .lab:before { box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5); }

.label-hidden .inp { display: block; }

.label-hidden .lab { position: absolute; top: 0; left: 0; width: 0; height: 0; padding-left: 0 !important; color: transparent !important; font-size: 0 !important; }

.radio-style__2 .lab { vertical-align: -2px; font-weight: bold; font-size: 16px; }

.radio-style__11 { position: relative; display: inline-block; margin-right: 0 !important; background-color: #fefefe; vertical-align: middle; line-height: 0; }

.radio-style__11 + .radio-style__11 { margin-left: 16px; }

.radio-style__11 .inp { position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; }

.radio-style__11 .lab { overflow: hidden; display: block; width: 30px; height: 30px; margin: 7px; line-height: 200px; }

.radio-style__11 .lab:before { position: absolute; top: 0; left: 0; width: 100%; height: 100%; content: ""; }

.radio-style__11 .inp:checked + .lab:before { border: solid 2px #f43142; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

.radio-style__11 .inp.focus-visible + .lab:before { box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5); }

.check-style__1 { display: inline-block; position: relative; vertical-align: middle; line-height: 0; white-space: nowrap; z-index: 1; }

.check-style__1 .inp { width: 22px; height: 22px; opacity: 0; }

.check-style__1 .lab { padding-left: 6px; font-size: 14px; color: #333; vertical-align: middle; }

.check-style__1 .lab:before { overflow: hidden; position: absolute; left: 0; top: 0; width: 22px; height: 22px; background-color: #fff; border: 1px solid #ccc; content: ""; }

.check-style__1 .inp:checked + .lab:before { border-color: #f43142; background-color: #f43142; }

.check-style__1 .inp:checked + .lab:after { position: absolute; top: 2px; left: 7px; width: 8px; height: 13px; border: 1px solid #fff; border-width: 0 1px 1px 0; transform: rotate(45deg); content: ""; }

.check-style__1 .inp:disabled + .lab:before, .check-style__1 .c-input input[readonly] + .lab:before, .c-input .check-style__1 input[readonly] + .lab:before { border-color: #eee; }

.check-style__1 .inp.focus-visible + .lab:before { box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5); }

.check-style__2 { display: inline-block; vertical-align: middle; line-height: 0; }

.check-style__2 .inp { position: absolute; width: 0; height: 0; opacity: 0; }

.check-style__2 .lab { overflow: hidden; position: relative; display: inline-block; width: 60px; height: 40px; line-height: 200px; }

.check-style__2 .lab:before { position: absolute; top: 13px; right: 13px; left: 13px; height: 14px; background-color: #ccc; border-radius: 7px; opacity: 0.5; transition: 0.4s; content: ""; }

.check-style__2 .lab:after { position: absolute; height: 20px; width: 20px; top: 10px; left: 10px; background-color: #fff; border-radius: 50%; box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0.5px rgba(0, 0, 0, 0.12); -webkit-transition: 0.4s; transition: 0.4s; content: ""; }

.check-style__2 .inp:checked + .lab:before { background-color: #f43142; }

.check-style__2 .inp:checked + .lab:after { background-color: #f43142; transform: translateX(19px); }

.check-style__2 .inp:disabled + .lab:before, .check-style__2 .c-input input[readonly] + .lab:before, .c-input .check-style__2 input[readonly] + .lab:before { background-color: #eee; }

.check-style__2 .inp:disabled + .lab:after, .check-style__2 .c-input input[readonly] + .lab:after, .c-input .check-style__2 input[readonly] + .lab:after { background-color: #eee; }

.check-style__2 .inp.focus-visible + .lab:before { box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5); }

.check-style__31 { display: inline-block; margin: 0 4px 8px 0 !important; vertical-align: middle; }

.check-style__31:last-of-type, .check-style__31:only-of-type { margin-right: 0 !important; }

.check-style__31 .inp { position: absolute; width: 0; height: 0; opacity: 0; }

.check-style__31 .lab { display: inline-block; padding: 4px 12px 5px; background-color: #fff; border: 1px solid #ddd; border-radius: 16px; color: #333; }

.check-style__31 .inp:checked + .lab { background-color: #fffbfc; border: solid 1px rgba(244, 49, 66, 0.2); color: #f43142; font-weight: bold; }

.check-style__31 .inp:checked + .lab:before { display: inline-block; width: 7px; height: 12px; margin: 0 8px -1px 2px; vertical-align: 1px; border: solid 1.5px #f43142; border-width: 0 1.5px 1.5px 0; transform: rotate(45deg); content: ""; }

.check-style__31 .inp.focus-visible + .lab { box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5); }

.c-check__local, .c-check__keyword { margin: 0 -4px -8px 0; }

.c-input + .c-check__keyword { margin-top: 20px; }

.c-dropdown { display: inline-block; position: relative; z-index: 1; height: 40px; vertical-align: middle; }

.c-dropdown .lab { color: #000; line-height: 26px; margin-right: 10px; }

.c-dropdown .title { display: inline-block; padding-right: 10px; font-size: 14px; color: #111; line-height: 40px; vertical-align: middle; white-space: nowrap; }

.c-dropdown .c-text { margin-right: 10px; }

.c-dropdown .dropdown { display: inline-block; position: relative; z-index: 1; }

.c-dropdown .dropdown + .unit { display: inline-block; margin-left: 10px; color: #111; line-height: 40px; font-size: 14px; vertical-align: middle; white-space: nowrap; }

.c-dropdown .btn { position: relative; z-index: 2; top: 0; left: 0; padding: 0 45px 0 12px; width: 160px; height: 40px; border: 1px solid #ddd; text-align: left; color: #999; font-size: 14px; background-color: #fff; }

.c-dropdown .btn span.text { display: block; width: 100%; height: 38px; line-height: 38px; overflow: hidden; text-overflow: clip; }

.c-dropdown .btn span.text .text_style1 .emblem { margin-right: 5px; color: #f43142; }

.c-dropdown .btn:before { position: absolute; top: 0; right: 40px; content: ""; height: 100%; width: 1px; border-left: 1px solid #f4f4f4; }

.c-dropdown .btn:after { position: absolute; top: 50%; right: 16px; margin-top: -7px; content: ""; display: inline-block; vertical-align: middle; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform: rotate(135deg); }

.c-dropdown .btn:disabled { border-color: #ddd; background-color: #fafafa; }

.c-dropdown .btn:disabled span.text { color: #ccc !important; }

.c-dropdown .btn:disabled:before { border-color: #f3f3f3; color: #ccc; }

.c-dropdown .btn:disabled:after { border-color: #999; }

.c-dropdown .list { display: none; position: absolute; z-index: 1; top: 39px; left: 0; width: 100%; padding: 10px 0; border: 1px solid #bbb; background: #fff; max-height: 200px; overflow-y: auto; }

.c-dropdown .list [role="option"] { display: block; width: 100%; padding: 8px 12px; color: #666; cursor: pointer; text-align: left; font-size: 14px; }

.c-dropdown .list [role="option"][aria-selected="true"] { color: #f43142; }

.c-dropdown .list [role="option"][aria-selected="true"] .text_style1 { color: #111; }

.c-dropdown .list [role="option"][aria-selected="true"] .text_style1 .emblem { margin-right: 5px; color: #f43142; }

.c-dropdown .list [role="option"]:hover { color: #111; background: #fafafa; text-decoration: none; }

.c-dropdown.list-up .list { top: auto; bottom: 39px; }

.c-dropdown.open { z-index: 10; }

.c-dropdown.open .c-input .input-text .inp { border-color: #bbb; }

.c-dropdown.open .btn { border-color: #bbb; color: #111; }

.c-dropdown.open .btn:after { top: 65%; transform: rotate(-45deg); }

.c-dropdown.filled .btn span.text { color: #111; }

.c-dropdown.auto .btn { color: #111; background-color: #f4f4f4; border-color: #ddd; }

.c-dropdown.auto .btn:before { border-color: #ddd; color: #ccc; }

.c-dropdown.auto .btn:after { border-color: #999; }

.c-dropdown.auto .btn .unit { background-color: #f4f4f4; color: #eee; border-color: #ddd; }

.c-dropdown.auto .c-input .input-text .inp { background-color: #f4f4f4; color: #111; /* firefox 19+ */ /* ie */ }

.c-dropdown.auto .c-input .input-text .inp::-webkit-input-placeholder { color: #111; font-weight: normal; }

.c-dropdown.auto .c-input .input-text .inp::-moz-placeholder { color: #111; font-weight: normal; }

.c-dropdown.auto .c-input .input-text .inp::-ms-input-placeholder { color: #111; font-weight: normal; }

.c-dropdown.auto .c-input .input-text .input-etc { background-color: #f4f4f4; }

.c-dropdown.w-100 .btn { width: 100px; }

.c-dropdown.w-200 .btn { width: 200px; }

.c-dropdown.size__2 { height: 32px; }

.c-dropdown.size__2 .btn { width: 120px; height: 32px; padding-right: 22px; }

.c-dropdown.size__2 .btn:before { display: none; }

.c-dropdown.size__2 .btn:after { right: 11px; }

.c-dropdown.size__2 .btn .text { height: 30px; line-height: 30px; }

.c-dropdown.size__2 .c-input .input-text .inp { height: 32px; }

.c-dropdown.size__2 .c-input .input-text .input-etc { height: 30px; }

.c-dropdown.size__2 .c-input .input-text .input-etc .btn-search { margin: 5px 12px; }

.c-dropdown.wide { display: table; width: 100%; }

.c-dropdown.wide .dropdown { display: table-cell; width: 100%; }

.c-dropdown.wide [class^="btn-style"] { margin: 0 0 0 10px; min-width: 160px; }

.c-dropdown.wide .c-input, .c-dropdown.wide .input-text, .c-dropdown.wide .inp { width: 100%; }

.c-dropdown.wide .btn { width: 100%; }

.c-dropdown.wide .list { width: 100%; }

.c-dropdown.wide .input-group { display: table-cell; width: 100%; }

.c-dropdown.wide .input-group.non-unit { display: table; }

.c-dropdown.wide .input-group.non-unit .input-text { display: table; }

.c-dropdown__list { display: none; position: absolute; z-index: 1000; width: 100%; padding: 10px 0; border: 1px solid #bbb; background: #fff; max-height: 200px; overflow-y: auto; }

.c-dropdown__list [role="option"] { display: block; width: 100%; padding: 8px 12px; color: #666; cursor: pointer; text-align: left; font-size: 14px; }

.c-dropdown__list [role="option"][aria-selected="true"] { color: #f43142; }

.c-dropdown__list [role="option"]:hover { color: #111; background: #fafafa; text-decoration: none; }

.c-dropdown__list.open { display: block; }

.c-select { position: relative; z-index: 1; }

.c-select .select-box { position: relative; z-index: 1; min-width: 92px; display: inline-block; }

.c-select .select-box:after { position: absolute; top: 50%; right: 17px; width: 8px; height: 8px; margin-top: -6px; border: 1px solid #999; border-width: 0 1px 1px 0; transform: rotate(45deg); content: ""; }

.c-select .select-box + .select-box { margin-left: 9px; }

.c-select .select { width: 100%; height: 40px; padding: 0 48px 0 11px; background: none; border: 1px solid #ddd; color: #111; font-weight: bold; font-size: 15px; appearance: none; /* IE 10, 11의 네이티브 화살표 숨기기 */ }

.c-select .select::-ms-expand { display: none; }

.input-text + .input-text, .c-dropdown + .input-text, [class^="btn-style"] + .input-text, .input-text + .c-dropdown, .c-dropdown + .c-dropdown, [class^="btn-style"] + .c-dropdown, .input-text + [class^="btn-style"], .c-dropdown + [class^="btn-style"], [class^="btn-style"] + [class^="btn-style"] { margin-left: 8px; }

[class^="radio-style"], [class^="check-style"] { margin-right: 36px; }

[class^="radio-style"]:last-of-type, [class^="radio-style"]:only-of-type, [class^="check-style"]:last-of-type, [class^="check-style"]:only-of-type { margin-right: 0; }

[class^="radio-style"] .lab.color__em, [class^="check-style"] .lab.color__em { color: #000; }

.c-dropdown + [class^="radio-style"], .c-dropdown + [class^="check-style"] { margin-left: 16px; }

.c-input { width: auto; padding: 0; background-color: transparent; border: none; font-size: inherit; }

.c-dropdown { width: auto; padding: 0; background: none; border: none; }

.btn-style__1, .btn-style__2, .btn-style__3, .btn-style__4, .btn-style__11, .btn-style__img { position: relative; display: inline-block; padding: 0 18px; text-align: center; vertical-align: middle; }

.btn-style__1:disabled, .btn-style__2:disabled, .btn-style__3:disabled, .btn-style__4:disabled, .btn-style__11:disabled, .btn-style__img:disabled { border-color: rgba(204, 204, 204, 0.2) !important; color: #ccc !important; background-color: #fafafa !important; }

.btn-style__1:hover, .btn-style__2:hover, .btn-style__3:hover, .btn-style__4:hover, .btn-style__11:hover, .btn-style__img:hover { text-decoration: none; }

.btn-style__1 + .btn-style__1, .btn-style__2 + .btn-style__1, .btn-style__3 + .btn-style__1, .btn-style__4 + .btn-style__1, .btn-style__11 + .btn-style__1, .btn-style__img + .btn-style__1, .btn-style__1 + .btn-style__2, .btn-style__2 + .btn-style__2, .btn-style__3 + .btn-style__2, .btn-style__4 + .btn-style__2, .btn-style__11 + .btn-style__2, .btn-style__img + .btn-style__2, .btn-style__1 + .btn-style__3, .btn-style__2 + .btn-style__3, .btn-style__3 + .btn-style__3, .btn-style__4 + .btn-style__3, .btn-style__11 + .btn-style__3, .btn-style__img + .btn-style__3, .btn-style__1 + .btn-style__4, .btn-style__2 + .btn-style__4, .btn-style__3 + .btn-style__4, .btn-style__4 + .btn-style__4, .btn-style__11 + .btn-style__4, .btn-style__img + .btn-style__4, .btn-style__1 + .btn-style__11, .btn-style__2 + .btn-style__11, .btn-style__3 + .btn-style__11, .btn-style__4 + .btn-style__11, .btn-style__11 + .btn-style__11, .btn-style__img + .btn-style__11, .btn-style__1 + .btn-style__img, .btn-style__2 + .btn-style__img, .btn-style__3 + .btn-style__img, .btn-style__4 + .btn-style__img, .btn-style__11 + .btn-style__img, .btn-style__img + .btn-style__img { margin-left: 6px; }

.btn-style__1 { background-color: #fff; border: 1px solid #ccc; color: #111; }

.btn-style__1:hover { background-color: #fafafa; color: #111; }

.btn-style__2 { background-color: #fff; border: 1px solid #999; color: #111; }

.btn-style__2:hover { background-color: #fafafa; color: #111; }

.btn-style__2:disabled { border-color: rgba(221, 221, 221, 0.2) !important; }

.btn-style__3 { background-color: #f43142; border: 1px solid #f43142; color: #fff; }

.btn-style__3:hover { background-color: #ea2f3f; color: #fff; }

.btn-style__4 { background-color: #fff; border: 1px solid #f43142; color: #f43142; }

.btn-style__4:hover { border-color: #ea2f3f; color: #ea2f3f; }

.btn-style__11 { background-color: #fff; border: 1px solid #e4e4e4; color: #111; }

.btn-style__11 .icon { margin-left: -9px; }

.btn-style__img { overflow: hidden; position: relative; width: 36px; height: 36px; padding: 0; background-color: #fff; border: 1px solid #ddd; line-height: 2000px; }

.btn-style__img:hover { background-color: #fafafa; }

.btn-style__img.btn-img__delete:before, .btn-style__img.btn-img__delete:after { position: absolute; top: 50%; left: 50%; width: 14px; height: 1px; background-color: #999; transform: translateZ(0) translate(-50%, -50%) rotate(45deg); content: ''; }

.btn-style__img.btn-img__delete:after { transform: translateZ(0) translate(-50%, -50%) rotate(135deg); }

.btn-style__img.btn-img__prev, .btn-style__img.btn-img__next, .btn-style__img.btn-img__first, .btn-style__img.btn-img__last { width: 26px; height: 26px; border-color: #eee; }

.btn-style__img.btn-img__prev:before, .btn-style__img.btn-img__next:before, .btn-style__img.btn-img__first:before, .btn-style__img.btn-img__last:before { position: absolute; top: 50%; left: 50%; width: 7.8px; height: 7.8px; margin: -2px 0 0 -4px; border: 1px solid #999; border-width: 1px 0 0 1px; vertical-align: middle; transform: rotate(45deg); content: ''; }

.btn-style__img.btn-img__prev:disabled:before, .btn-style__img.btn-img__next:disabled:before, .btn-style__img.btn-img__first:disabled:before, .btn-style__img.btn-img__last:disabled:before { border-color: #eee; }

.btn-style__img.btn-img__prev:disabled:after, .btn-style__img.btn-img__next:disabled:after, .btn-style__img.btn-img__first:disabled:after, .btn-style__img.btn-img__last:disabled:after { background-color: #eee; }

.btn-style__img.btn-img__next:before { margin-top: -7px; border-width: 0 1px 1px 0; }

.btn-style__img.btn-img__first, .btn-style__img.btn-img__last { margin: 0 -1px 0 2px; }

.btn-style__img.btn-img__first:before, .btn-style__img.btn-img__last:before { top: 14px; margin: -1px 0 0 -4px; }

.btn-style__img.btn-img__first:after, .btn-style__img.btn-img__last:after { position: absolute; top: 7px; left: 50%; width: 12px; height: 1px; margin-left: -6px; background-color: #999; content: ''; }

.btn-style__img.btn-img__last:before { top: 4px; margin-left: -4px; border-width: 0 1px 1px 0; }

.btn-style__img.btn-img__last:after { top: 16px; }

.btn-style__icon { overflow: hidden; position: relative; width: 36px; height: 36px; line-height: 2000px; }

.btn-style__icon.btn-icon__delete:before, .btn-style__icon.btn-icon__delete2:before, .btn-style__icon.btn-icon__delete3:before, .btn-style__icon.btn-icon__close:before, .btn-style__icon.btn-icon__delete:after, .btn-style__icon.btn-icon__delete2:after, .btn-style__icon.btn-icon__delete3:after, .btn-style__icon.btn-icon__close:after { position: absolute; top: 50%; left: 50%; width: 19px; height: 1px; background-color: #111; transform: translateZ(0) translate(-50%, -50%) rotate(45deg); content: ''; }

.btn-style__icon.btn-icon__delete:after, .btn-style__icon.btn-icon__delete2:after, .btn-style__icon.btn-icon__delete3:after, .btn-style__icon.btn-icon__close:after { transform: translateZ(0) translate(-50%, -50%) rotate(135deg); }

.btn-style__icon.btn-icon__delete2:before, .btn-style__icon.btn-icon__delete2:after { width: 16px; background-color: #999; }

.btn-style__icon.btn-icon__delete3 { width: 18px; height: 18px; background-color: #bbb; vertical-align: middle; border-radius: 50%; }

.btn-style__icon.btn-icon__delete3:before, .btn-style__icon.btn-icon__delete3:after { width: 11px; background-color: #fff; }

.btn-style__icon.btn-icon__close { width: 18px; height: 18px; margin: -3px -4px 0 -3px; vertical-align: middle; }

.btn-style__icon.btn-icon__close:before, .btn-style__icon.btn-icon__close:after { width: 13px; background-color: #fff; }

.btn-style__icon.btn-icon__prev, .btn-style__icon.btn-icon__next { width: 24px; height: 24px; }

.btn-style__icon.btn-icon__prev:before, .btn-style__icon.btn-icon__next:before { position: absolute; top: 50%; left: 50%; width: 8px; height: 8px; margin: -2px 0 0 -5px; border: 1px solid #999; border-width: 0 0 1px 1px; vertical-align: middle; transform: rotate(45deg) translateY(-50%); content: ''; }

.btn-style__icon.btn-icon__next:before { margin: -2px 0 0 -9px; border-width: 1px 1px 0 0; }

.btn-style__icon.btn-icon__prev2, .btn-style__icon.btn-icon__next2 { width: 40px; height: 40px; }

.btn-style__icon.btn-icon__prev2:before, .btn-style__icon.btn-icon__next2:before { position: absolute; top: 50%; left: 50%; width: 10px; height: 10px; margin: -5px 0 0 -2px; border: 1px solid #999; border-width: 0 0 1px 1px; transform: rotate(45deg); content: ''; }

.btn-style__icon.btn-icon__next2:before { margin-left: -8px; border-width: 1px 1px 0 0; }

.btn-size__1, .btn-size__0, .btn-size__10 { min-width: 140px; height: 48px; padding: 0 10px; line-height: 46px; font-weight: bold; font-size: 16px; }

.btn-size__0 { min-width: 200px; }

.btn-size__2 { min-width: 120px; height: 47px; line-height: 46px; font-weight: bold; font-size: 16px; }

.btn-size__3, .btn-size__4, .btn-size__8 { min-width: 140px; height: 40px; padding: 0 10px; line-height: 36px; font-size: 14px; }

.btn-size__4 { min-width: 120px; }

.btn-size__5, .btn-size__6, .btn-size__7, .btn-size__9 { height: 32px; padding: 0 24px; line-height: 30px; font-size: 13px; }

.btn-size__6 { min-width: 70px; padding: 0 10px; }

.btn-size__7 { min-width: 60px; padding: 0 5px; }

.btn-size__8 { min-width: 60px; }

.btn-size__9 { min-width: 50px; padding: 0 5px; }

.btn-size__10 { min-width: 280px; }

.btn-size__10:disabled { background-color: #ccc !important; border-color: #ccc !important; color: #fff !important; }

.btn-size__11 { height: 32px; padding: 0 15px; font-weight: bold; font-size: 11px; line-height: 28px; }

.btn-size__12 { min-width: 44px; height: 28px; padding: 0 5px; line-height: 26px; font-size: 13px; }

.wide { min-width: 100%; }

.table { width: 100%; border-collapse: collapse; table-layout: fixed; }

.table th { height: 40px; padding: 4px 7px; border: 1px solid #ddd; background-color: #f3f3f3; color: #111; text-align: center; font-weight: normal; font-size: 13px; line-height: 1.5; }

.table td { height: 40px; padding: 4px 7px; line-height: 1.5; color: #111; border: 1px solid #ddd; text-align: center; font-size: 13px; }

.table td.non-data { height: 270px !important; color: #333; font-size: 15px; }

.table td.non-data:before { display: block; margin: 0 auto 18px; content: ''; background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: 0px -63px; width: 48px; height: 48px; background-size: 215px 165px; }

.table td.non-data__2 { height: 300px !important; }

.table td.non-data__2 p { color: #111; font-size: 13px; }

.table td.non-data__2 p:not(:only-child) { margin-bottom: 20px; }

.table thead th, .table thead td { height: 48px; border: 1px solid #ddd; border-bottom: none; text-align: center; color: #111; }

.table thead:last-child { border-bottom: 1px solid #ddd; }

.table tbody { background-color: #fff; }

.table .check-style__2 { margin: -6px 0; }

.table .text-brand { color: #f43142; }

.table .td-side { text-align: left; padding-left: 12px; padding-right: 10px; }

.table .td-side2 { text-align: right; padding-left: 10px; padding-right: 12px; }

.table .text-num, .table .text-date { letter-spacing: 0; }

.table .text-title { display: block; width: 100%; padding: 0 5px; text-align: left; font-size: 13px; line-height: 1.8; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.table button.text-title { color: #0b83e6; text-decoration: underline; }

.table .non-date { line-height: 90px; font-size: 14px; color: #111; text-align: center; }

.table tr.tr_data td, .table tr.tr_data th { height: 60px; }

.table tr.active th, .table tr.active td { background-color: #eff1f7; }

.table.table-type__2 th, .table.table-type__2 td { height: 49px; line-height: 1.5; }

.table.table-type__3 th, .table.table-type__3 td { font-size: 14px; letter-spacing: 0; }

.table.table-type__3 .td-side { padding: 10px 18px 10px 20px; vertical-align: top; }

.table.table-type__4 { width: calc(100% - 1px); }

.table.table-type__4 th, .table.table-type__4 td { color: #666; font-size: 14px; letter-spacing: 0; }

.table.table-type__4 .td-side { padding-left: 20px; padding-right: 18px; }

.table-wrap { overflow-x: auto; /* MPQC-18126 삭제 */ /* // MPQC-18126 삭제 */ }

.table-wrap.table-style__2 { min-height: 320px; }

html.dialog-open { overflow: hidden; }

html.dialog-open body { overflow: hidden; }

html.dialog-open .dialog-bg { display: block; overflow: hidden; overflow-x: auto; }

html.alert-open { overflow: hidden; }

html.alert-open .alert-bg { display: block; }

.dialog-body.scroll { max-height: calc(100vh - 150px); overflow: hidden; overflow-y: auto; }

.dialog-bg, .alert-bg, .renewal-pop__bg, .mov-pop__bg, .cloud-pop__bg { position: fixed; left: 0; top: 0; right: 0; height: 100%; z-index: 1000; background-color: rgba(0, 0, 0, 0.6); overflow: hidden; overflow: auto; }

.dialog-bg .dialog { position: absolute; top: 50%; left: 50%; width: 762px; height: auto; padding: 0; background-color: #fff; border: 1px solid #ddd; transform: translate(-50%, -50%); }

.dialog-bg .dialog .dialog-header { position: relative; padding: 0 49px 0 40px; border: 1px solid transparent; border-bottom: 1px solid #ddd; text-align: center; }

.dialog-bg .dialog .dialog-header .dialog-header__title { font-size: 18px; padding: 17px 0 16px; color: #333; font-weight: bold; }

.dialog-bg .dialog .dialog-body { overflow: hidden; overflow-y: auto; max-height: calc(100vh - 180px); padding: 30px 40px 0; }

.dialog-bg .dialog .dialog-body:after { display: block; height: 40px; content: ''; }

.dialog-bg .dialog .dialog-body.body-style__scroll { height: 750px; }

.dialog-bg .dialog .dialog-body .area-cont__btn { margin-top: 20px; text-align: center; }

.dialog-bg .dialog .dialog-bottom { position: static; height: auto; border-top: 1px solid #ddd; padding: 16px 40px 15px; text-align: center; zoom: 1; }

.dialog-bg .dialog .dialog-bottom button { margin: 0 5px; }

.dialog-bg .dialog .dialog-bottom .dialog-bottom__left { float: left; text-align: left; }

.dialog-bg .dialog .dialog-bottom .dialog-bottom__right { float: right; text-align: right; }

.dialog-bg .dialog .dialog-bottom:after { display: block; content: ""; clear: both; }

.dialog-bg .dialog .dialog-close { position: absolute; top: 10px; right: 10px; width: 43px; height: 43px; overflow: hidden; line-height: 200px; }

.dialog-bg .dialog .dialog-close:before, .dialog-bg .dialog .dialog-close:after { position: absolute; top: 50%; left: 50%; width: 34px; height: 1px; background-color: #999; content: ''; }

.dialog-bg .dialog .dialog-close:before { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(45deg); transform: translateZ(0) translate(-50%, -50%) rotate(45deg); }

.dialog-bg .dialog .dialog-close:after { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(135deg); transform: translateZ(0) translate(-50%, -50%) rotate(135deg); }

.dialog-body__title { position: relative; padding-right: 150px; margin-bottom: 20px; }

.dialog-body__title h4, .dialog-body__title .title { padding: 5px 0 6px; color: #333; font-weight: normal; letter-spacing: -1px; }

.dialog-body__title p { padding: 5px 0 6px; color: #333; }

.dialog-body__title .side { position: absolute; top: 50%; right: 0; margin-top: -16px; }

.dialog-body__title.title-type__em .title { padding: 0; color: #000; font-weight: bold; }

.dialog-body__title.title-type__em .title i { font-weight: normal; }

.dialog-body__title.title-type__em:not(:first-child) { margin-top: 40px; }

.dialog-body__title.title-type__guide { margin-bottom: 30px; padding-right: 0; text-align: center; }

.dialog-body__title.title-type__guide .title { color: #f43142; font-size: 20px; letter-spacing: 0; }

.dialog-body__title.title-type__info { margin: 25px 0 6px; }

.dialog-body__title.title-type__info:first-child { margin-top: 0; }

.dialog-body__title.title-type__info .title { color: #111; font-weight: normal; font-size: 14px; letter-spacing: 0; }

.dialog-body__title.title-type__info .title_sub { padding: 6px 0; font-weight: normal; font-size: 14px; letter-spacing: 0; }

.alert { position: absolute; top: 50%; left: 50%; width: 460px; background: #fff; border: 1px solid #eee; transform: translate(-50%, -50%); }

.alert .alert-header { height: 0; overflow: hidden; }

.alert .alert-body { padding: 40px 20px; font-size: 15px; line-height: 1.8; }

.alert .alert-bottom { border-top: 1px solid #eee; min-height: 56px; padding: 16px 40px 15px; text-align: center; }

.alert .alert-bottom .alert-bottom__left { float: left; text-align: left; }

.alert .alert-bottom .alert-bottom__left .check-style__2 { margin: 8px 0 0 8px; }

.alert .alert-bottom .alert-bottom__left .check-style__2 .lab { color: #666; }

.alert .alert-bottom .alert-bottom__right { float: right; text-align: right; }

.alert .alert-close { position: absolute; top: 14px; right: 14px; width: 12px; height: 12px; overflow: hidden; line-height: 200px; }

.alert .alert-close:before, .alert .alert-close:after { position: absolute; top: 50%; left: 50%; width: 16px; height: 1px; background-color: #999; content: ''; }

.alert .alert-close:before { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(45deg); transform: translateZ(0) translate(-50%, -50%) rotate(45deg); }

.alert .alert-close:after { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(135deg); transform: translateZ(0) translate(-50%, -50%) rotate(135deg); }

.alert .alert-text { font-size: 15px; line-height: 24px; letter-spacing: -0.54px; text-align: center; }

.c-alert { font-size: 15px; font-weight: bold; text-align: center; color: #111; }

.c-alert .sub_text { display: block; margin-top: 6px; font-size: 15px; line-height: 1.5; color: #666; font-weight: normal; }

.icon { overflow: hidden; display: inline-block; height: 0; vertical-align: middle; overflow: hidden; line-height: 200px; }

.icon-must { margin: -2px 0 0 -2px; background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -177px -44px; width: 8px; height: 8px; background-size: 215px 165px; }

.icon-play { margin-right: 2px; background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -193px -102px; width: 18px; height: 18px; background-size: 215px 165px; }

button .icon-play { vertical-align: -4px; }

.icon-add { position: relative; width: 8px; height: 8px; margin: -3px 3px 0 0; }

.icon-add:before, .icon-add:after { position: absolute; top: 50%; left: 50%; width: 2px; height: 100%; margin: -4px 0 0 -1px; background-color: #f43142; content: ''; }

.icon-add:after { width: 100%; height: 2px; margin: -1px 0 0 -4px; }

.btn-size__1 .icon-add, .btn-size__0 .icon-add, .btn-size__10 .icon-add { width: 10px; height: 10px; }

.btn-size__1 .icon-add:before, .btn-size__0 .icon-add:before, .btn-size__10 .icon-add:before { margin-top: -5px; }

.btn-size__1 .icon-add:after, .btn-size__0 .icon-add:after, .btn-size__10 .icon-add:after { margin-left: -5px; }

[class^="btn-style"]:disabled .icon-add:before, [class^="btn-style"]:disabled .icon-add:after { background-color: #ccc; }

.icon_excel { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -193px 0px; width: 22px; height: 22px; background-size: 215px 165px; margin-right: 1px; }

.icon-order { position: absolute; top: 50%; left: 16px; margin-top: -10px; background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -193px -27px; width: 20px; height: 20px; background-size: 215px 165px; }

.btn-style__3 .icon-order { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: -193px -52px; width: 20px; height: 20px; background-size: 215px 165px; }

.icon-mark { width: 3px; height: 3px; margin: -2px 2px 0 0; background-color: #111; border-radius: 50%; }

.icon-link { overflow: hidden; display: inline-block; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; vertical-align: -1px; transform: rotate(45deg) translateY(-50%); font-size: 0; line-height: 200px; white-space: nowrap; }

.icon-11st { background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: 0px -149px; width: 34px; height: 16px; background-size: 215px 165px; }

.flag-em { position: relative; display: inline-block; min-width: 30px; margin-right: 1px; padding: 0 4px; color: #f43142; border: 1px solid #f43142; border-radius: 2px; font-size: 11px; line-height: 1.6; }

.flag-em:after { position: absolute; top: -1px; right: -4px; bottom: -1px; width: 3px; background-color: #fff; content: ''; }

.write-box .write-box__row + .write-box__row, .write-box__2 .write-box__row + .write-box__row, .write-box__3 .write-box__row + .write-box__row, .write-box__4 .write-box__row + .write-box__row { margin-top: 20px; }

.write-box .write-box__row.inline, .write-box__2 .write-box__row.inline, .write-box__3 .write-box__row.inline, .write-box__4 .write-box__row.inline, .write-box .write-box__row.inline-type__2, .write-box__2 .write-box__row.inline-type__2, .write-box__3 .write-box__row.inline-type__2, .write-box__4 .write-box__row.inline-type__2 { display: table; width: 100%; }

.write-box .write-box__row.inline .row_title, .write-box__2 .write-box__row.inline .row_title, .write-box__3 .write-box__row.inline .row_title, .write-box__4 .write-box__row.inline .row_title, .write-box .write-box__row.inline-type__2 .row_title, .write-box__2 .write-box__row.inline-type__2 .row_title, .write-box__3 .write-box__row.inline-type__2 .row_title, .write-box__4 .write-box__row.inline-type__2 .row_title { display: table-cell; width: 136px; padding: 9px 0; vertical-align: top; color: #111; }

.write-box .write-box__row.inline .row_cont, .write-box__2 .write-box__row.inline .row_cont, .write-box__3 .write-box__row.inline .row_cont, .write-box__4 .write-box__row.inline .row_cont, .write-box .write-box__row.inline-type__2 .row_cont, .write-box__2 .write-box__row.inline-type__2 .row_cont, .write-box__3 .write-box__row.inline-type__2 .row_cont, .write-box__4 .write-box__row.inline-type__2 .row_cont { display: table-cell; vertical-align: top; }

.write-box .write-box__row.inline .row_cont [class^="radio-style"], .write-box__2 .write-box__row.inline .row_cont [class^="radio-style"], .write-box__3 .write-box__row.inline .row_cont [class^="radio-style"], .write-box__4 .write-box__row.inline .row_cont [class^="radio-style"], .write-box .write-box__row.inline-type__2 .row_cont [class^="radio-style"], .write-box__2 .write-box__row.inline-type__2 .row_cont [class^="radio-style"], .write-box__3 .write-box__row.inline-type__2 .row_cont [class^="radio-style"], .write-box__4 .write-box__row.inline-type__2 .row_cont [class^="radio-style"] { margin-top: 9px; margin-bottom: 9px; }

.write-box .write-box__row.inline-type__2 .row_title, .write-box__2 .write-box__row.inline-type__2 .row_title, .write-box__3 .write-box__row.inline-type__2 .row_title, .write-box__4 .write-box__row.inline-type__2 .row_title { width: 110px; }

.write-box .write-box__row .row_title, .write-box__2 .write-box__row .row_title, .write-box__3 .write-box__row .row_title, .write-box__4 .write-box__row .row_title { position: relative; margin-bottom: 20px; padding: 10px 170px 0 0; color: #333; font-weight: bold; }

.write-box .write-box__row .row_title i, .write-box__2 .write-box__row .row_title i, .write-box__3 .write-box__row .row_title i, .write-box__4 .write-box__row .row_title i { font-weight: normal; }

.write-box .write-box__row .row_title p, .write-box__2 .write-box__row .row_title p, .write-box__3 .write-box__row .row_title p, .write-box__4 .write-box__row .row_title p { padding-top: 6px; color: #999; }

.write-box .write-box__row .row_title p + .side_btn .btn-size__6, .write-box__2 .write-box__row .row_title p + .side_btn .btn-size__6, .write-box__3 .write-box__row .row_title p + .side_btn .btn-size__6, .write-box__4 .write-box__row .row_title p + .side_btn .btn-size__6 { margin-top: -4px; }

.write-box .write-box__row .row_title .side_btn, .write-box__2 .write-box__row .row_title .side_btn, .write-box__3 .write-box__row .row_title .side_btn, .write-box__4 .write-box__row .row_title .side_btn { position: absolute; top: 50%; right: 0; }

.write-box .write-box__row .row_title .side_btn .btn-size__6, .write-box__2 .write-box__row .row_title .side_btn .btn-size__6, .write-box__3 .write-box__row .row_title .side_btn .btn-size__6, .write-box__4 .write-box__row .row_title .side_btn .btn-size__6 { margin-top: -13px; }

.write-box .view-item__select .item, .write-box__2 .view-item__select .item, .write-box__3 .view-item__select .item, .write-box__4 .view-item__select .item { display: inline-block; vertical-align: top; }

.write-box .view-item__select .item + .item, .write-box__2 .view-item__select .item + .item, .write-box__3 .view-item__select .item + .item, .write-box__4 .view-item__select .item + .item { margin-left: 46px; }

.write-box .view-item__select .item .item_cont, .write-box__2 .view-item__select .item .item_cont, .write-box__3 .view-item__select .item .item_cont, .write-box__4 .view-item__select .item .item_cont { width: 200px; margin-top: 10px; }

.write-box .view-item__select .item .item_cont > img, .write-box__2 .view-item__select .item .item_cont > img, .write-box__3 .view-item__select .item .item_cont > img, .write-box__4 .view-item__select .item .item_cont > img { width: 100%; height: 100%; border: solid 1px rgba(0, 0, 0, 0.08); }

.write-box .view-item__select .item.item-style__1 > .item_cont, .write-box__2 .view-item__select .item.item-style__1 > .item_cont, .write-box__3 .view-item__select .item.item-style__1 > .item_cont, .write-box__4 .view-item__select .item.item-style__1 > .item_cont { height: 200px; }

.write-box .view-item__select .item.item-style__2 > .item_cont, .write-box__2 .view-item__select .item.item-style__2 > .item_cont, .write-box__3 .view-item__select .item.item-style__2 > .item_cont, .write-box__4 .view-item__select .item.item-style__2 > .item_cont { height: 134px; }

.write-box .view-item__editor, .write-box__2 .view-item__editor, .write-box__3 .view-item__editor, .write-box__4 .view-item__editor { display: flex; height: 300px; background-color: #fff; justify-content: center; align-items: center; text-align: center; border: 1px solid #ddd; }

.write-box.type_bg, .type_bg.write-box__2, .type_bg.write-box__3, .type_bg.write-box__4 { position: relative; padding: 24px; background-color: #fafafa; border: 1px solid #eee; }

.write-box.type_bg + .write-box.type_bg, .type_bg.write-box__2 + .write-box.type_bg, .type_bg.write-box__3 + .write-box.type_bg, .type_bg.write-box__4 + .write-box.type_bg, .write-box.type_bg + .type_bg.write-box__2, .type_bg.write-box__2 + .type_bg.write-box__2, .type_bg.write-box__3 + .type_bg.write-box__2, .type_bg.write-box__4 + .type_bg.write-box__2, .write-box.type_bg + .type_bg.write-box__3, .type_bg.write-box__2 + .type_bg.write-box__3, .type_bg.write-box__3 + .type_bg.write-box__3, .type_bg.write-box__4 + .type_bg.write-box__3, .write-box.type_bg + .type_bg.write-box__4, .type_bg.write-box__2 + .type_bg.write-box__4, .type_bg.write-box__3 + .type_bg.write-box__4, .type_bg.write-box__4 + .type_bg.write-box__4 { margin-top: 24px; }

.write-box.type_bg .write-box__row.inline .row_title, .type_bg.write-box__2 .write-box__row.inline .row_title, .type_bg.write-box__3 .write-box__row.inline .row_title, .type_bg.write-box__4 .write-box__row.inline .row_title, .write-box.type_bg .write-box__row.inline-type__2 .row_title, .type_bg.write-box__2 .write-box__row.inline-type__2 .row_title, .type_bg.write-box__3 .write-box__row.inline-type__2 .row_title, .type_bg.write-box__4 .write-box__row.inline-type__2 .row_title { width: 86px; }

.write-box.type_bg .write-box__row.add, .type_bg.write-box__2 .write-box__row.add, .type_bg.write-box__3 .write-box__row.add, .type_bg.write-box__4 .write-box__row.add { display: table; width: 100%; }

.write-box.type_bg .write-box__row.add .add_upload, .type_bg.write-box__2 .write-box__row.add .add_upload, .type_bg.write-box__3 .write-box__row.add .add_upload, .type_bg.write-box__4 .write-box__row.add .add_upload { display: table-cell; width: 160px; padding-right: 24px; vertical-align: top; }

.write-box.type_bg .write-box__row.add .add_upload + .box_sub, .type_bg.write-box__2 .write-box__row.add .add_upload + .box_sub, .type_bg.write-box__3 .write-box__row.add .add_upload + .box_sub, .type_bg.write-box__4 .write-box__row.add .add_upload + .box_sub { padding-top: 15px; }

.write-box.type_bg .write-box__row.add .add_upload.upload-type__2 + .box_sub, .type_bg.write-box__2 .write-box__row.add .add_upload.upload-type__2 + .box_sub, .type_bg.write-box__3 .write-box__row.add .add_upload.upload-type__2 + .box_sub, .type_bg.write-box__4 .write-box__row.add .add_upload.upload-type__2 + .box_sub { padding-top: 0; }

.write-box.type_bg .write-box__row.info, .type_bg.write-box__2 .write-box__row.info, .type_bg.write-box__3 .write-box__row.info, .type_bg.write-box__4 .write-box__row.info { margin-bottom: 6px; color: #333; }

.write-box.type_bg .write-box__row.info p, .type_bg.write-box__2 .write-box__row.info p, .type_bg.write-box__3 .write-box__row.info p, .type_bg.write-box__4 .write-box__row.info p { color: #333; }

.write-box.type_bg .write-box__row.divide, .type_bg.write-box__2 .write-box__row.divide, .type_bg.write-box__3 .write-box__row.divide, .type_bg.write-box__4 .write-box__row.divide { margin-top: 30px; }

.write-box.type_bg .write-box__row .row_title, .type_bg.write-box__2 .write-box__row .row_title, .type_bg.write-box__3 .write-box__row .row_title, .type_bg.write-box__4 .write-box__row .row_title { font-weight: normal; }

.write-box.type_bg .split, .type_bg.write-box__2 .split, .type_bg.write-box__3 .split, .type_bg.write-box__4 .split { margin-bottom: 30px; padding-bottom: 30px; border-bottom: 1px solid #ddd; }

.write-box.type_bg .btn-icon__delete, .type_bg.write-box__2 .btn-icon__delete, .type_bg.write-box__3 .btn-icon__delete, .type_bg.write-box__4 .btn-icon__delete, .write-box.type_bg .btn-style__icon.btn-icon__delete2, .type_bg.write-box__2 .btn-style__icon.btn-icon__delete2, .type_bg.write-box__3 .btn-style__icon.btn-icon__delete2, .type_bg.write-box__4 .btn-style__icon.btn-icon__delete2, .write-box.type_bg .btn-style__icon.btn-icon__delete3, .type_bg.write-box__2 .btn-style__icon.btn-icon__delete3, .type_bg.write-box__3 .btn-style__icon.btn-icon__delete3, .type_bg.write-box__4 .btn-style__icon.btn-icon__delete3, .write-box.type_bg .btn-style__icon.btn-icon__close, .type_bg.write-box__2 .btn-style__icon.btn-icon__close, .type_bg.write-box__3 .btn-style__icon.btn-icon__close, .type_bg.write-box__4 .btn-style__icon.btn-icon__close { position: absolute; right: 15px; top: 15px; }

.write-box__2.type_bg { padding-right: 58px; }

.write-box__2.type_bg .write-box__row.inline .row_title, .write-box__2.type_bg .write-box__row.inline-type__2 .row_title { width: 136px; }

.write-box__3 .write-box__row { padding: 24px; }

.write-box__3 .write-box__row + .write-box__row { margin-top: 0; border-top: 1px solid #eee; }

.write-box__3 .write-box__row.inline .row_title, .write-box__3 .write-box__row.inline-type__2 .row_title { width: 176px; padding: 5px 0; }

.write-box__3 .write-box__row.inline .row_cont [class^="radio-style"], .write-box__3 .write-box__row.inline-type__2 .row_cont [class^="radio-style"] { margin-top: 5px; margin-bottom: 4px; }

.write-box__3 .write-box__row .area-keyword + .c-check__keyword { margin-top: 20px; }

.write-box__3 .write-box__row .area-keyword .area-keyword__selected { position: relative; display: inline-block; min-width: 412px; min-height: 40px; padding: 8px 12px; background-color: #fff; border: 1px solid #ddd; vertical-align: middle; line-height: 22px; }

.write-box__3 .write-box__row .area-keyword .placeholder { position: absolute; top: 8px; left: 12px; color: #999; }

.write-box__3 .write-box__row .area-keyword .item { position: relative; padding-right: 12px; background-color: #fff; color: #111; }

.write-box__3 .write-box__row .area-keyword .item .btn-style__icon { margin-top: -4px; }

.write-box__4 { margin-bottom: 30px; border-color: #ddd !important; }

.write-box__4.type_bg { padding: 20px 24px; }

.write-box__4.type_bg .write-box__row.inline .row_title, .write-box__4.type_bg .write-box__row.inline-type__2 .row_title { width: 116px; font-weight: bold; }

.write-box__4 .write-box__row.inline .row_title, .write-box__4 .write-box__row.inline-type__2 .row_title { padding: 0; vertical-align: middle; }

.write-box__4 .write-box__row.inline .row_cont [class^="radio-style"], .write-box__4 .write-box__row.inline-type__2 .row_cont [class^="radio-style"] { margin-top: 0; margin-bottom: 0; }

.write-box__btn { margin-top: 24px; padding: 0 30px; text-align: center; }

.box_sub .box_sub_row + .box_sub_row { margin-top: 16px; }

.search-box { margin-bottom: 30px; padding-bottom: 40px; border-bottom: 1px solid #eee; }

.search-box .write-box__row { display: table; width: 100%; }

.search-box .write-box__row + .write-box__row { margin-top: 20px; }

.search-box .write-box__row .row_title { display: table-cell; width: 90px; padding: 9px 0; vertical-align: top; color: #111; letter-spacing: -0.5px; }

.search-box .write-box__row .row_cont { display: table-cell; vertical-align: top; }

.search-box .write-box__row .row_cont .cont_flex { display: flex; }

.search-box .write-box__row .row_cont .cont_flex .c-dropdown { flex: 1; }

.search-box .write-box__row .row_cont .cont_flex .c-dropdown .dropdown { width: 100%; }

.search-box .write-box__row .row_cont .cont_flex .c-dropdown .btn { width: 100%; }

.search-box .write-box__row .row_cont .cont_flex .c-dropdown + .c-dropdown { margin-left: 11px; }

.search-box .box_btn { padding-top: 30px; text-align: center; }

.search-box.box-type__bg { padding: 20px 24px; background-color: #fafafa; border: 1px solid #ddd; }

.c-paging { margin-top: 20px; text-align: center; font-size: 0; }

.c-paging a, .c-paging button, .c-paging em { display: inline-block; width: 48px; height: 48px; color: #111; font-size: 14px; line-height: 48px; vertical-align: middle; font-family: "Roboto", Tahoma, sans-serif; background: transparent; }

.c-paging a.first, .c-paging a.prev, .c-paging a.next, .c-paging a.last, .c-paging button.first, .c-paging button.prev, .c-paging button.next, .c-paging button.last { overflow: hidden; position: relative; z-index: 1; background-color: #fff; color: #ccc; line-height: 200px; }

.c-paging a.first:before, .c-paging a.first:after, .c-paging a.prev:before, .c-paging a.prev:after, .c-paging a.next:before, .c-paging a.next:after, .c-paging a.last:before, .c-paging a.last:after, .c-paging button.first:before, .c-paging button.first:after, .c-paging button.prev:before, .c-paging button.prev:after, .c-paging button.next:before, .c-paging button.next:after, .c-paging button.last:before, .c-paging button.last:after { position: absolute; top: 50%; left: 50%; content: ""; display: inline-block; vertical-align: middle; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; }

.c-paging a.first:before, .c-paging button.first:before { margin: -4px 0 0 -4px; transform: rotate(-135deg); }

.c-paging a.first:after, .c-paging button.first:after { margin: -4px 0 0 2px; transform: rotate(-135deg); }

.c-paging a.first + .prev, .c-paging button.first + .prev { border-left: none; }

.c-paging a.prev:before, .c-paging button.prev:before { margin: -4px 0 0 -2px; transform: rotate(-135deg); }

.c-paging a.prev:after, .c-paging button.prev:after { display: none; }

.c-paging a.next:before, .c-paging button.next:before { margin: -4px 0 0 -4px; transform: rotate(45deg); }

.c-paging a.next:after, .c-paging button.next:after { display: none; }

.c-paging a.next + .last, .c-paging button.next + .last { border-left: none; }

.c-paging a.last:before, .c-paging button.last:before { margin: -4px 0 0 -8px; transform: rotate(45deg); }

.c-paging a.last:after, .c-paging button.last:after { margin: -4px 0 0 -2px; transform: rotate(45deg); }

.c-paging em { color: #f43142; font-weight: bold; }

.c-paging a.disabled.first:before, .c-paging a.disabled.first:after, .c-paging a.disabled.prev:before, .c-paging a.disabled.prev:after, .c-paging a.disabled.next:before, .c-paging a.disabled.next:after, .c-paging a.disabled.last:before, .c-paging a.disabled.last:after, .c-paging button:disabled.first:before, .c-paging button:disabled.first:after, .c-paging button:disabled.prev:before, .c-paging button:disabled.prev:after, .c-paging button:disabled.next:before, .c-paging button:disabled.next:after, .c-paging button:disabled.last:before, .c-paging button:disabled.last:after { border-color: rgba(0, 0, 0, 0.12); }

.st-layer { position: absolute; z-index: 4; margin-top: -1px; border: 1px solid #ddd; box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04); }

.st-calendar { width: 304px; padding: 12px; background-color: #fff; text-align: center; }

.st-calendar .st-calendar__header { position: relative; margin: 0 -13px 7px; padding: 0 40px; text-align: center; }

.st-calendar .st-calendar__header .btn-style__icon { position: absolute; top: 0; left: 0; }

.st-calendar .st-calendar__header .btn-style__icon.btn-icon__next2 { right: 0; left: auto; }

.st-calendar .st-calendar__body .calendar-table { width: 100%; }

.st-calendar .st-calendar__body .calendar-table thead th { height: 40px; color: #333; font-size: 13px; }

.st-calendar .st-calendar__body .calendar-table thead th:first-of-type { color: #f43142; }

.st-calendar .st-calendar__body .calendar-table tbody td { height: 40px; }

.st-calendar .st-calendar__body .calendar-table tbody td:first-of-type .td_day { color: #f43142; }

.st-calendar .st-calendar__body .calendar-table .td_day { display: block; height: 100%; width: 100%; padding: 5px; color: #111; font-family: "Roboto", Tahoma, sans-serif; font-size: 13px; }

.st-calendar .st-calendar__body .calendar-table .today { background-color: #f3f3f3; }

.st-calendar .st-calendar__body .calendar-table .disabled { color: #ddd; }

.st-calendar .st-calendar__body .calendar-table .active { color: #fff !important; background-color: #f43142; }

.text_guide { margin: 40px 0 20px; }

.text_guide.guide-type__2 { position: relative; margin: 0; padding-left: 6px; color: #111; }

.text_guide.guide-type__2:before { position: absolute; top: 10px; left: 0; width: 2px; height: 2px; margin-right: 4px; background-color: #111; vertical-align: 3px; border-radius: 50%; content: ''; }

.text_sub { color: #999; font-size: 13px; letter-spacing: 0; }

.text_sub.sub-type__2 { color: #111; font-size: 15px; }

.text_info { margin-top: 10px; font-size: 13px; letter-spacing: 0; }

.text_info.info-type__2 { margin: 25px 0 15px; }

.text_info.info-type__3 { color: #333; }

.text_info.info-type__4 { margin: 0 0 20px; }

.text_info.info-type__5 { margin-top: 20px; }

.text_info > .text_em { margin: 0; }

.text_info > button { margin-left: 5px; }

.text_add { margin-left: 8px; color: #111; font-size: 13px; letter-spacing: 0; }

.text_confirm { margin-bottom: 12px; color: #999; }

.text_confirm:before { display: inline-block; width: 7px; height: 12px; margin: 0 8px 0 2px; vertical-align: 2px; border: 1px solid #999; border-width: 0 1.5px 1.5px 0; transform: rotate(45deg); content: ''; }

.text_confirm.type__em { color: #f43142; }

.text_confirm.type__em:before { border-color: #f43142; }

.text_em { margin-top: 20px; color: #f43142; }

.text_alert { margin-top: 10px; font-size: 12px; }

.st-msg { position: fixed; bottom: 100px; left: 50%; z-index: 99; min-width: 260px; margin-left: -130px; padding: 11px 20px; background-color: rgba(0, 0, 0, 0.75); border-radius: 22px; text-align: center; color: #fff; font-size: 15px; letter-spacing: 0; }

.st-msg.confirm:before { display: inline-block; width: 6px; height: 11px; margin-right: 10px; vertical-align: 2px; border: 1px solid #fff; border-width: 0 1px 1px 0; transform: rotate(45deg); content: ''; }

.st-msg__guide { position: absolute; left: -29px; height: 28px; margin-top: 9px; padding: 5px 15px; background-color: #000; border-radius: 14px; color: #fff; font-size: 12px; white-space: nowrap; }

.st-msg__guide:after { position: absolute; top: -6px; left: 47px; width: 0; height: 0; border-right: 4px solid transparent; border-bottom: 6px solid #000; border-left: 4px solid transparent; content: ''; }

.st-msg__guide > p { display: inline-block; color: #fff; font-size: 12px; }

.st-msg__guide.bottom:after { top: auto; bottom: -6px; left: 50%; border-top: 6px solid #000; border-bottom: none; }

.st-msg__guide.guide-type__2 { top: -27px; left: 50%; margin: 0 0 0 -123px; }

.text_list li { position: relative; padding-left: 6px; font-size: 13px; letter-spacing: 0; }

.text_list li + li { margin-top: 5px; word-break: keep-all; }

.text_list li:before { position: absolute; top: 9px; left: 0; width: 2px; height: 2px; margin-right: 4px; background-color: #ccc; vertical-align: 3px; content: ''; }

.text_list.list-style__lg li { padding-left: 8px; font-size: 14px; }

.text_list.list-style__lg li:before { top: 9px; }

.view-guide { padding-top: 50px; margin-bottom: 30px; text-align: center; color: #111; font-size: 18px; }

.view-item { position: relative; display: flex; padding: 10px 0 12px; }

.view-item .item { flex: 1; text-align: center; }

.view-item .item [class^="radio-style"] { margin-right: 0; }

.view-item .item .item-img { position: relative; width: 292px; margin: 20px auto 0; padding: 30px 14px; border: 1px solid #ddd; border-radius: 32px; background-color: #fff; }

.view-item .item .item-img .item-img__in { position: relative; z-index: 2; height: 690px; background-color: #eee; border: 1px solid #ddd; border-radius: 2px; }

.view-item .item .item-img span { overflow: hidden; display: block; margin-top: 5px; line-height: 9999px; }

.view-item .item .item-img span img { display: block; width: 100%; height: auto; }

.view-item .item .item-img .template-gnb, .view-item .item .item-img .template-visual, .view-item .item .item-img .template-store { margin-top: 0; }

.view-item .item .item-img.type_visual .img-in .in_size { transform: scale(0.72) translate(-70px, -1055px); margin-bottom: -1520px; }

.view-item .item.active .item-img { border-color: transparent; box-shadow: 0 2px 6px 0 rgba(29, 29, 29, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.6); }

.view-item .item .bar-wrap { width: 2px; height: 100%; position: absolute; top: 0; right: -26px; overflow: hidden; }

.view-item .item .bar-wrap > span { visibility: hidden; display: block; width: 2px; background-color: #111111; }

.view-item .item .type_visual .bar-wrap { left: -26px; }

.view-item .compare { position: absolute; top: 10px; left: 50%; display: flex; width: 196px; height: 750px; margin-top: 41px; margin-left: -98px; padding: 32px 0; justify-content: center; }

.view-item .compare .btn { align-self: center; }

.view-item .compare .btn button { white-space: nowrap; }

.view-item .info { position: relative; opacity: 0; transition: 1s opacity; }

.view-item .info .info-area, .view-item .info .info-area__2 { position: absolute; top: 0; left: 0; width: 2px; height: 50px; background-color: #000; }

.view-item .info .info-area__2 { right: 0; left: auto; }

.view-item .info .info-name { position: relative; z-index: 2; display: inline-block; padding: 8px 0 7px; background-color: #000; color: #fff; white-space: nowrap; }

.view-item .info .info-name:before { position: absolute; top: 0; bottom: 0; left: -20px; width: 20px; background: url("/img/store/bg/bg_template.png") left top no-repeat; content: ''; }

.view-item .info .info-name:after { position: absolute; top: 0; bottom: 0; right: -20px; width: 20px; background: url("/img/store/bg/bg_template.png") right top no-repeat; content: ''; }

.view-item .scroll-wrap { height: 100%; transition: 1s opacity; }

.view-skin { padding-bottom: 12px; text-align: center; }

.view-skin .view-skin__img { width: 392px; margin: 30px auto 0; padding: 40px 15px; border-radius: 32px; border: solid 1px #ddd; background-color: #fff; }

.view-skin .view-skin__img .img_in { overflow-x: hidden; overflow-y: auto; position: relative; z-index: 2; height: 715px; background-color: #eee; border: solid 1px #ddd; border-radius: 2px; }

.view-skin .view-skin__img .img_in span { overflow: hidden; display: block; line-height: 9999px; margin-top: 8px; }

.view-skin .view-skin__img .img_in .skin_gnb, .view-skin .view-skin__img .img_in .skin_visual, .view-skin .view-skin__img .img_in .skin_store { margin-top: 0; }

.view-skin .view-skin__img .skin_visual { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -365px -899px; width: 360px; height: 240px; background-size: 1455px 1252px; }

.view-skin .view-skin__img .skin_banner { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -1095px -899px; width: 360px; height: 180px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_1 .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_1 .skin_store { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -730px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_1 .skin_info { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_1 .skin_search { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_1 .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_1 .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -365px -1144px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_1 .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_1 .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_1 .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_1 .skin_review { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_1 .skin_product { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_2 .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_02.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_2 .skin_store { background-image: url("/img/store/sprites/sp_display_skin_02.png"); background-position: -365px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_2 .skin_info { background-image: url("/img/store/sprites/sp_display_skin_02.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_2 .skin_search { background-image: url("/img/store/sprites/sp_display_skin_02.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_2 .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_02.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_2 .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_02.png"); background-position: -730px -899px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_2 .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_02.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_2 .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_02.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_2 .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_02.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_2 .skin_review { background-image: url("/img/store/sprites/sp_display_skin_02.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_2 .skin_product { background-image: url("/img/store/sprites/sp_display_skin_02.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_3 .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_03.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_3 .skin_store { background-image: url("/img/store/sprites/sp_display_skin_03.png"); background-position: -365px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_3 .skin_info { background-image: url("/img/store/sprites/sp_display_skin_03.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_3 .skin_search { background-image: url("/img/store/sprites/sp_display_skin_03.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_3 .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_03.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_3 .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_03.png"); background-position: -730px -899px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_3 .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_03.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_3 .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_03.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_3 .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_03.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_3 .skin_review { background-image: url("/img/store/sprites/sp_display_skin_03.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_3 .skin_product { background-image: url("/img/store/sprites/sp_display_skin_03.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_4 .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_04.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_4 .skin_store { background-image: url("/img/store/sprites/sp_display_skin_04.png"); background-position: -365px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_4 .skin_info { background-image: url("/img/store/sprites/sp_display_skin_04.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_4 .skin_search { background-image: url("/img/store/sprites/sp_display_skin_04.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_4 .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_04.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_4 .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_04.png"); background-position: -730px -899px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_4 .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_04.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_4 .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_04.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_4 .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_04.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_4 .skin_review { background-image: url("/img/store/sprites/sp_display_skin_04.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_4 .skin_product { background-image: url("/img/store/sprites/sp_display_skin_04.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_5 .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_05.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_5 .skin_store { background-image: url("/img/store/sprites/sp_display_skin_05.png"); background-position: -365px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_5 .skin_info { background-image: url("/img/store/sprites/sp_display_skin_05.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_5 .skin_search { background-image: url("/img/store/sprites/sp_display_skin_05.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_5 .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_05.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_5 .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_05.png"); background-position: -730px -899px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_5 .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_05.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_5 .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_05.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_5 .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_05.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_5 .skin_review { background-image: url("/img/store/sprites/sp_display_skin_05.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_5 .skin_product { background-image: url("/img/store/sprites/sp_display_skin_05.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_6 .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_06.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_6 .skin_store { background-image: url("/img/store/sprites/sp_display_skin_06.png"); background-position: -365px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_6 .skin_info { background-image: url("/img/store/sprites/sp_display_skin_06.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_6 .skin_search { background-image: url("/img/store/sprites/sp_display_skin_06.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_6 .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_06.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_6 .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_06.png"); background-position: -730px -899px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_6 .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_06.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_6 .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_06.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_6 .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_06.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_6 .skin_review { background-image: url("/img/store/sprites/sp_display_skin_06.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_6 .skin_product { background-image: url("/img/store/sprites/sp_display_skin_06.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_7 .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_07.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_7 .skin_store { background-image: url("/img/store/sprites/sp_display_skin_07.png"); background-position: -365px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_7 .skin_info { background-image: url("/img/store/sprites/sp_display_skin_07.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_7 .skin_search { background-image: url("/img/store/sprites/sp_display_skin_07.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_7 .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_07.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_7 .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_07.png"); background-position: -730px -899px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_7 .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_07.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_7 .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_07.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_7 .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_07.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_7 .skin_review { background-image: url("/img/store/sprites/sp_display_skin_07.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_7 .skin_product { background-image: url("/img/store/sprites/sp_display_skin_07.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_8 .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_08.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_8 .skin_store { background-image: url("/img/store/sprites/sp_display_skin_08.png"); background-position: -365px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_8 .skin_info { background-image: url("/img/store/sprites/sp_display_skin_08.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_8 .skin_search { background-image: url("/img/store/sprites/sp_display_skin_08.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_8 .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_08.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_8 .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_08.png"); background-position: -730px -899px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_8 .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_08.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_8 .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_08.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_8 .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_08.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_8 .skin_review { background-image: url("/img/store/sprites/sp_display_skin_08.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_8 .skin_product { background-image: url("/img/store/sprites/sp_display_skin_08.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_9 .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_09.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_9 .skin_store { background-image: url("/img/store/sprites/sp_display_skin_09.png"); background-position: -365px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_9 .skin_info { background-image: url("/img/store/sprites/sp_display_skin_09.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_9 .skin_search { background-image: url("/img/store/sprites/sp_display_skin_09.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_9 .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_09.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_9 .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_09.png"); background-position: -730px -899px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_9 .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_09.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_9 .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_09.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_9 .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_09.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_9 .skin_review { background-image: url("/img/store/sprites/sp_display_skin_09.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_9 .skin_product { background-image: url("/img/store/sprites/sp_display_skin_09.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_10 .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_10.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_10 .skin_store { background-image: url("/img/store/sprites/sp_display_skin_10.png"); background-position: -365px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_10 .skin_info { background-image: url("/img/store/sprites/sp_display_skin_10.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_10 .skin_search { background-image: url("/img/store/sprites/sp_display_skin_10.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_10 .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_10.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_10 .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_10.png"); background-position: -730px -899px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_10 .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_10.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_10 .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_10.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_10 .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_10.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_10 .skin_review { background-image: url("/img/store/sprites/sp_display_skin_10.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_10 .skin_product { background-image: url("/img/store/sprites/sp_display_skin_10.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_11 .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_11.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_11 .skin_store { background-image: url("/img/store/sprites/sp_display_skin_11.png"); background-position: -365px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_11 .skin_info { background-image: url("/img/store/sprites/sp_display_skin_11.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_11 .skin_search { background-image: url("/img/store/sprites/sp_display_skin_11.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_11 .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_11.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_11 .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_11.png"); background-position: -730px -899px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_11 .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_11.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_11 .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_11.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_11 .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_11.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_11 .skin_review { background-image: url("/img/store/sprites/sp_display_skin_11.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_11 .skin_product { background-image: url("/img/store/sprites/sp_display_skin_11.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_12 .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_12.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_12 .skin_store { background-image: url("/img/store/sprites/sp_display_skin_12.png"); background-position: -365px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_12 .skin_info { background-image: url("/img/store/sprites/sp_display_skin_12.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_12 .skin_search { background-image: url("/img/store/sprites/sp_display_skin_12.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_12 .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_12.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_12 .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_12.png"); background-position: -730px -899px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_12 .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_12.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_12 .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_12.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_12 .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_12.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_12 .skin_review { background-image: url("/img/store/sprites/sp_display_skin_12.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_12 .skin_product { background-image: url("/img/store/sprites/sp_display_skin_12.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_13 .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_13.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_13 .skin_store { background-image: url("/img/store/sprites/sp_display_skin_13.png"); background-position: -365px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_13 .skin_info { background-image: url("/img/store/sprites/sp_display_skin_13.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_13 .skin_search { background-image: url("/img/store/sprites/sp_display_skin_13.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_13 .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_13.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_13 .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_13.png"); background-position: -730px -899px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_13 .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_13.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_13 .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_13.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_13 .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_13.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_13 .skin_review { background-image: url("/img/store/sprites/sp_display_skin_13.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-skin .view-skin__img.skin_13 .skin_product { background-image: url("/img/store/sprites/sp_display_skin_13.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-display { position: relative; padding-bottom: 12px; text-align: center; }

.view-display .view-display__img { width: 392px; height: calc(100vh - 438px); min-height: 700px; margin: 30px auto 0; padding: 30px 15px; border-radius: 32px; border: solid 1px #ddd; background-color: #fff; }

.view-display .view-display__img .img-in { overflow-x: hidden; overflow-y: auto; position: relative; z-index: 2; height: 100%; background-color: #eee; border: solid 1px #ddd; border-radius: 2px; }

.view-display .view-display__img .img-in span { overflow: hidden; display: block; line-height: 9999px; margin-top: 8px; }

.view-display .view-display__img .img-in .skin_gnb, .view-display .view-display__img .img-in .skin_visual, .view-display .view-display__img .img-in .skin_store { margin-top: 0; }

.view-display .view-display__img .img-in .skin_gnb { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -1095px -747px; width: 360px; height: 124px; background-size: 1455px 1252px; }

.view-display .view-display__img .img-in .skin_visual { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -365px -899px; width: 360px; height: 240px; background-size: 1455px 1252px; }

.view-display .view-display__img .img-in .skin_store { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -730px -899px; width: 360px; height: 203px; background-size: 1455px 1252px; }

.view-display .view-display__img .img-in .skin_info { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: 0px -899px; width: 360px; height: 353px; background-size: 1455px 1252px; }

.view-display .view-display__img .img-in .skin_search { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -730px -792px; width: 360px; height: 80px; background-size: 1455px 1252px; }

.view-display .view-display__img .img-in .skin_recommend { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -1095px -374px; width: 360px; height: 368px; background-size: 1455px 1252px; }

.view-display .view-display__img .img-in .skin_shortcut { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -365px -1144px; width: 360px; height: 68px; background-size: 1455px 1252px; }

.view-display .view-display__img .img-in .skin_promotion { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -365px 0px; width: 360px; height: 848px; background-size: 1455px 1252px; }

.view-display .view-display__img .img-in .skin_cardview { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -1095px 0px; width: 360px; height: 369px; background-size: 1455px 1252px; }

.view-display .view-display__img .img-in .skin_movie { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -730px 0px; width: 360px; height: 398px; background-size: 1455px 1252px; }

.view-display .view-display__img .img-in .skin_banner { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -1095px -899px; width: 360px; height: 180px; background-size: 1455px 1252px; }

.view-display .view-display__img .img-in .skin_review { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: -730px -403px; width: 360px; height: 384px; background-size: 1455px 1252px; }

.view-display .view-display__img .img-in .skin_product { background-image: url("/img/store/sprites/sp_display_skin_01.png"); background-position: 0px 0px; width: 360px; height: 894px; background-size: 1455px 1252px; }

.view-display .view-display__area { overflow: hidden; position: absolute; top: 396px; left: 50%; width: 2px; height: 50px; margin-left: -208px; background-color: #f43142; text-indent: -9999px; }

.view-guide__instagram { padding: 16px 30px 30px; text-align: center; color: #111; font-weight: bold; line-height: 1.7; }

.view-guide__instagram:before { display: block; margin: 0 auto 16px; content: ''; background-image: url("/img/store/sprites/sp_icon_2x_202326_143355.png"); background-position: 0px 0px; width: 127px; height: 30px; background-size: 215px 165px; }

.cont-filter { display: flex; margin-bottom: 20px; }

.cont-filter .filter-side__2 { flex: 1; text-align: right; }

.c-radio__tab .tab_cont { margin-top: 15px; line-height: 1.6; }

.c-radio__tab .tab_cont .cont_add { display: table; width: 100%; margin-top: 20px; }

.c-radio__tab .tab_cont .cont_add .title { display: table-cell; width: 93px; vertical-align: middle; color: #111; font-weight: normal; white-space: nowrap; }

.c-radio__tab .tab_cont .cont_add.type__2 { width: auto; }

.c-radio__tab .tab_cont .cont_add.type__2 .title { vertical-align: top; }

.c-radio__tab .tab_cont .cont_add .location { display: table-cell; padding-right: 30px; vertical-align: middle; }

.c-radio__tab .tab_cont .cont_add .location + .text_list { display: table-cell; vertical-align: middle; }

.c-radio__tab .tab_cont.disabled .text_url .cont, .c-radio__tab .tab_cont.disabled .text_url .cont > i { color: #666; }

.c-radio__tab .text_url { margin-top: 16px; }

.c-radio__tab .text_url .title { display: inline-block; width: 44px; font-size: 13px; letter-spacing: 0; }

.c-radio__tab .text_url .cont { color: #999; font-size: 13px; letter-spacing: 0; }

.c-radio__tab .text_url .cont > i { color: #111; }

.list-item { display: table; width: 100%; white-space: nowrap; }

.list-item dt { display: table-cell; padding-right: 8px; vertical-align: middle; color: #111; }

.list-item dd { display: table-cell; padding-right: 40px; vertical-align: middle; }

.list-item dd:last-of-type { padding-right: 0; }

.list-item dd.dd-size__1 { width: 175px; }

.cont_profile { display: table-cell; padding-right: 30px; vertical-align: middle; }

.cont_profile + .text_list { display: table-cell; vertical-align: middle; }

.st-filter__cate { padding-top: 10px; }

.st-cont__cate { display: flex; }

.cate-menu .menu-depth, .area-cont__cate .menu-depth { display: flex; }

.cate-menu .menu-depth [class^="menu-depth__"], .area-cont__cate .menu-depth [class^="menu-depth__"] { overflow-x: hidden; overflow-y: auto; position: relative; width: 230px; border: 1px solid #ddd; border-width: 1px 0; align-items: stretch; }

.cate-menu .menu-depth [class^="menu-depth__"]:before, .area-cont__cate .menu-depth [class^="menu-depth__"]:before { position: absolute; top: 0; right: 0; bottom: 0; left: 0; border: 1px solid #ddd; border-width: 0 1px; content: ''; }

.cate-menu .menu-depth [class^="menu-depth__"] + [class^="menu-depth__"], .area-cont__cate .menu-depth [class^="menu-depth__"] + [class^="menu-depth__"] { width: 229px; }

.cate-menu .menu-depth [class^="menu-depth__"] + [class^="menu-depth__"]:before, .area-cont__cate .menu-depth [class^="menu-depth__"] + [class^="menu-depth__"]:before, .cate-menu .menu-depth [class^="menu-depth__"] + [class^="menu-depth__"] .depth_list:before, .area-cont__cate .menu-depth [class^="menu-depth__"] + [class^="menu-depth__"] .depth_list:before { border-left: none; }

.cate_default .cate-menu .menu-depth [class^="menu-depth__"], .cate_default .area-cont__cate .menu-depth [class^="menu-depth__"] { height: 587px; }

.cate_step1 .cate-menu .menu-depth [class^="menu-depth__"], .cate_step1 .area-cont__cate .menu-depth [class^="menu-depth__"] { height: 587px; }

.cate_step2 .cate-menu .menu-depth [class^="menu-depth__"], .cate_step2 .area-cont__cate .menu-depth [class^="menu-depth__"] { height: 524px; }

.cate_step3 .cate-menu .menu-depth [class^="menu-depth__"], .cate_step3 .area-cont__cate .menu-depth [class^="menu-depth__"] { height: 587px; }

.cate-menu .menu-depth [class^="menu-depth__"].type_move, .area-cont__cate .menu-depth [class^="menu-depth__"].type_move { overflow: visible; }

.cate-menu .depth_btn, .area-cont__cate .depth_btn { display: flex; padding: 10px; }

.cate-menu .depth_btn button, .area-cont__cate .depth_btn button { flex: 1; }

.cate-menu .depth_list, .area-cont__cate .depth_list { position: relative; }

.cate-menu .depth_list:before, .area-cont__cate .depth_list:before { position: absolute; top: 0; right: 0; bottom: 0; left: 0; border: 1px solid #ddd; border-width: 0 1px; content: ''; }

.cate-menu .depth_list .title, .area-cont__cate .depth_list .title { display: block; margin: 0 16px 5px; padding: 16px 0 9px; border-bottom: 1px solid #eee; color: #999; font-weight: normal; font-size: 12px; }

.cate-menu .depth_list .list .item, .area-cont__cate .depth_list .list .item { padding: 1px; }

.cate-menu .depth_list .list .item button, .area-cont__cate .depth_list .list .item button { position: relative; width: 100%; padding: 5px 24px 6px 14px; background-color: #fff; text-align: left; color: #111; font-size: 13px; letter-spacing: 0; }

.cate-menu .depth_list .list .item button i, .area-cont__cate .depth_list .list .item button i { color: #999; }

.cate-menu .depth_list .list .item button.drop:before, .area-cont__cate .depth_list .list .item button.drop:before, .cate-menu .depth_list .list .item button.drop:after, .area-cont__cate .depth_list .list .item button.drop:after { display: none; position: absolute; z-index: 10; left: 0; width: 100%; height: 2px; background-color: #f00; content: ''; }

.cate-menu .depth_list .list .item button.drop.before:before, .area-cont__cate .depth_list .list .item button.drop.before:before { display: block; }

.cate-menu .depth_list .list .item button.drop.after:after, .area-cont__cate .depth_list .list .item button.drop.after:after { display: block; }

.cate-menu .depth_list .list .item button.drop:before, .area-cont__cate .depth_list .list .item button.drop:before { top: -2px; }

.cate-menu .depth_list .list .item button.drop:after, .area-cont__cate .depth_list .list .item button.drop:after { bottom: -2px; }

.cate-menu .depth_list .list .item.active button, .area-cont__cate .depth_list .list .item.active button { background-color: #fafafa; color: #f43142; }

.cate-menu .depth_list .list .item.add button:after, .area-cont__cate .depth_list .list .item.add button:after { position: absolute; top: 50%; right: 20px; width: 8px; height: 8px; margin-top: -4px; border: 1px solid #999; border-width: 1px 1px 0 0; vertical-align: middle; transform: rotate(45deg); content: ''; }

.cate-menu .depth_list .list .item.edit_dim button, .area-cont__cate .depth_list .list .item.edit_dim button, .cate-menu .depth_list .list .item.edit_active button, .area-cont__cate .depth_list .list .item.edit_active button { width: calc(100% + 2px); height: 40px; margin: -1px; padding-top: 8px; padding-bottom: 9px; background-color: #fafafa; border: 1px solid #999; box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.1); color: #999; font-size: 14px; }

.cate-menu .depth_list .list .item.edit_active button, .area-cont__cate .depth_list .list .item.edit_active button { background-color: #fff; color: #111; }

.cate-menu .depth_list .list .item.drag_off button, .area-cont__cate .depth_list .list .item.drag_off button { padding-top: 9px; padding-bottom: 10px; background-color: #fafafa; color: #999; font-size: 14px; opacity: 0.5; cursor: none; }

.cate-menu .depth_list .list .item.drag_on button, .area-cont__cate .depth_list .list .item.drag_on button { position: absolute; top: 200px; left: 30px; z-index: 11; width: calc(100% + 2px); margin: 0 -1px; padding-top: 8px; padding-bottom: 9px; background-color: #fff; box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.1); border: 1px solid #666; font-size: 14px; opacity: 0.9; cursor: move; }

.cate-menu .depth_list .text_item, .area-cont__cate .depth_list .text_item { margin: 16px; color: #333; font-size: 13px; letter-spacing: 0; }

.cate-menu .depth_list .text_item em, .area-cont__cate .depth_list .text_item em { color: #f43142; }

.cate-menu .depth_list .btn, .area-cont__cate .depth_list .btn { margin: 25px 16px 16px; }

.cate-menu .depth_list .btn [class^="btn-style"], .area-cont__cate .depth_list .btn [class^="btn-style"] { width: 100%; }

.cate-menu .menu_btn, .area-cont__cate .menu_btn { clear: both; display: flex; padding-top: 10px; font-size: 0; }

.cate-menu .menu_btn .side, .area-cont__cate .menu_btn .side { flex: 1; }

.cate-menu .menu_btn .side2, .area-cont__cate .menu_btn .side2 { text-align: right; }

.cate-menu .menu_btn .btn-style__img:first-of-type, .area-cont__cate .menu_btn .btn-style__img:first-of-type { margin-right: -1px; }

.cate-menu .menu_btn .btn-style__img + .btn-style__img, .area-cont__cate .menu_btn .btn-style__img + .btn-style__img { margin-left: 4px; }

.cate-menu .text_alert, .area-cont__cate .text_alert { margin-right: -20px; }

.cate-view { position: relative; flex: 1; padding-left: 24px; }

.cate-view.view-type__2 { display: flex; margin-left: -1px; padding-left: 0; background-color: #fafafa; border: 1px solid #ddd; justify-content: center; align-items: center; }

.cate-view.view-type__3 { padding-bottom: 63px; }

.cate-view.view-type__3 .view-btn__area.area-type__2, .cate-view.view-type__3 .area-type__2.view-btn, .cate-view.view-type__3 .area-type__2.st-bottom { position: absolute; right: 0; bottom: 3px; left: 24px; padding-bottom: 0; border-top: 1px solid #ddd; }

.view-guide__2 { text-align: center; }

.view-guide__2 .text { color: #111; font-size: 18px; }

.view-guide__2 .btn { margin-top: 16px; }

.view-guide__2 .img { margin-top: 30px; }

.view-guide__2 .img img { display: block; width: 408px; height: 327px; margin: 0 auto; }

.view-guide__2 .img .alt { display: block; margin-top: 10px; text-align: center; color: #999; }

.view-guide__3 .title { display: block; margin-bottom: 50px; color: #f43142; font-size: 18px; }

.view-guide__3 .title.confirm:before { display: block; width: 30px; height: 48px; margin: 0 0 20px 13px; vertical-align: 2px; border: 1px solid #f43142; border-width: 0 6px 6px 0; transform: rotate(48deg); content: ''; }

.view-guide__3 .text { margin-top: 17px; color: #333; }

.view-guide__3 .btn { margin-top: 50px; vertical-align: middle; }

.area-cont__cate { border: 1px solid #ddd; }

.area-cont__cate .menu-depth [class^="menu-depth__"] { overflow-y: auto; flex: 1; width: auto; height: 360px; padding: 10px 0; border: none; }

.area-cont__cate .menu-depth [class^="menu-depth__"]:before, .area-cont__cate .menu-depth [class^="menu-depth__"] .depth_list:before { content: none; }

.area-cont__cate .menu-depth [class^="menu-depth__"] + [class^="menu-depth__"] { border-left: 1px solid #ddd; }

.area-cont__cate .menu-depth [class^="menu-depth__"] .item button { padding: 8px 16px 9px; }

.area-cont__cate .menu-depth .menu-depth__2 .item button { position: relative; padding-left: 23px; }

.area-cont__cate .menu-depth .menu-depth__2 .item button .icon-mark { position: absolute; top: 50%; left: 16px; margin-top: -1px; }

.area-cont__list .item_cate { position: relative; padding: 8px 8px 10px 16px; background-color: #fafafa; border: 1px solid #eee; color: #000; }

.area-cont__list .item_cate + .item_cate { border-top: none; }

.area-cont__list .item_cate .btn-icon__delete2 { position: absolute; top: 0; right: 2px; height: 100%; }

.cont-menu__item .item .l-grid { overflow: hidden; width: 360px; margin-top: 0; transform: scale(0.71) translate(-20.5%, -20.5%); }

.w-swiper .swiper-button-next, .w-swiper .swiper-button-prev { width: 42px !important; height: 42px; margin-top: -21px; background-color: rgba(17, 17, 17, 0.2); }

.w-swiper .swiper-button-next:before, .w-swiper .swiper-button-prev:before { width: 11px; height: 11px; margin: -7px 0 0 -4px; border-color: #fff; }

[data-ui-type=Store_Contents_Home_Swiper] .c-imgcover__more span:after { top: 6px; }

[data-ui-type=Store_Contents_Home_Swiper] .c-pagination__list { line-height: 24px; }

.w-swiper .swiper-button-next:before { margin-left: -6px; }

.c-imgcover__text .c-imgcover__info { line-height: 21px; }

.c-card.c-card__dealcard .c-card__name { display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.c-card.c-card__gallery .c-card__info .c-card__name { display: block; display: -webkit-box; }

.c-swiper__none .w-swiper [class*=swiper-button] { display: none; }

[data-ui-type=Store_Product_ImgOverPrice] .c-card__onlyprice .c-card__thumb { height: 157px; }

[data-ui-type=Store_Product_Basic] .c-card__gallery .c-card__info { height: 74px !important; }

[data-ui-type=Store_BillBoard] .w-swiper [class*=swiper-button] { visibility: visible; }

[data-ui-type=Store_BillBoard] .swiper-slide img { height: 178px; }

@media print, screen and (min-width: 40em) { .l-grid .medium-3 { width: 50%; } }

.event_inquiry_box { height: 338px; }

.event_inquiry_box .event_inquiry_select { overflow: hidden; margin: 7px 0 16px 0; }

.event_inquiry_box .event_inquiry_select li { float: left; margin-right: 20px; }

.event_inquiry_box .event_number_search { min-height: 221px; }

.event_inquiry_box .event_number_search .text { margin-top: 13px; font-size: 14px; color: #111; }

.event_inquiry_box .event_number_search .c-input { margin-top: 17px; }

.event_inquiry_box .event_number_search .c-input .inp { width: 238px; }
