@charset "UTF-8";

%btn {
    position: relative;
    display: inline-block;
    padding: 0 18px;
    text-align: center;
    vertical-align: middle;

    &:disabled {
        border-color: rgba(204, 204, 204, 0.2) !important;
        color: $color-light__6 !important;
        background-color: $color-light__2 !important;
    }

    &:hover {
        text-decoration: none;
    }

    &+%btn {
        margin-left: 6px;
    }
}

// Style
.btn-style__1 {
    @extend %btn;
    background-color: $color-light;
    border: 1px solid $color-light__6;
    color: $color-dark__2;

    &:hover {
        background-color: $color-light__2;
        color: $color-dark__2;
    }
}

.btn-style__2 {
    @extend %btn;
    background-color: $color-light;
    border: 1px solid $color-info;
    color: $color-dark__2;

    &:hover {
        background-color: $color-light__2;
        color: $color-dark__2;
    }

    &:disabled {
        border-color: rgba(221, 221, 221, 0.2) !important;
    }
}

.btn-style__3 {
    @extend %btn;
    background-color: $color-brand;
    border: 1px solid $color-brand;
    color: $color-light;

    &:hover {
        background-color: #ea2f3f;
        color: $color-light;
    }
}

.btn-style__4 {
    @extend %btn;
    background-color: $color-light;
    border: 1px solid $color-brand;
    color: $color-brand;

    &:hover {
        border-color: #ea2f3f;
        color: #ea2f3f;
    }
}

.btn-style__11 {
    @extend %btn;
    background-color: $color-light;
    border: 1px solid #e4e4e4;
    color: $color-dark__2;

    .icon {
        margin-left: -9px;
    }
}

.btn-style__img {
    @extend %btn;
    overflow: hidden;
    position: relative;
    width: 36px;
    height: 36px;
    padding: 0;
    background-color: $color-light;
    border: 1px solid $color-light__5;
    line-height: 2000px;

    &:hover {
        background-color: $color-light__2;
    }

    &.btn-img__delete {

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 14px;
            height: 1px;
            background-color: $color-info;
            transform: translateZ(0) translate(-50%, -50%) rotate(45deg);
            content: '';
        }

        &:after {
            transform: translateZ(0) translate(-50%, -50%) rotate(135deg);
        }
    }

    &.btn-img__prev {
        width: 26px;
        height: 26px;
        border-color: $color-light__4;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 7.8px;
            height: 7.8px;
            margin: -2px 0 0 -4px;
            border: 1px solid $color-info;
            border-width: 1px 0 0 1px;
            vertical-align: middle;
            transform: rotate(45deg);
            content: '';
        }

        &:disabled {
            &:before {
                border-color: $color-light__4;
            }

            &:after {
                background-color: $color-light__4;
            }
        }
    }

    &.btn-img__next {
        @extend .btn-img__prev;

        &:before {
            margin-top: -7px;
            border-width: 0 1px 1px 0;
        }
    }

    &.btn-img__first {
        @extend .btn-img__prev;
        margin: 0 -1px 0 2px;

        &:before {
            top: 14px;
            margin: -1px 0 0 -4px;
        }

        &:after {
            position: absolute;
            top: 7px;
            left: 50%;
            width: 12px;
            height: 1px;
            margin-left: -6px;
            background-color: $color-info;
            content: '';
        }
    }

    &.btn-img__last {
        @extend .btn-img__first;

        &:before {
            top: 4px;
            margin-left: -4px;
            border-width: 0 1px 1px 0;
        }

        &:after {
            top: 16px;
        }
    }
}

.btn-style__icon {
    overflow: hidden;
    position: relative;
    width: 36px;
    height: 36px;
    line-height: 2000px;

    &.btn-icon__delete {

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 19px;
            height: 1px;
            background-color: $color-dark__2;
            transform: translateZ(0) translate(-50%, -50%) rotate(45deg);
            content: '';
        }

        &:after {
            transform: translateZ(0) translate(-50%, -50%) rotate(135deg);
        }
    }

    &.btn-icon__delete2 {
        @extend .btn-icon__delete;

        &:before,
        &:after {
            width: 16px;
            background-color: $color-info;
        }
    }

    &.btn-icon__delete3 {
        @extend .btn-icon__delete;
        width: 18px;
        height: 18px;
        background-color: #bbb;
        vertical-align: middle;
        border-radius: 50%;

        &:before,
        &:after {
            width: 11px;
            background-color: $color-light;
        }
    }

    &.btn-icon__close {
        @extend .btn-icon__delete;
        width: 18px;
        height: 18px;
        margin: -3px -4px 0 -3px;
        vertical-align: middle;

        &:before,
        &:after {
            width: 13px;
            background-color: $color-light;
        }
    }

    &.btn-icon__prev {
        width: 24px;
        height: 24px;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 8px;
            margin: -2px 0 0 -5px;
            border: 1px solid $color-info;
            border-width: 0 0 1px 1px;
            vertical-align: middle;
            transform: rotate(45deg) translateY(-50%);
            content: '';
        }
    }

    &.btn-icon__next {
        @extend .btn-icon__prev;

        &:before {
            margin: -2px 0 0 -9px;
            border-width: 1px 1px 0 0;
        }
    }

    &.btn-icon__prev2 {
        width: 40px;
        height: 40px;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            margin: -5px 0 0 -2px;
            border: 1px solid $color-info;
            border-width: 0 0 1px 1px;
            transform: rotate(45deg);
            content: '';
        }
    }

    &.btn-icon__next2 {
        @extend .btn-icon__prev2;

        &:before {
            margin-left: -8px;
            border-width: 1px 1px 0 0;
        }
    }
}

// Size
.btn-size__1 {
    min-width: 140px;
    height: 48px;
    padding: 0 10px;
    line-height: 46px;
    font-weight: bold;
    font-size: 16px;
}

.btn-size__0 {
    @extend .btn-size__1;
    min-width: 200px;
}

.btn-size__2 {
    min-width: 120px;
    height: 47px;
    line-height: 46px;
    font-weight: bold;
    font-size: 16px;
}

.btn-size__3 {
    min-width: 140px;
    height: 40px;
    padding: 0 10px;
    line-height: 36px;
    font-size: 14px;
}

.btn-size__4 {
    @extend .btn-size__3;
    min-width: 120px;
}

.btn-size__5 {
    height: 32px;
    padding: 0 24px;
    line-height: 30px;
    font-size: 13px;
}

.btn-size__6 {
    @extend .btn-size__5;
    min-width: 70px;
    padding: 0 10px;
}

.btn-size__7 {
    @extend .btn-size__5;
    min-width: 60px;
    padding: 0 5px;
}

.btn-size__8 {
    @extend .btn-size__3;
    min-width: 60px;
}

.btn-size__9 {
    @extend .btn-size__5;
    min-width: 50px;
    padding: 0 5px;
}

.btn-size__10 {
    @extend .btn-size__1;
    min-width: 280px;

    &:disabled {
        background-color: $color-light__6 !important;
        border-color: $color-light__6 !important;
        color: $color-light !important;
    }
}

.btn-size__11 {
    height: 32px;
    padding: 0 15px;
    font-weight: bold;
    font-size: 11px;
    line-height: 28px;
}

.btn-size__12 {
    min-width: 44px;
    height: 28px;
    padding: 0 5px;
    line-height: 26px;
    font-size: 13px;
}

.wide {
    min-width: 100%;
}
