@charset "UTF-8";
@import "../store/sprites/sp_footer_2x.scss";

.footer-box {
    margin-top: 40px;
    padding-bottom: 34px;
    background-color: $color-light__4;

    .footer-box__link {
        background-color: $color-light__5;
        height: 54px;
        font-size: 0;

        .link_in {
            display: flex;
            width: 1200px;
            margin: 0 auto;
        }

        li {
            flex: 1 1 auto;
            position: relative;
            z-index: 1;
            display: inline-block;
            text-align: center;

            a {
                font-size: 12px;
                color: $color-dark__3;
                line-height: 54px;
                letter-spacing: -0.9px;
            }

            &:before {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 0;
                margin-top: -4px;
                display: block;
                width: 1px;
                height: 8px;
                opacity: 0.65;
                background-color: $color-light__6;
                content: '';
            }

            &:first-child:before {
                display: none;
            }

            &:nth-child(4) {
                a {
                    font-weight: bold;
                }
            }
        }
    }

    .footer-box__info {
        display: table;
        width: 1200px;
        margin: 0 auto;
        padding: 30px 0 27px;
        border-bottom: 1px solid $color-light__4;

        .logo {
            display: table-cell;
            text-align: center;
            width: 12%;
            vertical-align: middle;

            .logo-11st {
                display: inline-block;
                @include sprite-retina($sp_footer_2x_logo_footer);
                overflow: hidden;
                line-height: 200px;
            }
        }

        .info {
            display: table-cell;
            vertical-align: top;

            dt {
                color: $color-default;
                font-weight: bold;
                font-size: 12px;
                line-height: 19px;
            }

            dd {
                color: $color-info;
                font-size: 12px;
                line-height: 19px;
            }

            .tel {
                padding: 10px 0;
                font-size: 20px;
                color: $color-default;
                font-family: $font-family-number;
            }

            a {
                color: $color-info;
            }

            .col {
                margin-left: 10px;
                font-size: 12px;
                color: $color-info;
                line-height: 19px;
                letter-spacing: -0.9px;
                vertical-align: middle;
            }
        }

        .info:nth-child(2) {
            width: 35%;
        }

        .info:nth-child(3) {
            width: 27%;
        }

        .info:nth-child(4) {
            width: 26%;
        }
    }

    .footer-box_service {
        padding-top: 21px;
        font-size: 0;
        border-top: 1px solid $color-light__5;

        .service_in {
            width: 1200px;
            margin: 0 auto;
        }

        .service_link {
            display: inline-block;
            min-width: 140px;
            height: 32px;
            font-size: 12px;
            line-height: 30px;
            border: 1px solid $color-light__5;
            background-color: $color-light;
            text-align: center;
            color: $color-default;
            margin-left: 6px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .footer-box_copy {
        width: 1200px;
        margin: 0 auto;
        padding-top: 21px;

        p {
            font-size: 11px;
            line-height: 22px;
        }

        .txt {
            font-weight: bold;
        }
    }
}
