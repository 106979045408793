@charset "UTF-8";

// INPUT -text
.c-input {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.3;

    .lab {
        line-height: 40px;
        font-size: 14px;
        margin-right: 6px;
        color: $color-dark__2;
        vertical-align: top; // waring 문구노출 시 필요
    }

    .title {
        display: inline-block;
        padding-right: 10px;
        font-size: 14px;
        color: $color-dark__2;
        line-height: 40px;
        vertical-align: middle;
    }

    .input-group {
        display: inline-block;
    }

    .input-text {
        display: inline-block;
        position: relative;
        z-index: 2; // dropdown type list box z-index:1
        vertical-align: top;

        & + button {
            margin-left: 7px;
            vertical-align: top;
        }

        input:not(:only-child) {
            padding-right: 40px;
        }
    }

    .inp {
        width: 160px;
        height: 40px;
        padding: 0 12px;
        background: $color-light;
        border: 1px solid $color-light__5;
        color: $color-dark__2;
        letter-spacing: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &::-webkit-input-placeholder {
            color: $color-info;
            font-weight: normal;
        }

        &::-moz-placeholder {
            color: $color-info;
            font-weight: normal;
        }

        /* firefox 19+ */
        &::-ms-input-placeholder {
            color: $color-info;
            font-weight: normal;
        }

        /* ie */
        &:focus {
            border-color: #bbb;
        }

        &:required:valid {
            border-color: $color-light__5;
            color: $color-dark__2;
        }

        &:disabled {
            background-color: $color-light__2;
            border-color: rgba(204, 204, 204, 0.2);
            color: $color-light__6;

            &::-webkit-input-placeholder {
                color: $color-light__6;
                font-weight: normal;
            }

            /* firefox 19+ */
            &::-moz-placeholder {
                color: $color-light__6;
                font-weight: normal;
            }

            /* ie */
            &::-ms-input-placeholder {
                color: $color-light__6;
                font-weight: normal;
            }
        }

        &:disabled + .input-etc {
            background-color: $color-light__2;
        }
    }

    input[readonly] {
        @extend .inp:disabled;
    }

    .input-etc {
        overflow: hidden;
        position: absolute;
        top: 1px;
        right: 1px;
        height: 38px;
        text-align: center;

        .btn-search {
            overflow: hidden;
            width: 44px;
            height: 38px;
            display: inline-block;
            line-height: 200px;

            &:after {
                position: absolute;
                top: 0;
                right: 0;
                margin: 9px 12px;
                @include sprite($sp_icon_search);
                content: "";
            }
        }

        .btn-clear {
            position: relative;
            display: inline-block;
            margin: 9px 12px;
            background-color: #bbb;
            border-radius: 9px;
            @include ico-cancel(8px, 1px, $color-light, 5px);
        }

        .btn-clear:not(:last-of-type) {
            margin-left: 0px;
            margin-right: 0px;
        }

        .btn-clear + .character-check {
            margin-left: 0px;
        }

        .btn-calender {
            display: inline-block;
            overflow: hidden;
            height: 38px;
            line-height: 200px;

            &:before {
                position: absolute;
                top: 0;
                right: 0;
                margin: 8px 9px;
                @include sprite-retina($sp_icon_2x_calendar);
                content: "";
            }

            &:after {
                position: absolute;
                top: 1px;
                right: 38px;
                display: block;
                width: 1px;
                height: 40px;
                background-color: #f4f4f4;
                content: "";
            }
        }

        .character-check {
            display: inline-block;
            height: 38px;
            margin: 0 9px;
            font-size: 13px;
            color: $color-info;
            line-height: 38px;
            text-align: right;
            vertical-align: middle;
            font-family: $font-family-number;

            em {
                color: $color-dark__2;
            }
        }
    }

    &.w-350 .inp {
        width: 348px;
    }

    &.w-400 .inp {
        width: 412px;
    }

    &.size__2 .inp[type="number"] {
        width: 54px;
        height: 32px;
        text-align: center;
        font-family: $font-family-number;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .unit {
        padding-left: 3px;
        line-height: 40px;
        color: $color-dark__2;
        font-size: 14px;
        vertical-align: middle;
    }

    .input-message {
        display: none;
    }

    &.waring {
        vertical-align: top;

        .inp {
            border-color: $color-brand;
        }

        .input-message {
            position: absolute;
            z-index: 1;
            left: 7px;
            bottom: 0;
            display: block;
            color: $color-brand;
            width: 100%;
            height: 15px;
            font-size: 12px;
            line-height: 15px;
            white-space: nowrap;
        }

        .input-text {
            padding-bottom: 18px;
        }
    }

    &.wide {
        display: table;
        width: 100%;

        .lab {
            display: table-cell;
            padding-right: 10px;
        }

        .input-text {
            display: table-cell;
            width: 100%;

            .inp {
                width: 100%;
            }
        }

        button:first-child {
            margin-right: 10px;
        }

        .input-file {
            display: table-cell;
            min-width: 120px;
        }

        .unit {
            display: table-cell;
        }
    }

    &.vertical {
        margin-top: 0 !important;

        .lab {
            margin-right: 5px;
            vertical-align: middle;
        }

        .input-text,
        .unit {
            display: table-cell;
        }
    }

    &.check {
        .input-text .inp {
            padding-right: 80px;
        }
    }

    &.address_add {
        display: block;
        margin: 10px 0 0 130px;

        .input-text,
        .inp {
            width: 100%;
        }
    }

    .input_in {
        display: table;
        width: 100%;
        background-color: $color-light;
        border: 1px solid $color-light__5;

        .lab {
            display: table-cell;
            width: 1px;
            margin-right: 0;
            padding: 0 0 0 12px;
            color: $color-dark__2;
            line-height: 38px;
            white-space: nowrap;

            &:after {
                display: inline-block;
                width: 1px;
                height: 12px;
                margin-left: 10px;
                background-color: #d8d8d8;
                vertical-align: middle;
                content: "";
            }
        }

        .inp {
            display: table-cell;
            width: 100%;
            height: 38px;
            padding: 0 12px 0 9px !important;
            border: none;
        }
    }
}

.c-date__calender {
    font-size: 0;
    display: inline-block;
    vertical-align: middle;

    [class^="radio-style"] + &,
    [class^="check-style"] + & {
        margin-left: -10px;
    }
}

.section-bar {
    // 날짜선택폼 '~'
    display: inline-block;
    padding: 0 8px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    color: $color-dark__2;
    font-size: 14px;
}

// file
.input-file {
    display: inline-block;
    position: relative;
    z-index: 1;
    width: 160px;
    height: 160px;
    vertical-align: middle;
    font-size: 0; // MPQA-45720

    &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
    }

    .btn-img__file {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        border: 1px dashed $color-light__5;
        cursor: pointer;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 32px;
            height: 2px;
            margin: -1px 0 0 -16px;
            background-color: $color-info;
            content: "";
        }

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2px;
            height: 32px;
            margin: -16px 0 0 -1px;
            background-color: $color-info;
            content: "";
        }
    }

    input[type="file"] {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 0; // MPQA-45720
        opacity: 0;
        cursor: pointer;

        &.focus-visible,
        &.focus-visible + [class^="btn"],
        &.focus-visible + .img,
        &.focus-visible + .img [class^="btn"],
        &.focus-visible + .img + [class^="btn"] {
            box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
        }
    }

    .text {
        position: absolute;
        right: 5px;
        bottom: 10px;
        left: 5px;
        text-align: center;
        color: $color-info;
        font-size: 13px;
        letter-spacing: 0;
        cursor: pointer;
    }

    .img {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        border: solid 1px rgba(0, 0, 0, 0.1);
        cursor: pointer;

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }

        .btn {
            position: absolute;
            top: 0;
            right: 0;
            padding: 3px 5px;
            background-color: rgba(0, 0, 0, 0.6);
            border: solid 1px rgba(17, 17, 17, 0.3);
            color: $color-light;
            font-size: 11px;
            cursor: pointer;
        }

        .input-file__btn {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            @include clearfix;

            [class^="input-file__btn"] {
                position: relative;
                float: left;
                display: block;
                width: 50%;
                height: 32px;
                padding: 6px 5px 8px;
                background-color: rgba(0, 0, 0, 0.6);
                color: $color-light;
                font-size: 12px;
                text-align: center;
                cursor: pointer;

                &:before {
                    display: inline-block;
                    content: "";
                    margin-right: 4px;
                    vertical-align: bottom;
                }
            }

            .input-file__btn--edit {
                &:before {
                    @include sprite-retina($sp_icon_2x_ico_edit);
                }

                &:after {
                    position: absolute;
                    top: 10px;
                    right: 0;
                    width: 1px;
                    height: 12px;
                    border-right: 1px solid rgba(255, 255, 255, 0.6);
                    content: "";
                }
            }

            .input-file__btn--del:before {
                @include sprite-retina($sp_icon_2x_ico_delete);
            }
        }
    }

    [class^="btn-style"],
    [class^="btn-style"]:before {
        cursor: pointer;
    }

    &.size__2 {
        height: 107px;
    }

    &.size__4 {
        width: 300px;
        height: 165px;
    }

    &.size__3 {
        width: 80px;
        height: auto;
        padding-top: 91px;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            width: 28px;
            height: 2px;
            margin: -20px 0 0 -14px;
            background-color: $color-info;
            content: "";
        }

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            width: 2px;
            height: 28px;
            margin: -33px 0 0 -1px;
            background-color: $color-info;
            content: "";
        }

        input[type="file"] {
            & + [class^="btn-style"]:before {
                position: absolute;
                top: -89px;
                left: 0;
                width: 100%;
                height: 80px;
                background-color: $color-light;
                border: 1px dashed $color-light__5;
                border-radius: 50%;
                content: "";
            }
        }

        /* MPQC-17908 배포 후 삭제 */
        .img {
            position: absolute;
            top: 0;
            z-index: 2;
            height: 80px;
            border: none;

            &:before {
                position: absolute;
                top: -3px;
                right: -3px;
                bottom: -3px;
                left: -3px;
                border: 1px solid $color-brand;
                border-radius: 50%;
                content: "";
            }

            &:after {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 26px;
                height: 26px;
                background-color: $color-brand;
                border: none;
                border-radius: 50%;
                content: "";
            }

            img {
                border: 1px solid rgba(0, 0, 0, 0.07);
                border-radius: 50%;
            }

            & + [class^="btn-style"]:before {
                @include sprite-retina($sp_icon_2x_upload_img);
                position: absolute;
                top: -35px;
                right: 2px;
                z-index: 2;
                content: "";
            }
        }
        /* // MPQC-17908 배포 후 삭제 */

        .img_wrap {
            position: absolute;
            top: 0;
            z-index: 2;
            width: 100%;
            height: 80px;
            border: 1px solid rgba(0, 0, 0, 0.07);
            border-radius: 50%;

            &:before {
                position: absolute;
                top: -3px;
                right: -3px;
                bottom: -3px;
                left: -3px;
                border: 1px solid $color-brand;
                border-radius: 50%;
                content: "";
            }

            &:after {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 26px;
                height: 26px;
                background-color: $color-brand;
                border: none;
                border-radius: 50%;
                content: "";
            }

            .img_in {
                overflow: hidden;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 50%;
                color: transparent;
                font-size: 0;
                line-height: 200px;
            }

            & + [class^="btn-style"]:before {
                @include sprite-retina($sp_icon_2x_upload_img);
                position: absolute;
                top: -35px;
                right: 2px;
                z-index: 2;
                content: "";
            }
        }
    }
}

// textarea
.c-textarea {
    .textarea {
        height: 78px;
        padding: 13px;
        border: 1px solid $color-light__5;
        font-size: 14px;
        color: $color-dark__2;
        resize: none;

        &::-webkit-input-placeholder {
            color: $color-info;
            font-weight: normal;
        }

        &::-moz-placeholder {
            color: $color-info;
            font-weight: normal;
        }

        /* firefox 19+ */
        &::-ms-input-placeholder {
            color: $color-info;
            font-weight: normal;
        }

        /* ie */
    }

    &.wide {
        .textarea {
            width: 100%;
        }
    }

    &.check {
        position: relative;
        display: inline-block;

        .textarea {
            padding-right: 100px;
        }

        .character-check {
            position: absolute;
            z-index: 1;
            right: 37px;
            bottom: 0;
            display: inline-block;
            font-size: 13px;
            color: $color-info;
            line-height: 38px;
            text-align: right;
            vertical-align: middle;
            letter-spacing: 0;

            em {
                color: $color-dark__2;
            }
        }
    }
}

// Radio
.radio-style__1 {
    display: inline-block;
    position: relative;
    z-index: 1;
    vertical-align: middle;
    line-height: 0;
    white-space: nowrap; // safari오류

    .inp {
        width: 22px;
        height: 22px;
        opacity: 0;
    }

    .lab {
        padding-left: 6px;
        vertical-align: -1px;
        color: $color-dark__3;
        font-size: 14px;

        &:before {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 22px;
            height: 22px;
            background-color: $color-light;
            border: 1px solid $color-light__6;
            border-radius: 50%;
            content: "";
        }

        &:after {
            position: absolute;
            top: 6px;
            left: 6px;
            width: 10px;
            height: 10px;
            background-color: $color-light__6;
            border-radius: 50%;
            content: "";
        }
    }

    .inp:checked + .lab {
        &:before {
            border-color: $color-brand;
            background-color: $color-brand;
        }

        &:after {
            background-color: $color-light;
        }
    }

    .inp:disabled + .lab {
        &:before {
            border-color: $color-light__4;
        }

        &:after {
            background-color: $color-light__4;
        }
    }

    .inp.focus-visible + .lab:before {
        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
    }
}

.label-hidden {
    .inp {
        display: block;
    }

    .lab {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        padding-left: 0 !important;
        color: transparent !important;
        font-size: 0 !important;
    }
}

.radio-style__2 {
    @extend .radio-style__1;

    .lab {
        vertical-align: -2px;
        font-weight: bold;
        font-size: 16px;
    }
}

// Radio-색상
.radio-style__11 {
    position: relative;
    display: inline-block;
    margin-right: 0 !important;
    background-color: #fefefe;
    vertical-align: middle;
    line-height: 0;

    & + & {
        margin-left: 16px;
    }

    .inp {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .lab {
        overflow: hidden;
        display: block;
        width: 30px;
        height: 30px;
        margin: 7px;
        line-height: 200px;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
        }
    }

    .inp:checked + .lab:before {
        border: solid 2px $color-brand;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    .inp.focus-visible + .lab:before {
        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
    }
}

// Checkbox
.check-style__1 {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    line-height: 0;
    white-space: nowrap; // safari오류
    z-index: 1;

    .inp {
        width: 22px;
        height: 22px;
        opacity: 0;
    }

    .lab {
        padding-left: 6px;
        font-size: 14px;
        color: $color-dark__3;
        vertical-align: middle;

        &:before {
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
            width: 22px;
            height: 22px;
            background-color: $color-light;
            border: 1px solid $color-light__6;
            content: "";
        }
    }

    .inp:checked + .lab {
        &:before {
            border-color: $color-brand;
            background-color: $color-brand;
        }

        &:after {
            position: absolute;
            top: 2px;
            left: 7px;
            width: 8px;
            height: 13px;
            border: 1px solid $color-light;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
            content: "";
        }
    }

    .inp:disabled + .lab:before {
        border-color: $color-light__4;
    }

    .inp.focus-visible + .lab:before {
        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
    }
}

.check-style__2 {
    display: inline-block;
    vertical-align: middle;
    line-height: 0;

    .inp {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
    }

    .lab {
        overflow: hidden;
        position: relative;
        display: inline-block;
        width: 60px;
        height: 40px;
        line-height: 200px;

        &:before {
            position: absolute;
            top: 13px;
            right: 13px;
            left: 13px;
            height: 14px;
            background-color: $color-light__6;
            border-radius: 7px;
            opacity: 0.5;
            transition: 0.4s;
            content: "";
        }

        &:after {
            position: absolute;
            height: 20px;
            width: 20px;
            top: 10px;
            left: 10px;
            background-color: $color-light;
            border-radius: 50%;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0.5px rgba(0, 0, 0, 0.12);
            -webkit-transition: 0.4s;
            transition: 0.4s;
            content: "";
        }
    }

    .inp:checked + .lab {
        &:before {
            background-color: $color-brand;
        }

        &:after {
            background-color: $color-brand;
            transform: translateX(19px);
        }
    }

    .inp:disabled + .lab {
        &:before {
            background-color: $color-light__4;
        }

        &:after {
            background-color: $color-light__4;
        }
    }

    .inp.focus-visible + .lab:before {
        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
    }
}

// 지역
.check-style__31 {
    display: inline-block;
    margin: 0 4px 8px 0 !important;
    vertical-align: middle;

    &:last-of-type,
    &:only-of-type {
        margin-right: 0 !important;
    }

    .inp {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
    }

    .lab {
        display: inline-block;
        padding: 4px 12px 5px;
        background-color: $color-light;
        border: 1px solid $color-light__5;
        border-radius: 16px;
        color: $color-dark__3;
    }

    .inp:checked + .lab {
        background-color: #fffbfc;
        border: solid 1px rgba(244, 49, 66, 0.2);
        color: $color-brand;
        font-weight: bold;

        &:before {
            display: inline-block;
            width: 7px;
            height: 12px;
            margin: 0 8px -1px 2px;
            vertical-align: 1px;
            border: solid 1.5px $color-brand;
            border-width: 0 1.5px 1.5px 0;
            transform: rotate(45deg);
            content: "";
        }
    }

    .inp.focus-visible + .lab {
        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
    }
}

.c-check__local,
.c-check__keyword {
    margin: 0 -4px -8px 0;
}

.c-input + .c-check__keyword {
    margin-top: 20px;
}

// Dropdown
.c-dropdown {
    display: inline-block;
    position: relative;
    z-index: 1;
    height: 40px;
    vertical-align: middle;

    .lab {
        color: $color-dark;
        line-height: 26px;
        margin-right: 10px;
    }

    .title {
        display: inline-block;
        padding-right: 10px;
        font-size: 14px;
        color: $color-dark__2;
        line-height: 40px;
        vertical-align: middle;
        white-space: nowrap;
    }

    .c-text {
        margin-right: 10px;
    }

    .dropdown {
        display: inline-block;
        position: relative;
        z-index: 1;

        & + .unit {
            display: inline-block;
            margin-left: 10px;
            color: $color-dark__2;
            line-height: 40px;
            font-size: 14px;
            vertical-align: middle;
            white-space: nowrap;
        }
    }

    .btn {
        position: relative;
        z-index: 2;
        top: 0;
        left: 0;
        padding: 0 45px 0 12px;
        width: 160px;
        height: 40px;
        border: 1px solid $color-light__5;
        text-align: left;
        color: $color-info;
        font-size: 14px;
        background-color: $color-light;

        span.text {
            display: block;
            width: 100%;
            height: 38px;
            line-height: 38px;
            overflow: hidden;
            //white-space: nowrap;
            text-overflow: clip;

            .text_style1 {
                .emblem {
                    margin-right: 5px;
                    color: $color-brand;
                }
            }
        }

        &:before {
            position: absolute;
            top: 0;
            right: 40px;
            content: "";
            height: 100%;
            width: 1px;
            border-left: 1px solid #f4f4f4;
        }

        &:after {
            position: absolute;
            top: 50%;
            right: 16px;
            margin-top: -7px;
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 8px;
            height: 8px;
            border: 1px solid $color-info;
            border-width: 1px 1px 0 0;
            transform: rotate(135deg);
        }
    }

    .btn:disabled {
        border-color: $color-light__5;
        background-color: $color-light__2;

        span.text {
            color: $color-light__6 !important;
        }

        &:before {
            border-color: $color-light__3;
            color: $color-light__6;
        }

        &:after {
            border-color: $color-info;
        }
    }

    .list {
        display: none;
        position: absolute;
        z-index: 1;
        top: 39px;
        left: 0;
        width: 100%;
        padding: 10px 0;
        border: 1px solid #bbb;
        background: $color-light;
        max-height: 200px;
        overflow-y: auto;

        [role="option"] {
            display: block;
            width: 100%;
            padding: 8px 12px;
            color: #666;
            cursor: pointer;
            text-align: left;
            font-size: 14px;

            &[aria-selected="true"] {
                color: $color-brand;

                .text_style1 {
                    color: $color-dark__2;

                    .emblem {
                        margin-right: 5px;
                        color: $color-brand;
                    }
                }
            }

            &:hover {
                color: $color-dark__2;
                background: $color-light__2;
                text-decoration: none;
            }
        }
    }

    &.list-up {
        .list {
            top: auto;
            bottom: 39px;
        }
    }

    &.open {
        z-index: 10;

        .c-input .input-text .inp {
            border-color: #bbb;
        }

        .btn {
            border-color: #bbb;
            color: $color-dark__2;

            &:after {
                top: 65%;
                transform: rotate(-45deg);
            }
        }
    }

    &.filled {
        .btn {
            span.text {
                color: $color-dark__2;
            }
        }
    }

    &.auto {
        .btn {
            color: $color-dark__2;
            background-color: #f4f4f4;
            border-color: $color-light__5;

            &:before {
                border-color: $color-light__5;
                color: $color-light__6;
            }

            &:after {
                border-color: $color-info;
            }
        }

        .btn .unit {
            background-color: #f4f4f4;
            color: $color-light__4;
            border-color: $color-light__5;
        }

        .c-input .input-text .inp {
            background-color: #f4f4f4;
            color: $color-dark__2;

            &::-webkit-input-placeholder {
                color: $color-dark__2;
                font-weight: normal;
            }

            &::-moz-placeholder {
                color: $color-dark__2;
                font-weight: normal;
            }

            /* firefox 19+ */
            &::-ms-input-placeholder {
                color: $color-dark__2;
                font-weight: normal;
            }

            /* ie */
        }

        .c-input .input-text .input-etc {
            background-color: #f4f4f4;
        }
    }

    &.w-100 {
        .btn {
            width: 100px;
        }
    }

    &.w-200 {
        .btn {
            width: 200px;
        }
    }

    &.size__2 {
        height: 32px;

        .btn {
            width: 120px;
            height: 32px;
            padding-right: 22px;

            &:before {
                display: none;
            }

            &:after {
                right: 11px;
            }

            .text {
                height: 30px;
                line-height: 30px;
            }
        }

        .c-input .input-text .inp {
            height: 32px;
        }

        .c-input .input-text .input-etc {
            height: 30px;

            .btn-search {
                margin: 5px 12px;
            }
        }
    }

    &.wide {
        display: table;
        width: 100%;

        .dropdown {
            display: table-cell;
            width: 100%;
        }

        [class^="btn-style"] {
            margin: 0 0 0 10px;
            min-width: 160px;
        }

        .c-input,
        .input-text,
        .inp {
            width: 100%;
        }

        .btn {
            width: 100%;
        }

        .list {
            width: 100%;
        }

        .input-group {
            display: table-cell;
            width: 100%;

            &.non-unit {
                display: table;

                .input-text {
                    display: table;
                }
            }
        }
    }
}

.c-dropdown__list {
    display: none;
    position: absolute;
    z-index: 1000;
    width: 100%;
    padding: 10px 0;
    border: 1px solid #bbb;
    background: $color-light;
    max-height: 200px;
    overflow-y: auto;

    [role="option"] {
        display: block;
        width: 100%;
        padding: 8px 12px;
        color: #666;
        cursor: pointer;
        text-align: left;
        font-size: 14px;

        &[aria-selected="true"] {
            color: $color-brand;
        }

        &:hover {
            color: $color-dark__2;
            background: $color-light__2;
            text-decoration: none;
        }
    }

    &.open {
        display: block;
    }
}

// Select
.c-select {
    position: relative;
    z-index: 1;

    .select-box {
        position: relative;
        z-index: 1;
        min-width: 92px;
        display: inline-block;

        &:after {
            position: absolute;
            top: 50%;
            right: 17px;
            width: 8px;
            height: 8px;
            margin-top: -6px;
            border: 1px solid #999;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
            content: "";
        }

        & + .select-box {
            margin-left: 9px;
        }
    }

    .select {
        width: 100%;
        height: 40px;
        padding: 0 48px 0 11px;
        background: none;
        border: 1px solid $color-light__5;
        color: $color-dark__2;
        font-weight: bold;
        font-size: 15px;
        appearance: none;

        /* IE 10, 11의 네이티브 화살표 숨기기 */
        &::-ms-expand {
            display: none;
        }
    }
}

// 조합
.input-text,
.c-dropdown,
[class^="btn-style"] {
    & + & {
        margin-left: 8px;
    }
}

[class^="radio-style"],
[class^="check-style"] {
    margin-right: 36px;

    &:last-of-type,
    &:only-of-type {
        margin-right: 0;
    }

    .lab.color__em {
        color: $color-dark;
    }

    .c-dropdown + & {
        margin-left: 16px;
    }
}

// 모바일 CSS 충돌 처리 (CSS 재선언)
.c-input {
    width: auto;
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: inherit;
}

.c-dropdown {
    width: auto;
    padding: 0;
    background: none;
    border: none;
}
