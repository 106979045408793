@charset "UTF-8";

// Font
$font-family-default:'Noto Sans CJK KR','Noto Sans KR','Helvetica Neue','Apple SD Gothic Neo',Arial,'맑은 고딕','Malgun Gothic','돋움',dotum,sans-serif;
$font-family-asis: 'Helvetica Neue','Apple SD Gothic Neo','돋움',dotum,sans-serif;
$font-family-number: 'Roboto',Tahoma,sans-serif;
// Color
$color-brand: #f43142;
$color-secondary: #0b83e6;
$color-dark: #000;
$color-dark__2: #111;
$color-dark__3: #333;
$color-light: #fff;
$color-light__2: #fafafa;
$color-light__3: #f3f3f3;
$color-light__4: #eee;
$color-light__5: #ddd;
$color-light__6: #ccc;
$color-info: #999;
$color-default: #666;
